import api from '../../utils/api'
import {
  handleActionCatch,
  validateServerResponse,
} from '../../utils/errorUtils'
import notifyToast from '../../utils/notifyToast'
import {
  EDIT_PROPERTY_STARTED,
  EDIT_PROPERTY_SUCCESS,
  EDIT_PROPERTY_FAILED,
  CLEAR_EDIT_PROPERTY,
} from '../constants'

export const EditPropertyStarted = (payload) => ({
  type: EDIT_PROPERTY_STARTED,
  ...payload,
})

export const EditPropertySuccess = (payload) => {
  notifyToast('success', 'Property updated successfully')
  return { type: EDIT_PROPERTY_SUCCESS, payload }
}

export const EditPropertyFailed = (payload) => ({
  type: EDIT_PROPERTY_FAILED,
  payload,
})

export const ClearEditProperty = () => ({ type: CLEAR_EDIT_PROPERTY })

export const EditProperty = (payload, token) => async (dispatch) => {
  dispatch(EditPropertyStarted(payload))
  try {
    const result = await api.Put('/property', payload, token)
    validateServerResponse(result)
    dispatch(EditPropertySuccess(result.data.data))
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      EditPropertyFailed,
      'Edit property',
      payload
    )
  }
  dispatch(ClearEditProperty())
}

export default EditProperty
