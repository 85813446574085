/* eslint default-param-last: off */
import { ADD_TO_SAVED, DELETE_SAVED, CLEAR_SAVED_PRODUCTS } from '../constants'

export const initialState = {
  savedProductList: [],
}

// eslint-disable-next-line arrow-body-style

const removeItem = (state, payload) => {
  const updateGuestCart = state.savedProductList.filter(
    (product) => product.id !== payload
  )
  return updateGuestCart
}

const savedListReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case ADD_TO_SAVED: {
      return {
        ...state,
        savedProductList: payload,
      }
    }
    case DELETE_SAVED: {
      return {
        ...state,
        savedProductList: removeItem(state, payload),
      }
    }
    case CLEAR_SAVED_PRODUCTS: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}

export default savedListReducer
