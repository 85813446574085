// import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Buttons/Button'
import LoaderComponent from '../../components/Loader'
import defaultCopy from '../../utils/dictionary'
import ResetPasswordActions from '../../redux/actions/ResetPasswordActions'
import { validateEmail, validatePassword } from '../../utils/fieldValidator'
import InputComponent from '../../components/Inputs/InputComponent'
import { noop, setPageTitleAndUserInfo } from '../../utils'
import notifyToast from '../../utils/notifyToast'
import useGuest from '../../hooks/useGuest'

const { StartResetPassword, ClearResetPassword, ChangeUserPassword } =
  ResetPasswordActions

const ResetPassword = (props) => {
  const {
    step,
    startResetPasswordIsLoading,
    startResetPasswordError,
    changePasswordIsLoading,
    changePasswordError,
    changePasswordSuccess,
  } = props
  const defaultStepString = 'Unknown step'

  useEffect(() => setPageTitleAndUserInfo('Reset Password'), [])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { baseRoute } = useGuest()
  const [email, setEmail] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [verificationCodeError, setVerificationCodeError] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [inputValueError, setInputValueError] = useState('')
  const [title, setTitle] = useState(defaultCopy.resetPassword.userEmail.title)
  const [description, setDescription] = useState(
    defaultCopy.resetPassword.userEmail.description
  )
  const [inputLabel, setInputLabel] = useState(
    defaultCopy.resetPassword.userEmail.inputLabel
  )
  const [inputType, setInputType] = useState('email')
  const [buttonText, setButtonText] = useState(
    defaultCopy.resetPassword.userEmail.buttonText
  )
  const [enableContinueButton, setEnableContinueButton] = useState(false)
  const handleDispatchResetPassword = () =>
    inputValue && dispatch(StartResetPassword({ email: inputValue }))

  const handleDispatchChangePassword = () =>
    inputValue &&
    verificationCode &&
    email &&
    dispatch(
      ChangeUserPassword({
        email,
        verificationCode,
        password: inputValue,
      })
    )

  const handleEmailChange = (newEmail) => {
    setInputValue(newEmail)
    if (!validateEmail(newEmail)) {
      setInputValueError(defaultCopy.ValidationMessages.INVALID_EMAIL)
      setEnableContinueButton(false)
    } else {
      setInputValueError('')
      setEnableContinueButton(true)
    }
  }

  const handlePasswordChange = (newPassword) => {
    setInputValue(newPassword)
    if (!validatePassword(newPassword)) {
      setInputValueError(defaultCopy.ValidationMessages.INVALID_PASSWORD)
      setEnableContinueButton(false)
    } else {
      setInputValueError('')
      setEnableContinueButton(step === 1 ? true : !verificationCodeError)
    }
  }

  const onInputChange = (newValue) => {
    if (step === 1) return handleEmailChange(newValue)
    if (step === 2) return handlePasswordChange(newValue)
    return noop()
  }

  const onButtonClick = () => {
    if (step === 1) return handleDispatchResetPassword()
    if (step === 2) return handleDispatchChangePassword()
    return noop()
  }

  const onVerificationCodeChange = (newValue) => {
    if (typeof newValue !== 'string') return
    setVerificationCode(newValue)
    if (newValue.length !== 6 || Number.isNaN(Number(newValue))) {
      setVerificationCodeError(
        defaultCopy.ValidationMessages.INVALID_VERIFICATION_CODE
      )
      setEnableContinueButton(false)
    } else {
      setVerificationCodeError('')
      setEnableContinueButton(!inputValueError)
    }
  }

  const setViewAccordingToStep = () => {
    setInputValue('')
    setInputValueError('')
    setEnableContinueButton(false)
    if (step === 1) {
      // User Email
      setButtonText(defaultCopy.resetPassword.userEmail.buttonText)
      setTitle(defaultCopy.resetPassword.userEmail.title)
      setDescription(defaultCopy.resetPassword.userEmail.description)
      setInputLabel(defaultCopy.resetPassword.userEmail.inputLabel)
      setInputType('email')
    } else if (step === 2) {
      // Create new password
      setButtonText(defaultCopy.resetPassword.createPassword.buttonText)
      setTitle(defaultCopy.resetPassword.createPassword.title)
      setDescription(defaultCopy.resetPassword.createPassword.description)
      setInputLabel(defaultCopy.resetPassword.createPassword.inputLabel)
      setInputType('password')
    } else {
      // default
      setButtonText(defaultStepString)
      setTitle(defaultStepString)
      setDescription(defaultStepString)
      setInputLabel(defaultStepString)
      setInputType('text')
    }
  }

  useEffect(() => {
    setViewAccordingToStep()
    if (step === 2) setEmail(inputValue)
  }, [step])

  useEffect(() => {
    if (typeof startResetPasswordError !== 'string') return
    setInputValueError(startResetPasswordError)
  }, [startResetPasswordError])

  useEffect(() => {
    if (typeof changePasswordError !== 'string') return
    const lowercaseError = changePasswordError.toLowerCase()
    if (lowercaseError.indexOf('verification') > -1) {
      setVerificationCodeError(changePasswordError)
    } else {
      setInputValueError(changePasswordError)
    }
  }, [changePasswordError])

  useEffect(() => {
    dispatch(ClearResetPassword())
  }, [])

  useEffect(() => {
    if (changePasswordSuccess) {
      // everything done! reset and redirect
      notifyToast(
        'success',
        defaultCopy.resetPassword.successToastMessage,
        2000
      )
      navigate(`${baseRoute}/`)
      dispatch(ClearResetPassword())
    }
  }, [changePasswordSuccess])

  const isLoading = startResetPasswordIsLoading || changePasswordIsLoading

  const isButtonDisabled = () => {
    if (step === 1) return !inputValue || !enableContinueButton
    if (step === 2)
      return !inputValue || !verificationCode || !enableContinueButton

    return true
  }

  return (
    <section className="reset-password-container">
      {isLoading && <LoaderComponent show={isLoading} />}
      <h1 className="reset-password-title">{title}</h1>
      <p className="reset-password-description">{description}</p>
      {step === 2 && (
        <InputComponent
          id={defaultCopy.resetPassword.verificationCode.inputLabel}
          labelText={defaultCopy.resetPassword.verificationCode.inputLabel}
          type="text"
          onChange={(e) => onVerificationCodeChange(e.target.value)}
          value={verificationCode}
          invalid={!!verificationCodeError}
          invalidText={verificationCodeError}
        />
      )}
      {step === 1 && (
        <InputComponent
          id="verificationCode"
          labelText={inputLabel}
          type={inputType}
          onChange={(e) => onInputChange(e.target.value)}
          value={inputValue}
          invalid={!!inputValueError}
          invalidText={inputValueError}
          className="reset-password-input"
        />
      )}
      {step === 2 && (
        <InputComponent
          id="password"
          labelText="Password"
          type="password"
          onChange={(e) => onInputChange(e.target.value)}
          value={inputValue}
          invalid={!!inputValueError}
          invalidText={inputValueError}
          className="reset-password-input"
          showPasswordControl
        />
      )}
      <div className="reset-password-button">
        <Button
          id="reset-password-btn-id"
          text={buttonText}
          action={() => onButtonClick()}
          disabled={isButtonDisabled()}
        />
      </div>
    </section>
  )
}

ResetPassword.propTypes = {
  step: PropTypes.number,

  startResetPasswordIsLoading: PropTypes.bool,
  startResetPasswordError: PropTypes.string,

  changePasswordIsLoading: PropTypes.bool,
  changePasswordError: PropTypes.string,
  changePasswordSuccess: PropTypes.bool,
}

ResetPassword.defaultProps = {
  step: 1,

  startResetPasswordIsLoading: false,
  startResetPasswordError: '',

  changePasswordIsLoading: false,
  changePasswordError: '',
  changePasswordSuccess: false,
}

const mapStateToProps = ({
  resetPassword: {
    step,
    startResetPasswordIsLoading,
    startResetPasswordError,
    changePasswordIsLoading,
    changePasswordError,
    changePasswordSuccess,
  },
}) => ({
  step,
  startResetPasswordIsLoading,
  startResetPasswordError,
  changePasswordIsLoading,
  changePasswordError,
  changePasswordSuccess,
})

export default connect(mapStateToProps)(ResetPassword)
