/* eslint-disable no-plusplus */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import Icon from '../../../components/Icon'

function ProductImages(props) {
  const { images, mainImage, chosenVariant } = props
  const imgLength = images?.length
  const [prevNext, setPrevNext] = useState(0)
  useEffect(() => {
    const validatedVariants = []
    for (let i = 0; i < images.length; i++) {
      const valVariants = images[i].variant_ids
      if (valVariants.length > 0) {
        for (let j = 0; j < valVariants.length; j++) {
          validatedVariants.push({ idVariant: valVariants[j], index: i })
        }
      } else {
        validatedVariants.push({ idVariant: '', index: i })
      }
    }
    const getVariantImg = validatedVariants?.filter(
      (variant) => variant.idVariant === chosenVariant.id
    )
    const imgVariant =
      getVariantImg[0]?.idVariant !== '' ? getVariantImg[0]?.index : 0
    setPrevNext(imgVariant)
  }, [mainImage])
  return (
    <div className="product-detail-images">
      <div className="arrows-btns-container">
        <button
          type="button"
          onClick={
            prevNext >= 0 ? () => setPrevNext(prevNext - 1) : setPrevNext(0)
          }
        >
          <Icon
            item="carousel-arrow"
            width="20px"
            height="25px"
            className="arrows"
          />
        </button>
        <button
          type="button"
          onClick={
            prevNext <= imgLength
              ? () => setPrevNext(prevNext + 1)
              : setPrevNext(imgLength - 1)
          }
        >
          <Icon
            item="carousel-arrow"
            width="20px"
            height="25px"
            className="arrows right "
          />
        </button>
      </div>
      <Carousel
        selectedItem={prevNext}
        onClickThumb={(e) => setPrevNext(e)}
        showIndicators={false}
      >
        {images &&
          images.map((image) => (
            <div key={image.src}>
              <img src={image?.src} alt="Main" aria-hidden="true" />
            </div>
          ))}
      </Carousel>
    </div>
  )
}

ProductImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  mainImage: PropTypes.string.isRequired,
  chosenVariant: PropTypes.object.isRequired,
}

export default ProductImages
