import React from 'react'
import PropTypes from 'prop-types'

function TextArea(props) {
  const { id, className, onChange, labelText, placeholder, name, disabled } =
    props
  return (
    <textarea
      id={id}
      className={className}
      onChange={onChange}
      placeholder={placeholder}
      labelText={labelText}
      name={name}
      disabled={disabled}
    />
  )
}

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
}

TextArea.defaultProps = {
  className: '',
  onChange: () => {},
  labelText: '',
  placeholder: '',
  name: '',
  disabled: false,
}

export default TextArea
