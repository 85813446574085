/* eslint default-param-last: off */
import {
  RESET_PRODUCT_LIST_TOASTS,
  GET_PRODUCT_LIST_STARTED,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_FAILED,
  FILTER_PRODUCTS_STARTED,
  FILTER_PRODUCTS_SUCCESS,
  FILTER_PRODUCTS_FAILED,
  GET_PRODUCT_LIST_GUEST_STARTED,
  GET_PRODUCT_LIST_GUEST_SUCCESS,
  GET_PRODUCT_LIST_GUEST_FAILED,
  CLEAR_PRODUCT_LIST,
  CLEAR_PRODUCT_LIST_GUEST,
  CLEAR_FILTER_PRODUCTS,
  GET_FILTERS_STARTED,
  GET_FILTERS_SUCCESS,
  GET_FILTERS_FAILED,
  SET_URL_GUEST_PARAMS,
  REVIEW_PROPERTY_STARTED,
  REVIEW_PROPERTY_SUCCESS,
  REVIEW_PROPERTY_FAILURE,
  SAVE_PRODUCT_LIST_FIRST_PAGE_CACHE,
} from '../constants'

const initialStateProductList = {
  productList: {},
  productListStarted: false,
  productListSuccess: false,
  productListIsLoading: false,
  productListFailed: false,
  productListError: '',
  productListFirstPageCache: [],
}

const initialStateProductListGuest = {
  productListGuest: {},
  productListGuestStarted: false,
  productListGuestSuccess: false,
  productListGuestIsLoading: false,
  productListGuestFailed: false,
  productListGuestError: '',
}

const initialStateFilterProducts = {
  filterProduct: {
    filters: {
      tag: '',
      categories: [],
      toSearch: '',
      vendors: [],
      priceRange: {},
    },
    response: {},
  },
  filterProductStarted: false,
  filterProductSuccess: false,
  filterProductIsLoading: false,
  filterProductFailed: false,
  filterProductError: '',
}

const initialStateFiltersList = {
  filterList: {},
  filterListStarted: false,
  filterListSuccess: false,
  filterListIsLoading: false,
  filterListFailed: false,
  filterListError: '',
}

const initialStatePropertyReview = {
  propertyReviewSuccess: false,
  propertyReviewError: '',
}

export const initialState = {
  ...initialStateProductList,
  ...initialStateFilterProducts,
  ...initialStateFiltersList,
  ...initialStateProductListGuest,
  ...initialStatePropertyReview,
  urlGuestParams: '',
}

const productListReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_PRODUCT_LIST_STARTED: {
      return {
        ...state,
        ...initialStateProductList,
        productListStarted: true,
        productListIsLoading: true,
        productListError: '',
      }
    }
    case GET_PRODUCT_LIST_GUEST_STARTED: {
      return {
        ...state,
        ...initialStateProductListGuest,
        productListGuestStarted: true,
        productListGuestIsLoading: true,
        productListGuestError: '',
      }
    }
    case GET_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        ...initialStateProductList,
        productListSuccess: true,
        productList: payload,
        productListIsLoading: false,
      }
    }
    case GET_PRODUCT_LIST_GUEST_SUCCESS: {
      return {
        ...state,
        ...initialStateProductListGuest,
        productListGuestSuccess: true,
        productListGuest: payload,
        productListGuestIsLoading: false,
      }
    }
    case GET_PRODUCT_LIST_FAILED: {
      return {
        ...state,
        ...initialStateProductList,
        productListFailed: true,
        productListIsLoading: false,
        productListError: payload.errorMessage,
      }
    }
    case GET_PRODUCT_LIST_GUEST_FAILED: {
      return {
        ...state,
        ...initialStateProductListGuest,
        productListGuestFailed: true,
        productListGuestIsLoading: false,
        productListGuestError: payload.errorMessage,
      }
    }
    case FILTER_PRODUCTS_STARTED: {
      return {
        ...state,
        // ...initialStateFilterProducts,
        filterListSuccess: false,
        filterProductStarted: true,
        filterProductIsLoading: true,
        filterProductError: '',
      }
    }
    case FILTER_PRODUCTS_SUCCESS: {
      return {
        ...state,
        ...initialStateFilterProducts,
        filterProductSuccess: true,
        filterProduct: payload,
        filterProductIsLoading: false,
        productListIsLoading: false,
      }
    }
    case FILTER_PRODUCTS_FAILED: {
      return {
        ...state,
        ...initialStateFilterProducts,
        filterProductFailed: true,
        filterProductIsLoading: false,
        productListIsLoading: false,
        filterProductError: payload.errorMessage,
      }
    }

    case GET_FILTERS_STARTED: {
      return {
        ...state,
        ...initialStateFiltersList,
        filterListStarted: true,
        filterListIsLoading: true,
        filterListError: '',
      }
    }
    case GET_FILTERS_SUCCESS: {
      return {
        ...state,
        ...initialStateFiltersList,
        filterListSuccess: true,
        filterList: payload,
        filterListIsLoading: false,
      }
    }
    case GET_FILTERS_FAILED: {
      return {
        ...state,
        ...initialStateFiltersList,
        filterListFailed: true,
        filterListIsLoading: false,
        filterListError: payload.errorMessage,
      }
    }
    case CLEAR_PRODUCT_LIST: {
      return {
        ...state,
        ...initialStateProductList,
      }
    }
    case CLEAR_PRODUCT_LIST_GUEST: {
      return {
        ...state,
        ...initialStateProductListGuest,
      }
    }
    case CLEAR_FILTER_PRODUCTS: {
      return {
        ...state,
        ...initialStateFilterProducts,
      }
    }
    case SET_URL_GUEST_PARAMS: {
      return {
        ...state,
        urlGuestParams: payload,
      }
    }
    case REVIEW_PROPERTY_STARTED: {
      return {
        ...state,
        productListGuestIsLoading: true,
        ...initialStatePropertyReview,
        propertyReviewSuccess: false,
      }
    }
    case REVIEW_PROPERTY_SUCCESS: {
      return {
        ...state,
        productListGuestIsLoading: false,
        ...initialStatePropertyReview,
        propertyReviewSuccess: true,
        propertyReviewError: '',
      }
    }
    case REVIEW_PROPERTY_FAILURE: {
      return {
        ...state,
        productListGuestIsLoading: false,
        ...initialStatePropertyReview,
        propertyReviewSuccess: false,
        propertyReviewError: payload.errorMessage,
      }
    }
    case RESET_PRODUCT_LIST_TOASTS: {
      return {
        ...state,
        productListStarted: false,
        productListSuccess: false,
        productListIsLoading: false,
        productListFailed: false,
        productListError: '',
        filterProductStarted: false,
        filterProductSuccess: false,
        filterProductIsLoading: false,
        filterProductFailed: false,
        filterProductError: '',
        filterListStarted: false,
        filterListSuccess: false,
        filterListIsLoading: false,
        filterListFailed: false,
        filterListError: '',
        propertyReviewSuccess: false,
        propertyReviewError: '',
      }
    }

    case SAVE_PRODUCT_LIST_FIRST_PAGE_CACHE: {
      return {
        ...state,
        productListFirstPageCache: payload || [],
      }
    }
    default:
      return state
  }
}

export default productListReducer
