import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { calculateCartTotals } from '../../../utils/productUtils'

function Summary({ cartProducts }) {
  const { subtotal, shipping, taxes, savings } =
    calculateCartTotals(cartProducts)
  return (
    <section className="checkout-summary cont">
      <h2>Summary</h2>
      <div className="checkout-summary__operations">
        <span>
          <h4>Subtotal</h4>
          <p>${subtotal}</p>
        </span>
        <span>
          <h4>Shipping</h4>
          <p>{shipping}</p>
        </span>
        {taxes > 0 && (
          <span>
            <h4>Taxes</h4>
            <p>${taxes}</p>
          </span>
        )}
        <span>
          <h4>Coupon</h4>
          <p>-$0</p>
        </span>
      </div>
      <span className="total">
        <h4>Total</h4>
        <p>${subtotal}</p>
      </span>
      <span>
        <h4>Savings</h4>
        <p>${savings}</p>
      </span>
    </section>
  )
}

Summary.propTypes = {
  cartProducts: PropTypes.object.isRequired,
}

function mapStateToProps({ cart }) {
  return {
    cartProducts: cart.cartProducts,
  }
}

export default connect(mapStateToProps)(Summary)
