import api from '../../utils/api'
import {
  handleActionCatch,
  validateServerResponse,
} from '../../utils/errorUtils'
import {
  GET_PROPERTY_LIST_STARTED,
  GET_PROPERTY_LIST_SUCCESS,
  GET_PROPERTY_LIST_FAILED,
  DELETE_PROPERTY_STARTED,
  DELETE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_FAILED,
  GET_COMMISSION_INFO_STARTED,
  GET_COMMISSION_INFO_SUCCESS,
  GET_COMMISSION_INFO_FAILED,
  WITHDRAW_COMMISSION_STARTED,
  WITHDRAW_COMMISSION_SUCCESS,
  WITHDRAW_COMMISSION_FAILED,
  RESET_PROPERTY_LIST_TOASTS,
} from '../constants'

export const GetPropertyListStarted = (payload) => ({
  type: GET_PROPERTY_LIST_STARTED,
  ...payload,
})

export const GetPropertyListSuccess = (payload) => ({
  type: GET_PROPERTY_LIST_SUCCESS,
  payload,
})

export const GetPropertyListFailed = (payload) => ({
  type: GET_PROPERTY_LIST_FAILED,
  payload,
})

export const DeletePropertyStarted = (payload) => ({
  type: DELETE_PROPERTY_STARTED,
  ...payload,
})

export const DeletePropertySuccess = (payload) => ({
  type: DELETE_PROPERTY_SUCCESS,
  payload,
})

export const DeletePropertytFailed = (payload) => ({
  type: DELETE_PROPERTY_FAILED,
  payload,
})

export const getCommissionInfoStarted = () => ({
  type: GET_COMMISSION_INFO_STARTED,
})
export const getCommissionInfoSuccess = (payload) => ({
  type: GET_COMMISSION_INFO_SUCCESS,
  payload,
})
export const getCommissionInfoFailed = (payload) => ({
  type: GET_COMMISSION_INFO_FAILED,
  payload,
})
export const withdrawCommissionStarted = () => ({
  type: WITHDRAW_COMMISSION_STARTED,
})
export const withdrawCommissionSuccess = (payload) => ({
  type: WITHDRAW_COMMISSION_SUCCESS,
  ...payload,
})
export const withdrawCommissionFailed = (payload) => ({
  type: WITHDRAW_COMMISSION_FAILED,
  payload,
})

const resetPropertyListToasts = () => ({
  type: RESET_PROPERTY_LIST_TOASTS,
})

export const DeleteProperty =
  ({ id, token }) =>
  async (dispatch) => {
    dispatch(DeletePropertyStarted(id))
    try {
      const result = await api.Delete(`/property/${id}`, token)
      validateServerResponse(result)
      dispatch(DeletePropertySuccess(result.data?.data))
    } catch (error) {
      handleActionCatch(
        error,
        dispatch,
        DeletePropertytFailed,
        `Delete property ${id}`,
        { id, token }
      )
    }
    dispatch(resetPropertyListToasts())
  }

const GetPropertyList = (payload) => async (dispatch) => {
  const { page = 0, size = 10, token } = payload
  dispatch(GetPropertyListStarted(payload))
  try {
    const result = await api.Get(
      `/property/list?page=${page}&size=${size}`,
      token
    )
    validateServerResponse(result)
    dispatch(GetPropertyListSuccess(result.data?.data))
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      GetPropertyListFailed,
      'Get property list',
      payload
    )
  }
}

export const getCommissionInfo = (payload) => async (dispatch) => {
  dispatch(getCommissionInfoStarted())
  try {
    const result = await api.Get('/commissions/info', payload.token)
    validateServerResponse(result)
    dispatch(getCommissionInfoSuccess(result.data.data))
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      getCommissionInfoFailed,
      'Commission info',
      payload
    )
  }
}

export const withdrawCommission = (payload) => async (dispatch) => {
  const { withdrawCommissionsInfo, token } = payload
  dispatch(withdrawCommissionStarted())
  try {
    const result = await api.Post(
      '/withdrawal',
      {
        amount: withdrawCommissionsInfo.amount,
        contactInfo: withdrawCommissionsInfo.contactInfo,
      },
      token
    )
    validateServerResponse(result)
    dispatch(withdrawCommissionSuccess(result.data.data))
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      withdrawCommissionFailed,
      'Withdraw commission',
      payload
    )
  }
  dispatch(resetPropertyListToasts())
}

export default GetPropertyList
