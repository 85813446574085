import { Auth } from 'aws-amplify'
import analytics from '../../utils/analytics'
import api from '../../utils/api'
import {
  handleActionCatch,
  validateServerResponse,
} from '../../utils/errorUtils'
import {
  RESET_LOGIN_TOASTS,
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
} from '../constants'

export const LoginStarted = (payload) => ({
  type: LOGIN_STARTED,
  ...payload,
})

export const LoginSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
})

export const LoginFailed = (payload) => ({
  type: LOGIN_FAILED,
  payload,
})

const ResetLoginToasts = () => ({ type: RESET_LOGIN_TOASTS })

export const UserLogin = (payload) => async (dispatch) => {
  dispatch(LoginStarted(payload))
  try {
    const { email, password } = payload

    const authResponse = await Auth.signIn(email, password)
    const {
      attributes: { sub: cognitoUsername, name, email_verified: emailVerified },
      signInUserSession: {
        accessToken: { jwtToken },
      },
    } = authResponse

    const payloadToApi = { email, cognitoUsername }
    const result = await api.Post('/user/signin', payloadToApi)
    validateServerResponse(result)

    const resultToReducer = {
      jwtToken,
      user: {
        ...result.data.data.user,
        cognitoUsername,
        email,
        name,
        emailVerified,
      },
    }

    dispatch(LoginSuccess(resultToReducer))
    if (resultToReducer.user?.cognitoUsername) {
      analytics.addUserInfo(cognitoUsername, name, email)
    }
  } catch (error) {
    handleActionCatch(error, dispatch, LoginFailed, 'User login', payload)
  }
  dispatch(ResetLoginToasts())
}

export default UserLogin
