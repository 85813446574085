/* eslint-disable no-lonely-if */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import CheckBox from '../../../components/CheckBox'
import InputComponent from '../../../components/Inputs/InputComponent'

import { GetFilters } from '../../../redux/actions/CatalogActions'
import Icon from '../../../components/Icon'
import analytics from '../../../utils/analytics'
import HostActiveProductFiltersActions from '../../../redux/actions/HostActiveProductFiltersActions'
import HostProductFiltersRedux from '../../../redux/connectors/HostProductFiltersRedux'
import SideFiltersTestUtils from '../../../utils/testUtils/HostCatalogTestUtils/SideFiltersTestUtils'

const {
  DataIds: {
    BRAND_FILTER_UL: brandUl,
    PRICE_FILTER_UL: priceUl,
    FILTER_OPTION_LI: optionLi,
    CLEAR_ALL_BUTTON: clearAllButton,
    PRICE_MAX_INPUT: priceMaxInput,
    PRICE_MIN_INPUT: priceMinInput,
    PRICE_FILTER_GO_CLEAR_BUTTON: priceFilterGoClearBtn,
  },
} = SideFiltersTestUtils
const { SaveHostProductFilters } = HostActiveProductFiltersActions

const initialPriceRange = {
  priceMax: '',
  priceMin: '',
}

function SideFilters({
  filterList,
  source,
  setShowSideFilter,
  hostActiveProductFilters,
}) {
  const dispatch = useDispatch()
  const [rangeInput, setRangeInput] = useState(initialPriceRange)
  const [isPriceRangeBtnActive, setIsPriceRangeBtnActive] = useState(false)

  const productFilters = new HostProductFiltersRedux(
    hostActiveProductFilters,
    dispatch,
    SaveHostProductFilters
  )

  useEffect(() => {
    dispatch(GetFilters())
    if (
      productFilters.filters.priceMinStr !== '' ||
      productFilters.filters.priceMaxStr !== ''
    )
      setIsPriceRangeBtnActive(true)
    setRangeInput({
      priceMin: productFilters.filters.priceMin
        ? productFilters.filters.priceMinStr
        : initialPriceRange.priceMin,
      priceMax: productFilters.filters.priceMax
        ? productFilters.filters.priceMaxStr
        : initialPriceRange.priceMax,
    })
  }, [])

  const updateFilters = (filters) => {
    productFilters.updateSideFilters(filters)
  }

  const cleanSidefilters = () => {
    productFilters.clearSideFilters()
    setRangeInput(initialPriceRange)
  }

  // Clear priceRange inputs and button from "Side Filters" and "Search bar"
  // When class is updated, it updates priceRange state
  useEffect(() => {
    const { priceMax, priceMin } = productFilters.filters
    if (priceMax === 0 && priceMin === 0) {
      setRangeInput(initialPriceRange)
      setIsPriceRangeBtnActive(false)
    }
  }, [productFilters.filters.priceMin, productFilters.filters.priceMax])

  const isChecked = (name, type) => {
    switch (type) {
      case 'brands':
        return productFilters.filters.vendors.includes(name)
      case 'categories':
        return productFilters.filters.categories.includes(name)
      default:
        throw new Error(`${type} filter is not supported`)
    }
  }

  // Change filters when a checkbox is clicked
  const handleCheckClicked = ({ name }, type) => {
    switch (type) {
      case 'brands': {
        if (isChecked(name, type)) {
          updateFilters({
            vendors: productFilters.filters.vendors.filter(
              (vendor) => vendor !== name
            ),
          })
        } else {
          updateFilters({
            vendors: [...productFilters.filters.vendors, name],
          })
          analytics.filterByVendor(name, false)
        }
        break
      }
      case 'categories': {
        if (isChecked(name, type)) {
          updateFilters({
            categories: productFilters?.filters.categories.filter(
              (category) => category !== name
            ),
          })
        } else {
          updateFilters({
            categories: [...productFilters.filters.categories, name],
          })
        }
        break
      }
      default: {
        break
      }
    }
  }

  /* Parameters received in this function are type number,
  so there's no need for conversion before sending to class. */
  const handlePriceRangeSelection = ({ priceMin, priceMax }) => {
    setRangeInput(initialPriceRange)
    updateFilters({
      priceRange: {
        priceMin,
        priceMax,
      },
    })
  }

  const handleChangeInputPrice = ({ name, value }) => {
    if (isPriceRangeBtnActive) setIsPriceRangeBtnActive(false)
    setRangeInput({
      ...rangeInput,
      [name]: value,
    })
  }

  // Values from rangeInput state are type string. To be sent to class they need to be numbers
  const handlePriceRangeWithInputs = () => {
    const { priceMax, priceMin } = rangeInput
    const priceMaxNum = Number(priceMax)
    const priceMinNum = Number(priceMin)
    if (priceMaxNum > 0 && priceMaxNum > priceMinNum) {
      updateFilters({
        priceRange: {
          priceMin: priceMinNum,
          priceMax: priceMaxNum,
        },
      })
      setIsPriceRangeBtnActive(true)
    }
  }

  // Clean priceRange inputs and button with "Clear" btn
  // Update class and PriceRange state
  const handleClearInputBtn = () => {
    updateFilters({
      priceRange: {
        priceMin: 0,
        priceMax: 0,
      },
    })
    setRangeInput(initialPriceRange)
    setIsPriceRangeBtnActive(false)
  }

  const renderFilterOptions = (type) => {
    if (Object.values(filterList).length > 1) {
      return filterList[type].data.map((item) => (
        <li key={`${item.title}-${item.id}`} sp-data-id={optionLi}>
          <CheckBox
            id={`${item.title}-${item.id}`}
            labelText={item.title}
            name={item.title}
            checked={isChecked(item.title, type)}
            onClick={({ target }) => handleCheckClicked(target, type)}
          />
        </li>
      ))
    }
    return null
  }

  const renderFilterPriceOptions = () => {
    if (Object.values(filterList).length > 1) {
      return filterList.price_range.data[0].map((item) => (
        <li key={`${item.title}-${item.id}`}>
          <button
            type="button"
            className={`no-shape price-range ${
              item.priceMin === productFilters?.filters.priceMin &&
              item.priceMax === productFilters?.filters.priceMax
                ? 'selected'
                : ''
            }`}
            id={item.title}
            name={item.title}
            onClick={() => handlePriceRangeSelection(item)}
          >
            {item.title}
          </button>
        </li>
      ))
    }
    return null
  }

  return (
    <section className="side-filters">
      {source && (
        <button
          type="button"
          className="side-filters__close-btn"
          onClick={() => setShowSideFilter()}
        >
          <Icon item="closeFilter" width="20px" height="20px" fill="#FFFFFF" />
        </button>
      )}

      {source && (
        <div className="side-filters__header">
          <button
            type="button"
            className="side-filters__btn"
            onClick={() => setShowSideFilter()}
          >
            <Icon
              item="filter-mobile"
              width="20px"
              height="25px"
              fill="#767980"
              stroke="#767980"
              className="catalog__icon-filter"
            />
            FILTERS
          </button>
          <button
            type="button"
            className="no-shape"
            onClick={() => cleanSidefilters()}
            sp-data-id={clearAllButton}
          >
            <Icon item="clean" fill="#5a91dd" stroke="#5a91dd" />
            <p> Clear all</p>
          </button>
        </div>
      )}

      {!source && (
        <button
          type="button"
          className="no-shape"
          onClick={() => cleanSidefilters()}
          sp-data-id={clearAllButton}
        >
          <Icon item="clean" fill="#5a91dd" stroke="#5a91dd" />
          <p> Clear all</p>
        </button>
      )}

      {filterList?.brands?.data?.length >= 1 && (
        <div>
          <h3>Brand</h3>
          <ul sp-data-id={brandUl}>{renderFilterOptions('brands')}</ul>
        </div>
      )}

      {filterList?.categories?.data?.length >= 1 && (
        <div>
          <h3>Category</h3>
          <ul>{renderFilterOptions('categories')}</ul>
        </div>
      )}

      <div>
        <h3>Price</h3>
        <ul sp-data-id={priceUl}>{renderFilterPriceOptions()}</ul>
        <div className="range-inputs">
          <InputComponent
            outlined
            name="priceMin"
            value={rangeInput.priceMin}
            type="number"
            placeHolder="$ Min"
            className="range-min"
            onChange={({ target }) => handleChangeInputPrice(target)}
            id="min"
            spDataId={priceMinInput}
          />
          <InputComponent
            outlined
            name="priceMax"
            value={rangeInput.priceMax}
            type="number"
            placeHolder="$ Max"
            className="range-max"
            onChange={({ target }) => handleChangeInputPrice(target)}
            id="max"
            spDataId={priceMaxInput}
          />
          <button
            type="button"
            className="no-shape"
            onClick={() =>
              isPriceRangeBtnActive
                ? handleClearInputBtn()
                : handlePriceRangeWithInputs()
            }
            sp-data-id={priceFilterGoClearBtn}
          >
            {isPriceRangeBtnActive ? 'Clear' : 'Go'}
          </button>
        </div>
      </div>
    </section>
  )
}

SideFilters.propTypes = {
  filterList: PropTypes.object.isRequired,
  source: PropTypes.bool.isRequired,
  setShowSideFilter: PropTypes.func.isRequired,
  hostActiveProductFilters: PropTypes.object.isRequired,
}

function mapStateToProps({
  hostActiveProductFilters,
  productList: { filterList },
}) {
  return {
    hostActiveProductFilters,
    filterList,
  }
}

export default connect(mapStateToProps)(SideFilters)
