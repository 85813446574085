/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import Button from '../../components/Buttons/Button'
import LoaderComponent from '../../components/Loader'
import ModalComponent from '../../components/ModalComponent'
import InputComponent from '../../components/Inputs/InputComponent'
import TableComponent from '../../components/Table'
import infoIcon from '../../assets/icons/info-circle.svg'
import MobilePropertyList from './MobilePropertyList'
import linkIcon from '../../assets/icons/link.svg'
import useHandleMobile from '../../hooks/useHandleMobile'

import GetPropertyList, {
  DeleteProperty,
  getCommissionInfo,
  withdrawCommission,
} from '../../redux/actions/PropertyListActions'
import {
  ClearPropertyDetails,
  ClearPurchaseHistory,
  ClearPurchasePagination,
} from '../../redux/actions/PropertyDetailsAction'
import ErrorAlert from '../../components/ErrorAlert'
import notifyToast from '../../utils/notifyToast'
import {
  extractSessionInfo,
  setPageTitleAndUserInfo,
  truncateStr,
} from '../../utils'
import { roundToDecimals } from '../../utils/productUtils'

function Propertylist({
  session,
  propertyList,
  isLoading,
  propertyListError,
  deletedProperty,
  deletedMsg,
  commissions,
  withdrawCommissionSuccess,
  withdrawCommissionError,
}) {
  const { userId, userName, userEmail, jwt } = extractSessionInfo(session)

  useEffect(
    () => setPageTitleAndUserInfo('Property List', userId, userName, userEmail),
    []
  )

  const { mobile } = useHandleMobile()
  const [list, setList] = useState({})
  const [currentPage, setCurrentPage] = useState(0)
  const [showModal, setShowModal] = useState({
    show: false,
    id: 0,
  })
  const [openCommissionsModal, setOpenCommissionsModal] = useState(false)
  const [withdrawCommissionsInfo, setWithdrawCommissionsInfo] = useState({
    amount: '',
    contactInfo: '',
  })

  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(ClearPropertyDetails())
    dispatch(ClearPurchaseHistory())
    dispatch(ClearPurchasePagination())
    dispatch(getCommissionInfo({ token: jwt }))
    if (!Object.values(list).length >= 1) {
      dispatch(GetPropertyList({ token: jwt }))
    }
  }, [])

  useEffect(() => {
    if (Object.values(propertyList).length >= 1) {
      setList(propertyList)
    }
  }, [propertyList])

  useEffect(() => {
    dispatch(
      GetPropertyList({
        token: jwt,
        page: currentPage,
      })
    )
    dispatch(getCommissionInfo({ token: jwt }))
  }, [currentPage])

  useEffect(() => {
    if (deletedProperty) {
      notifyToast('success', deletedMsg.description, 2000)
      setShowModal({
        id: 0,
        show: false,
      })
      dispatch(GetPropertyList({ token: jwt }))
    }
  }, [deletedProperty])

  useEffect(() => {
    if (withdrawCommissionSuccess) {
      notifyToast(
        'success',
        'Request to withdraw commissions has been generated successfully',
        3500
      )
      setOpenCommissionsModal(false)
      dispatch(getCommissionInfo({ token: jwt }))
    } else if (withdrawCommissionError?.length >= 1)
      notifyToast('error', withdrawCommissionError, 2000)
  }, [withdrawCommissionSuccess, withdrawCommissionError])

  const handleEdit = (event, id) => {
    event.stopPropagation()
    navigate(`/property/${id}/edit`)
  }

  const handleDelete = () =>
    dispatch(DeleteProperty({ id: showModal.id, token: jwt }))

  const handleShowModal = () =>
    setShowModal({
      id: 0,
      show: false,
    })

  const shouldShowModal = (event, id) => {
    event.stopPropagation()
    setShowModal({
      show: true,
      id,
    })
  }

  const handleRedirect = () => navigate('/property/new')

  const copyToClipboard = (event, copyText) => {
    event.stopPropagation()
    navigator.clipboard.writeText(copyText)
    notifyToast('success', 'Link copied successfully')
  }

  /* Commissions withdraw */
  const handleOpenCommissionsModal = () => {
    setWithdrawCommissionsInfo({
      amount: '',
      contactInfo: '',
    })
    setOpenCommissionsModal(true)
  }

  const handleCloseCommissionsModal = () => setOpenCommissionsModal(false)

  const handleChange = ({ name, value }) => {
    const regex = /[a-zA-Z!"#$%&/()=?¡°|;:_'¿,+*{}`´ÑñÄËÏÖÜ\\\-\[\]¨\s]/g
    const numberValue = value.replaceAll(regex, '')
    setWithdrawCommissionsInfo({
      ...withdrawCommissionsInfo,
      [name]: name === 'amount' ? numberValue : value.trim(),
    })
  }

  const handleConfirmWithdraw = () => {
    dispatch(withdrawCommission({ withdrawCommissionsInfo, token: jwt }))
    /* setOpenCommissionsModal(false) */
  }

  const invalidAmount =
    parseFloat(withdrawCommissionsInfo.amount) >
    commissions?.commissions?.availableWithdraw

  const isDisabled =
    withdrawCommissionsInfo.amount === '' ||
    withdrawCommissionsInfo.contactInfo === '' ||
    invalidAmount

  const tableBody = () => {
    if (Object.values(list).length >= 1) {
      return list.elements.map((item) => (
        <tr
          key={item.id}
          onClick={() => navigate(`/property/${item.id}/details`)}
        >
          <td>{item.nickName}</td>
          <td>{item.address}</td>
          <td>{item.state}</td>
          <td>{item.city}</td>
          <td>${roundToDecimals(item.spent)}</td>
          <td>
            {item.propertyUrl ? (
              <span>
                <a
                  href={`${item.propertyUrl}/product/list`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {truncateStr(`${item.propertyUrl}/product/list`, 80)}
                </a>
                <button
                  type="button"
                  onClick={(event) =>
                    copyToClipboard(event, `${item.propertyUrl}/product/list`)
                  }
                >
                  <img src={linkIcon} alt="link" />
                </button>
              </span>
            ) : (
              ''
            )}
          </td>
          <td className="action__buttons">
            <button
              type="button"
              onClick={(event) => handleEdit(event, item.id)}
            >
              Edit
            </button>
            <button
              type="button"
              onClick={(event) => shouldShowModal(event, item.id)}
            >
              Delete
            </button>
          </td>
        </tr>
      ))
    }
    return null
  }

  return (
    <section className="property">
      <div className="commission">
        <div className="text1">
          <p className="commission-text">Total Commissions earned:</p>
          <span className="commission-number">
            {`$${roundToDecimals(commissions?.commissions?.commissionEarned)}`}
          </span>
        </div>
        <div className="text2">
          <p className="commission-text">Commissions Available to withdraw:</p>
          <span className="commission-number">
            {`$${roundToDecimals(commissions?.commissions?.availableWithdraw)}`}
          </span>
        </div>
        <div className="commission-btn">
          <Button
            id="withdraw"
            className="withdraw"
            action={() => handleOpenCommissionsModal()}
            text="Withdraw"
          />
        </div>
      </div>
      <div className="property-list container">
        <LoaderComponent show={isLoading} text="Please wait..." />
        {propertyListError && <ErrorAlert error={propertyListError} />}
        <div className="property-list__table-header">
          <h4>My Properties</h4>
          <Button
            id="add_property"
            action={() => handleRedirect()}
            text="+ Add Property"
          />
        </div>
        {list?.totalElements >= 1 && !mobile && (
          <div className="table-container">
            <TableComponent
              id="properties-list"
              tableHead={[
                'Nickname',
                'Address',
                'State',
                'City',
                'Total Spent',
                'Property Link',
                '',
              ]}
              paginateOptions={{
                totalPages: list?.totalPages,
                currentPage,
                handleNextPage: setCurrentPage,
                handlePreviousPage: setCurrentPage,
              }}
            >
              <tbody>{tableBody()}</tbody>
            </TableComponent>
          </div>
        )}
        {list?.totalElements >= 1 && mobile && (
          <div className="mobile-cards-container">
            {Object.values(list).length >= 1 && (
              <MobilePropertyList
                list={list}
                shouldShowModal={(event, item) => shouldShowModal(event, item)}
                handleEdit={(event, item) => handleEdit(event, item)}
                copyToClipboard={(event, item) => copyToClipboard(event, item)}
              />
            )}
          </div>
        )}
        <ModalComponent show={showModal.show} className="delete-property">
          <div>
            <h4>Are you sure you want to delete the property?</h4>
            <div className="delete-property-modal__buttons">
              <Button
                id="cancelDelete"
                secondary
                text="Cancel"
                action={() => handleShowModal()}
              />
              <Button id="delete" text="Delete" action={() => handleDelete()} />
            </div>
          </div>
        </ModalComponent>

        {/* Commissions modal */}
        <ModalComponent show={openCommissionsModal} className="withdraw">
          <div className="withdraw-modal__inputs">
            <InputComponent
              id="amount"
              name="amount"
              value={withdrawCommissionsInfo.amount}
              labelText="Amount to withdraw"
              onChange={({ target }) => handleChange(target)}
              outlined
              invalid={invalidAmount}
              invalidText={`Commissions available to withdraw: $${commissions?.commissions?.availableWithdraw}`}
              className="col-12 col-sm-6 amount-input"
            />
            <InputComponent
              id="pay_to"
              name="contactInfo"
              value={withdrawCommissionsInfo.contactInfo}
              labelText="Paypal email or phone number"
              onChange={({ target }) => handleChange(target)}
              outlined
              className="col-12 col-sm-6 pay-to-input"
            />
          </div>
          <div className="withdraw-modal__information">
            <div>
              <img id="infoIcon" src={infoIcon} alt="info circle" />
            </div>
            <p>
              Once you click on &quot;Confirm withdraw&quot;, we will receive
              your request and we will send you an email when your commissions
              are deposited to your Paypal account.
            </p>
          </div>
          <div className="withdraw-modal__buttons">
            <Button
              id="CancelWithdraw"
              secondary
              text="Cancel"
              action={() => handleCloseCommissionsModal()}
            />
            <Button
              id="confirmWithdraw"
              text="Confirm Withdraw"
              action={() => handleConfirmWithdraw()}
              disabled={isDisabled}
            />
          </div>
        </ModalComponent>
      </div>
    </section>
  )
}

Propertylist.propTypes = {
  session: PropTypes.object.isRequired,
  propertyList: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  propertyListError: PropTypes.string.isRequired,
  deletedProperty: PropTypes.bool.isRequired,
  deletedMsg: PropTypes.object.isRequired,
  commissions: PropTypes.object.isRequired,
  withdrawCommissionSuccess: PropTypes.bool.isRequired,
  withdrawCommissionError: PropTypes.string.isRequired,
}

function mapStateToProps({ login, propertyList }) {
  return {
    session: login.login,
    propertyList: propertyList.propertyList,
    isLoading:
      propertyList.propertyListIsLoading ||
      propertyList.deletePropertyIsLoading ||
      propertyList.withdrawCommissionisLoading ||
      propertyList.commissionsIsLoading,
    propertyListError: propertyList.propertyListError,
    deletedProperty: propertyList.deletePropertySuccess,
    deletedMsg: propertyList.deleteProperty,
    commissions: propertyList.commissions,
    withdrawCommissionSuccess: propertyList.withdrawCommissionSuccess,
    withdrawCommissionError: propertyList.withdrawCommissionError,
  }
}

export default connect(mapStateToProps)(Propertylist)
