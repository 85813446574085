/**
 * Rounds a number or string to the nearest 2 decimal points
 * @param {number | string} num - Number (or string with number) to round
 * @param {number} decimals - Number of decimals to keep (default 2)
 * @returns String with the specified rounded decimal points
 */
export const roundToDecimals = (num, decimals = 2) => {
  const parseAndRound = (number) => parseFloat(number).toFixed(decimals)
  if (typeof num === 'number') return parseAndRound(num)
  if (typeof num === 'string') {
    const numeric = Number(num)
    if (numeric && !Number.isNaN(numeric)) return parseAndRound(numeric)
  }

  if (!num) return '0.00'

  return parseAndRound(num)
}

export const calculateProductTotal = (products, prop) => {
  const result = products?.reduce((acum, curr) => acum + (curr[prop] || 0), 0)
  return roundToDecimals(result)
}

export const calculateSavings = (products) => {
  const totalPrice = products?.reduce(
    (acum, curr) =>
      acum + (curr.quantity * curr.compareAtPrice - curr.subtotal || 0),
    0
  )
  return totalPrice
}

export const calculateCartTotals = (products) => {
  const subtotal = calculateProductTotal(products, 'subtotal')
  const shipping = calculateProductTotal(products, 'shippingCost')
  const taxes = calculateProductTotal(products, 'tax')
  const savings = calculateSavings(products, 'compareAtPrice')

  return {
    subtotal,
    shipping,
    taxes,
    savings,
  }
}

export const shippingCostText = (shippingCostNum) => {
  if (!shippingCostNum) return 'Free'
  return `$${roundToDecimals(shippingCostNum)}`
}

export const getTotalAmount = (price, quantity) =>
  roundToDecimals(price * quantity)
