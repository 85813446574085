/* eslint-disable no-return-assign */
import api from '../../utils/api'
import {
  handleActionCatch,
  validateServerResponse,
} from '../../utils/errorUtils'
import notifyToast from '../../utils/notifyToast'
import {
  RESET_PRODUCT_LIST_TOASTS,
  GET_PRODUCT_LIST_STARTED,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_FAILED,
  GET_PRODUCT_LIST_GUEST_STARTED,
  GET_PRODUCT_LIST_GUEST_SUCCESS,
  GET_PRODUCT_LIST_GUEST_FAILED,
  FILTER_PRODUCTS_STARTED,
  FILTER_PRODUCTS_SUCCESS,
  FILTER_PRODUCTS_FAILED,
  CLEAR_PRODUCT_LIST,
  CLEAR_FILTER_PRODUCTS,
  CLEAR_PRODUCT_LIST_GUEST,
  GET_FILTERS_STARTED,
  GET_FILTERS_SUCCESS,
  GET_FILTERS_FAILED,
  SET_URL_GUEST_PARAMS,
  REVIEW_PROPERTY_STARTED,
  REVIEW_PROPERTY_SUCCESS,
  REVIEW_PROPERTY_FAILURE,
  SAVE_PRODUCT_LIST_FIRST_PAGE_CACHE,
} from '../constants'

export const GetProductsListStarted = (payload) => ({
  type: GET_PRODUCT_LIST_STARTED,
  ...payload,
})

export const GetProductsListSuccess = (payload) => ({
  type: GET_PRODUCT_LIST_SUCCESS,
  payload,
})

export const GetProductsListFailed = (payload) => ({
  type: GET_PRODUCT_LIST_FAILED,
  payload,
})

export const ClearProductsList = () => ({
  type: CLEAR_PRODUCT_LIST,
})

export const ClearProductsListGuest = () => ({
  type: CLEAR_PRODUCT_LIST_GUEST,
})

export const ClearFilterProducts = () => ({
  type: CLEAR_FILTER_PRODUCTS,
})

export const filterProductsStarted = (payload) => ({
  type: FILTER_PRODUCTS_STARTED,
  ...payload,
})

export const filterProductsSuccess = (payload) => ({
  type: FILTER_PRODUCTS_SUCCESS,
  payload,
})

export const filterProductsFailed = (payload) => ({
  type: FILTER_PRODUCTS_FAILED,
  payload,
})

export const GetFiltersStarted = () => ({
  type: GET_FILTERS_STARTED,
})

export const GetFiltersSuccess = (payload) => ({
  type: GET_FILTERS_SUCCESS,
  payload,
})

export const GetFiltersFailed = (payload) => ({
  type: GET_FILTERS_FAILED,
  payload,
})

export const GetProductsListGuestStarted = (payload) => ({
  type: GET_PRODUCT_LIST_GUEST_STARTED,
  ...payload,
})

export const GetProductsListGuestSuccess = (payload) => ({
  type: GET_PRODUCT_LIST_GUEST_SUCCESS,
  payload,
})

export const GetProductsListGuestFailed = (payload) => ({
  type: GET_PRODUCT_LIST_GUEST_FAILED,
  payload,
})

export const SetUrlGuestParams = (payload) => ({
  type: SET_URL_GUEST_PARAMS,
  payload,
})

export const reviewPropertyStarted = () => ({ type: REVIEW_PROPERTY_STARTED })
export const reviewPropertySuccess = (payload) => {
  notifyToast('success', 'Review added successfully', 2000)
  return { type: REVIEW_PROPERTY_SUCCESS, ...payload }
}
export const reviewPropertyFailure = (payload) => {
  notifyToast('error', payload.propertyReviewError)
  return { type: REVIEW_PROPERTY_FAILURE, payload }
}

export const SaveProductListFirstPageCache = (payload) => ({
  type: SAVE_PRODUCT_LIST_FIRST_PAGE_CACHE,
  payload,
})

const ResetProductListTotast = () => ({ type: RESET_PRODUCT_LIST_TOASTS })

const GetProductsList =
  (payload = { nextPage: null, token: '' }) =>
  async (dispatch) => {
    let baseUrl = '/products/list?limit=12'
    const { nextPage, token } = payload
    if (nextPage) {
      baseUrl = `${baseUrl}&page_info=${nextPage}`
    }
    dispatch(GetProductsListStarted())
    try {
      const result = await api.Get(baseUrl, token)
      validateServerResponse(result)
      dispatch(GetProductsListSuccess(result.data.data))
    } catch (error) {
      handleActionCatch(
        error,
        dispatch,
        GetProductsListFailed,
        'Get product list',
        payload
      )
    }
    dispatch(ResetProductListTotast())
  }

export const GetProductsListGuest =
  (payload = { page: 0, token: '', id: '0' }) =>
  async (dispatch) => {
    const { page, id, token } = payload
    const isPropertyVisited =
      sessionStorage.getItem(`visitedProperties:${id}`) !== null
    if (!isPropertyVisited) {
      sessionStorage.setItem(`visitedProperties:${id}`, true)
    }
    const baseUrl = `/property/products/guest/${id}?page=${page}&size=10&visited=${
      isPropertyVisited ? 1 : 0
    }`
    dispatch(GetProductsListGuestStarted())
    try {
      const result = await api.Get(baseUrl, token)
      validateServerResponse(result)
      dispatch(GetProductsListGuestSuccess(result.data.data))
    } catch (error) {
      handleActionCatch(
        error,
        dispatch,
        GetProductsListGuestFailed,
        'Get product list guest',
        payload
      )
    }
    dispatch(ResetProductListTotast())
  }

const handleFilterTags = (filters) => {
  let baseUrl = '/products/list?limit=12'
  Object.keys(filters).forEach((filter) => {
    if (filter === 'categories') {
      const additionalUrl = filters[filter].join()
      baseUrl = `${baseUrl}${
        additionalUrl !== '' ? `&categories=${additionalUrl}` : ''
      }`
    } else if (filter === 'tag') {
      baseUrl = `${baseUrl}${filters.tag !== '' ? `&tags=${filters.tag}` : ''}`
    } else if (filter === 'vendors') {
      const additionalUrl = filters[filter].join()
      baseUrl = `${baseUrl}${
        additionalUrl !== '' ? `&vendor=${additionalUrl}` : ''
      }`
    } else if (filter === 'priceRange') {
      const hasRange = Object.values(filters[filter]).length >= 1
      baseUrl = `${baseUrl}${
        hasRange
          ? `&priceMin=${filters[filter]?.priceMin}&priceMax=${filters[filter]?.priceMax}`
          : ''
      }`
    } else {
      baseUrl = `${baseUrl}&search=${filters.toSearch}`
    }
  })
  return baseUrl
}

export const filterProducts =
  ({ filters, next = null, token = '' }) =>
  async (dispatch) => {
    dispatch(filterProductsStarted())
    let url = handleFilterTags(filters)
    if (next) {
      url = `${url}&page_info=${next}`
      dispatch(GetProductsListStarted())
    }
    try {
      const result = await api.Get(url, token)
      validateServerResponse(result)
      dispatch(
        filterProductsSuccess({
          filters: {
            tag: filters.tag,
            categories: filters.categories,
            toSearch: filters.toSearch,
            vendors: filters.vendors,
            priceRange: filters.priceRange,
          },
          response: result.data.data,
        })
      )
    } catch (error) {
      handleActionCatch(
        error,
        dispatch,
        filterProductsFailed,
        'Get Filter List',
        { filters, next, token }
      )
    }
    dispatch(ResetProductListTotast())
  }

export const GetFilters = () => async (dispatch) => {
  dispatch(GetFiltersStarted())
  const url = '/filters/list'
  try {
    const result = await api.Get(url)
    validateServerResponse(result)
    dispatch(GetFiltersSuccess(result.data.data.filter))
  } catch (error) {
    dispatch(GetFiltersFailed(error.response.data.error))
  }
  dispatch(ResetProductListTotast())
}

export const reviewProperty = (payload) => async (dispatch) => {
  dispatch(reviewPropertyStarted())
  try {
    const result = await api.Post('/property/review', {
      propertyId: payload?.propertyId,
      review: payload?.propertyReview,
    })
    validateServerResponse(result)
    dispatch(reviewPropertySuccess(result.data.data))
    dispatch(ResetProductListTotast())
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      reviewPropertyFailure,
      'Review property',
      payload
    )
    dispatch(ResetProductListTotast())
  }
}

export default GetProductsList
