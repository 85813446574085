/* eslint default-param-last: off */

import { defaultGuestProductFilters } from '../../domain/GuestProductFilters'
import { SAVE_GUEST_ACTIVE_PRODUCT_FILTERS } from '../constants'

const initialState = { ...defaultGuestProductFilters }

const guestActiveProductFilters = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SAVE_GUEST_ACTIVE_PRODUCT_FILTERS:
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}

export default guestActiveProductFilters
