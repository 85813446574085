import React from 'react'
import PropTypes from 'prop-types'

function Button(props) {
  const {
    className,
    id,
    text,
    disabled,
    secondary,
    tertiary,
    action,
    children,
    small,
    noButtonBehavior,
  } = props
  return (
    <button
      type="button"
      className={`Button ${className}-Button ${
        noButtonBehavior ? 'no-behavior' : ''
      }`}
      disabled={disabled}
      id={id}
      primary={!(tertiary || secondary) ? 'true' : undefined}
      secondary={secondary ? 'true' : undefined}
      tertiary={tertiary ? 'true' : undefined}
      small={small ? 'true' : undefined}
      onClick={action}
    >
      {children}
      {text}
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  action: PropTypes.func,
  children: PropTypes.node,
  small: PropTypes.bool,
  noButtonBehavior: PropTypes.bool,
}

Button.defaultProps = {
  className: '',
  text: 'Click me',
  disabled: false,
  secondary: false,
  tertiary: false,
  action: () => {},
  children: undefined,
  small: false,
  noButtonBehavior: false,
}

export default Button
