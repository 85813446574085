/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { format } from 'date-fns'
import PropTypes from 'prop-types'

import MobileOrderDetail from './MobileOrderDetail'
import useHandleMobile from '../../hooks/useHandleMobile'
import LoaderComponent from '../../components/Loader'
import Button from '../../components/Buttons/Button'
import TableComponent from '../../components/Table'
import ModalComponent from '../../components/ModalComponent'
import ProductRow from './ProductRow'

import GetOrdersDetails from '../../redux/actions/OrdersDetailsActions'
import {
  CancelOrder,
  ClearCancelOrders,
} from '../../redux/actions/OrdersActions'
import notifyToast from '../../utils/notifyToast'

import { roundToDecimals, shippingCostText } from '../../utils/productUtils'
import { orderFinancialStatus, orderFulfillmentStatus } from '../../utils/enums'
import { extractSessionInfo, setPageTitleAndUserInfo } from '../../utils'

function OrdersDetails({ details, isLoading, session, orderCanceled }) {
  const { mobile } = useHandleMobile()
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [info, setInfo] = useState(details)
  const [showModal, setShowModal] = useState(false)
  const id = location.pathname.split('/')[2]

  const { userId, userName, userEmail, jwt } = extractSessionInfo(session)

  useEffect(
    () => setPageTitleAndUserInfo('Order Details', userId, userName, userEmail),
    []
  )

  useEffect(() => {
    dispatch(
      GetOrdersDetails({
        token: jwt,
        id,
      })
    )
  }, [])

  useEffect(() => {
    if (orderCanceled && !isLoading) {
      notifyToast(
        'success',
        `Order #${info.orderNumber} has been successfully deleted`
      )
      setTimeout(() => {
        dispatch(ClearCancelOrders())
        navigate('/orders')
      }, 1000)
    }
  }, [orderCanceled])

  useEffect(() => {
    if (details?.id) {
      setInfo(details)
    }
  }, [details])

  const isThereInfo = () => !!info?.id

  const showMainInfo = () => {
    if (isThereInfo()) {
      const {
        fulfillmentStatus,
        financialStatus,
        createdAt,
        orderNumber,
        spStatus,
      } = info
      const orderDate = createdAt
        ? format(new Date(createdAt), 'yyyy-MM-dd')
        : null
      return (
        <div className="order-details__main-info">
          <span>
            <h3>Order Number: </h3>
            <p>{orderNumber}</p>
          </span>
          <span>
            <h3>Payment Status: </h3>
            <p>{financialStatus}</p>
          </span>
          <span>
            <h3>Fulfillment Status: </h3>
            <p>{fulfillmentStatus}</p>
          </span>
          <span>
            <h3>Order Date: </h3>
            <p>{orderDate}</p>
          </span>
          <span>
            <h3>Order Status: </h3>
            <p>{spStatus}</p>
          </span>
        </div>
      )
    }
    return null
  }

  const showShippingInfo = () => {
    if (isThereInfo()) {
      const { shippingAddress1, state, city, zip } = info
      return (
        <div className="order-details__shipping-info">
          <span>
            <h4>Shipping Address: </h4>
            <p>{shippingAddress1}</p>
          </span>
          <span>
            <h4>City: </h4>
            <p>{city}</p>
          </span>
          <span>
            <h4>State: </h4>
            <p>{state}</p>
          </span>
          <span>
            <h4>Zip Code: </h4>
            <p>{zip}</p>
          </span>
        </div>
      )
    }
    return null
  }

  const handleCancelOrder = () => {
    dispatch(
      CancelOrder({
        id,
        token: jwt,
      })
    )
  }

  const showSummary = () => {
    if (isThereInfo()) {
      const {
        subtotalPrice,
        totalPrice,
        totalTax,
        totalDiscounts,
        totalShippingCost,
        paymentMethod,
        // creditCardLastDigits,
      } = info
      return (
        <div className="order-details__summary">
          <div>
            <h3>Summary</h3>
            <article>
              <span>
                <h4>Subtotal: </h4>
                <p>{`$${roundToDecimals(subtotalPrice)}`}</p>
              </span>
              <span>
                <h4>Shipping: </h4>
                <p>{shippingCostText(totalShippingCost)}</p>
              </span>
              {!!totalTax && (
                <span>
                  <h4>Taxes: </h4>
                  <p>{`$${roundToDecimals(totalTax)}`}</p>
                </span>
              )}
              <span>
                <h4>Coupon: </h4>
                <p>{`-$${roundToDecimals(totalDiscounts)}`}</p>
              </span>
              <span className="total">
                <h4>Total: </h4>
                <p>{`$${roundToDecimals(totalPrice)}`}</p>
              </span>
              <span>
                <h4>Savings: </h4>
                <p>$0.00</p>
              </span>
              <span>
                <h4>Payment Method: </h4>
                <p>{paymentMethod}</p>
              </span>
            </article>
          </div>
          {info?.financialStatus === orderFinancialStatus.PAID &&
            info?.fulfillmentStatus === orderFulfillmentStatus.UNFULFILLED && (
              <Button
                id="cancelOrder"
                secondary
                text="Cancel Order"
                action={() => setShowModal(true)}
              />
            )}
        </div>
      )
    }
    return null
  }

  const handleProductRow = (item, idx) => {
    const {
      variantId,
      image,
      name: title,
      price,
      quantity,
      externalProductId,
    } = item
    const product = {
      variantId,
      image,
      title,
      price,
      quantity,
      externalProductId,
    }
    return (
      <tr>
        <ProductRow product={product} altBackground={idx % 2 === 1} />
      </tr>
    )
  }

  const handleShowModal = () => setShowModal(false)

  return (
    <section className="order-details cont">
      <LoaderComponent show={isLoading} />
      {!mobile && (
        <div className="order-details__info">
          {showMainInfo()}
          {showShippingInfo()}
        </div>
      )}
      {!mobile && (
        <div className="order-details__container">
          <div>
            <div className="order-details__products-info">
              <h2>Products</h2>
              {info?.orderLines && (
                <TableComponent id="purchase_history_table">
                  <tbody>
                    {info.orderLines.map((orderLine, idx) =>
                      handleProductRow(orderLine, idx)
                    )}
                  </tbody>
                </TableComponent>
              )}
            </div>
          </div>
          {!mobile && showSummary()}
        </div>
      )}
      {mobile && isThereInfo() && <MobileOrderDetail details={details} />}
      <ModalComponent show={showModal} className="cancel-order">
        <div>
          <h4>Are you sure you want to cancel?</h4>
          <div className="cancel-order-modal__buttons">
            <Button
              id="back"
              secondary
              text="Back"
              action={() => handleShowModal()}
            />
            <Button
              id="cancel"
              text="Cancel Order"
              action={() => handleCancelOrder()}
            />
          </div>
        </div>
      </ModalComponent>
    </section>
  )
}

OrdersDetails.propTypes = {
  details: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  session: PropTypes.object.isRequired,
  orderCanceled: PropTypes.bool.isRequired,
}

function mapStateToProps({ orderDetails, login, orders }) {
  return {
    details: orderDetails.orderDetails,
    isLoading:
      orderDetails.orderDetailsIsLoading || orders.cancelOrderIsLoading,
    session: login.login,
    orderCanceled: orders.cancelOrderSuccess,
  }
}

export default connect(mapStateToProps)(OrdersDetails)
