import notifyToast from '../../utils/notifyToast'
import { ADD_TO_SAVED, DELETE_SAVED, CLEAR_SAVED_PRODUCTS } from '../constants'

export const AddProductToSaved = (payload) => {
  notifyToast('success', 'Product added to saved products', 2000)
  return { type: ADD_TO_SAVED, payload }
}

export const DeleteProductSaved = (payload) => {
  notifyToast('success', 'Product removed from your saved products', 2000)
  return { type: DELETE_SAVED, payload }
}

export const ClearSavedProducts = () => ({
  type: CLEAR_SAVED_PRODUCTS,
})
