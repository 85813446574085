import analytics from '../../utils/analytics'
import api from '../../utils/api'
import {
  handleActionCatch,
  validateServerResponse,
} from '../../utils/errorUtils'
import notifyToast from '../../utils/notifyToast'
import {
  GET_PRODUCT_DETAILS_STARTED,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_FAILED,
  LIKE_PRODUCT_STARTED,
  LIKE_PRODUCT_SUCCESS,
  LIKE_PRODUCT_FAILURE,
  UNLIKE_PRODUCT_STARTED,
  UNLIKE_PRODUCT_SUCCESS,
  UNLIKE_PRODUCT_FAILURE,
  RATE_PRODUCT_STARTED,
  RATE_PRODUCT_SUCCESS,
  RATE_PRODUCT_FAILURE,
  CLEAR_PRODUCT_DETAILS,
  RESET_PRODUCT_TOASTS,
} from '../constants'

export const GetProductDetailsStarted = (payload) => ({
  type: GET_PRODUCT_DETAILS_STARTED,
  ...payload,
})
export const GetProductDetailsSuccess = (payload) => ({
  type: GET_PRODUCT_DETAILS_SUCCESS,
  payload,
})
export const GetProductDetailsFailed = (payload) => ({
  type: GET_PRODUCT_DETAILS_FAILED,
  payload,
})

export const LikeProductStarted = (payload) => ({
  type: LIKE_PRODUCT_STARTED,
  payload,
})
export const LikeProductSuccess = (payload) => {
  const { propertyUrl, id, title, isGuest } = payload
  if (isGuest) {
    analytics.addToSavedProductsGuest(propertyUrl, id, title)
  }
  notifyToast(
    'success',
    payload.isGuest
      ? 'Product added to saved products'
      : 'Product added to your favorites',
    2000
  )
  return { type: LIKE_PRODUCT_SUCCESS, payload }
}
export const LikeProductFailure = (payload) => ({
  type: LIKE_PRODUCT_FAILURE,
  payload,
})

export const UnLikeProductStarted = (payload) => ({
  type: UNLIKE_PRODUCT_STARTED,
  payload,
})
export const UnLikeProductSuccess = (payload) => {
  notifyToast(
    'success',
    payload.isGuest
      ? 'Product removed from your saved products'
      : 'Product removed from your favorites',
    2000
  )
  return { type: UNLIKE_PRODUCT_SUCCESS, payload }
}
export const UnLikeProductFailure = (payload) => ({
  type: UNLIKE_PRODUCT_FAILURE,
  payload,
})

export const RateProductStarted = () => ({
  type: RATE_PRODUCT_STARTED,
})
export const RateProductSuccess = (payload) => {
  notifyToast('success', 'Rate added successfully', 2000)
  return { type: RATE_PRODUCT_SUCCESS, ...payload }
}
export const RateProductFailure = (payload) => {
  notifyToast('error', payload.rateProductError, 2000)
  return { type: RATE_PRODUCT_FAILURE, payload }
}

export const ClearProductDetails = () => ({ type: CLEAR_PRODUCT_DETAILS })
export const ResetProductToasts = () => ({ type: RESET_PRODUCT_TOASTS })

const GetProductDetails = (payload) => async (dispatch) => {
  dispatch(GetProductDetailsStarted(payload))
  try {
    const result = await api.Get(`/products/${payload.id}`, payload.token)
    validateServerResponse(result)
    dispatch(GetProductDetailsSuccess(result.data.data))
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      GetProductDetailsFailed,
      'Get product details',
      payload
    )
  }
}

const LikeProduct = (payload) => async (dispatch) => {
  dispatch(LikeProductStarted(payload))
  try {
    const result = await api.Post(
      `/products/like/${payload.id}`,
      {},
      payload.token
    )
    validateServerResponse(result)
    dispatch(LikeProductSuccess({ ...result.data.data, ...payload }))
    dispatch(ResetProductToasts())
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      LikeProductFailure,
      'Like Product',
      payload
    )
  }
}

const UnLikeProduct = (payload) => async (dispatch) => {
  dispatch(UnLikeProductStarted(payload))
  try {
    const result = await api.Delete(
      `/products/like/${payload.id}`,
      payload.token
    )
    validateServerResponse(result)
    dispatch(UnLikeProductSuccess({ ...result.data.data, ...payload }))
    dispatch(ResetProductToasts())
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      UnLikeProductFailure,
      'UnLike Product',
      payload
    )
  }
}

const RateProduct = (payload) => async (dispatch) => {
  dispatch(RateProductStarted(payload))
  try {
    const {
      productRating: score,
      productId: externalProductId,
      vendor,
      sku,
    } = payload
    const result = await api.Post('/products/rate', {
      score,
      externalProductId,
      vendor,
      sku,
    })
    validateServerResponse(result)
    dispatch(RateProductSuccess(result.data.data))
    dispatch(ResetProductToasts())
    analytics.rateProduct(score, externalProductId, vendor, sku)
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      RateProductFailure,
      'Rate Product',
      payload
    )
    dispatch(ResetProductToasts())
  }
}

export default {
  GetProductDetails,
  LikeProduct,
  UnLikeProduct,
  RateProduct,
}
