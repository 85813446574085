import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import PropTypes from 'prop-types'
import analytics from '../../../utils/analytics'

function SubMenu({ options }) {
  const onSubMenuLinkClick = (menuItem) => {
    analytics.filterByCategory(menuItem.title, false)
  }

  const renderMainOptions = () =>
    options.map((menuItem) => (
      <NavItem key={menuItem.title}>
        <NavLink
          onClick={() => onSubMenuLinkClick(menuItem)}
          href={menuItem?.url}
        >
          {menuItem.title}
        </NavLink>
      </NavItem>
    ))

  const hasSecondOptions = options.some((option) => option?.items?.length >= 1)

  return (
    <Nav
      vertical
      autoFocus
      className={`submenu__container ${
        hasSecondOptions ? 'with_second_options' : ''
      }`}
    >
      {renderMainOptions()}
    </Nav>
  )
}

SubMenu.propTypes = {
  options: PropTypes.array.isRequired,
}

export default SubMenu
