/* eslint-disable no-control-regex */
export const validatePassword = (password) => {
  if (!password) return false
  const regex = /^(?=.*?[0-9#?¿_"/=()¡;:.,!@$ %^&*-]).{8,60}$/
  return !!password.match(regex)
}

export const validateEmail = (email) => {
  if (!email) return false
  const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
  return !!email.match(regex)
}

export const validateLink = (link) => {
  if (!link) return false
  const regex =
    /^((ftp|http|https):\/\/)?(www\.)?([A-z]+|\/+)+\.([A-z]{2,})(\/)?([\x00-\x7f])+$/
  return !!link.match(regex)
}

export const validateNumber = (number, type = 'integer') => {
  if (!number) return false
  const regexInt = /^[0-9]*$/
  const regexFloat = /^[0-9]+.{1}[0-9]*$/
  if (type === 'float') {
    return !!number.match(regexFloat)
  }
  return !!number.match(regexInt)
}
