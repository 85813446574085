import React from 'react'
import PropTypes from 'prop-types'

function MobileCards({ children, key, action }) {
  return (
    <article>
      <div
        key={key}
        className="mobile-card"
        onClick={() => action()}
        aria-hidden="true"
      >
        {children}
      </div>
    </article>
  )
}

MobileCards.propTypes = {
  key: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
}

export default MobileCards
