import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import PropTypes from 'prop-types'
import useHandleMobile from '../../hooks/useHandleMobile'
import MobileHeader from './MobileHeader'
import Button from '../Buttons/Button'
import Logout from '../../redux/actions/LogoutActions'
import {
  ClearProductsList,
  ClearFilterProducts,
  SetUrlGuestParams,
} from '../../redux/actions/CatalogActions'
import Logo from '../../assets/Spaceplace_Full.svg'
import Profile from '../../assets/icons/profile.svg'
import ShoppingBagIcon from '../../assets/icons/shopping-bag.svg'
import UserNotLogged from '../../assets/icons/user-not-logged.svg'

import defaultCopy from '../../utils/dictionary'
import useGuest from '../../hooks/useGuest'
import { extractSessionInfo } from '../../utils'

function Header(props) {
  const { mobile } = useHandleMobile()
  const { headersNoAuth } = defaultCopy
  const { cartProducts, session, guestCartProduct } = props
  const { jwt, userName } = extractSessionInfo(session)
  const isLoggedIn = !!jwt
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const cartIndicatorDivId = 'cartindicatordivid'
  const { isGuest, propertyId, propertyName, baseRoute } = useGuest()
  const handleCartClicked = () => {
    navigate(`${baseRoute}/cart`)
  }
  const handleLogOut = () => {
    dispatch(ClearFilterProducts())
    dispatch(ClearProductsList())
    dispatch(Logout())
  }

  useEffect(() => {
    if (location.pathname === '/') dispatch(SetUrlGuestParams(''))
  }, [location])

  // eslint-disable-next-line consistent-return
  const handleProductLength = () => {
    if (isGuest && !isLoggedIn) {
      return guestCartProduct.length
    }
    if (!isGuest || isLoggedIn) {
      return cartProducts.length
    }
  }

  const handleRedirect = () => {
    if (!isGuest) {
      if (isLoggedIn) {
        if (process.env?.REACT_APP_ENVIRONMENT === 'production') {
          return `${process.env.REACT_APP_SHOPIFY_HOME_PAGE}?name=${userName}`
        }
        return '/product/list'
      }
      return '/product/list'
    }
    return `/${propertyId}/${propertyName}/product/list`
  }

  const handleShopLink = () => {
    if (process.env?.REACT_APP_ENVIRONMENT === 'production') {
      return (
        <li>
          <a
            href={`${process.env.REACT_APP_SHOPIFY_HOME_PAGE}?name=${userName}`}
          >
            Shop
          </a>
        </li>
      )
    }
    return (
      <li
        key="shop"
        className={location.pathname.includes('product') ? 'active' : ''}
      >
        <Link to="/product/list">Shop</Link>
      </li>
    )
  }

  return (
    <div className="header">
      {!mobile && <p>Make your Airbnb shoppable</p>}
      {!mobile && (
        <nav className="header__menu">
          <Link className="header-logo" to={handleRedirect()}>
            <img src={Logo} alt="logo" />
          </Link>
          {!isLoggedIn && !isGuest && (
            <div className="header__nav">
              <ul>
                {headersNoAuth
                  .filter((item) => item.text !== 'Home')
                  .map((item) => (
                    <li key={item.text}>
                      <a href={item.url}>{item.text}</a>
                    </li>
                  ))}
              </ul>
              <Button
                id="login"
                action={() => navigate(`${baseRoute}/`)}
                text="Log in"
                small
              />
            </div>
          )}
          {!isLoggedIn && isGuest && (
            <div className="header__nav">
              <ul>
                <li key="catalog">
                  <Link to={`/${propertyId}/${propertyName}/product/list`}>
                    Catalog
                  </Link>
                </li>
                <li key="saved">
                  <Link to={`/${propertyId}/${propertyName}/saved`}>
                    Saved Products
                  </Link>
                </li>
              </ul>

              <div className="header__nav--dropdown">
                <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                  <DropdownToggle nav>
                    <img src={UserNotLogged} alt="Profile" />
                  </DropdownToggle>
                  <DropdownMenu container="body">
                    <DropdownItem onClick={() => navigate(`${baseRoute}/`)}>
                      Log In
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <button
                type="button"
                className="shopping-cart-indicator"
                id={cartIndicatorDivId}
              >
                <div
                  onClick={() => handleCartClicked()}
                  role="none"
                  className="shopping-cart-link"
                >
                  <img
                    className="icon"
                    src={ShoppingBagIcon}
                    alt="Shopping cart icon"
                  />
                  {guestCartProduct.length !== 0 && (
                    <span className="badge">{guestCartProduct.length}</span>
                  )}
                </div>
              </button>
            </div>
          )}
          {isLoggedIn && (
            <div className="header__nav">
              {isGuest ? (
                <ul>
                  <li key="catalog">
                    <Link to={`/${propertyId}/${propertyName}/product/list`}>
                      Catalog
                    </Link>
                  </li>
                  <li key="saved">
                    <Link to={`/${propertyId}/${propertyName}/saved`}>
                      Saved Products
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul>
                  {handleShopLink()}
                  <li
                    key="properties"
                    className={
                      location.pathname.includes('property') ? 'active' : ''
                    }
                  >
                    <Link to="/property/list">My Properties</Link>
                  </li>
                  <li key="blog">
                    <a href="https://spaceplaceapp.com/pages/blog.html">Blog</a>
                  </li>
                </ul>
              )}

              <div className="header__nav--dropdown">
                <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                  <DropdownToggle nav>
                    <img src={Profile} alt="profile" />
                    <span className="username"> {userName} </span>
                  </DropdownToggle>
                  <DropdownMenu container="body">
                    {!isGuest && (
                      <DropdownItem
                        onClick={() => {
                          navigate(`${baseRoute}/favorites`)
                        }}
                      >
                        My Favorites
                      </DropdownItem>
                    )}
                    {!isGuest && (
                      <DropdownItem
                        onClick={() => navigate(`${baseRoute}/orders`)}
                      >
                        {!isGuest && 'My '}Orders
                      </DropdownItem>
                    )}
                    {isGuest && (
                      <DropdownItem
                        onClick={() =>
                          navigate(`/${propertyId}/${propertyName}/orders`)
                        }
                      >
                        Orders
                      </DropdownItem>
                    )}
                    <DropdownItem onClick={() => handleLogOut()}>
                      Log Out
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <button
                type="button"
                className="shopping-cart-indicator"
                id={cartIndicatorDivId}
              >
                <div
                  onClick={() => handleCartClicked()}
                  role="none"
                  className="shopping-cart-link"
                >
                  <img
                    className="icon"
                    src={ShoppingBagIcon}
                    alt="Shopping cart icon"
                  />
                  {handleProductLength() !== 0 && (
                    <span className="badge">{handleProductLength()}</span>
                  )}
                </div>
              </button>
            </div>
          )}
        </nav>
      )}
      {mobile && (
        <MobileHeader
          handleCartClicked={() => handleCartClicked()}
          ShoppingBagIcon={ShoppingBagIcon}
          cartProducts={handleProductLength()}
          cartIndicatorDivId={cartIndicatorDivId}
          isOpen={isOpen}
          setIsOpen={() => setIsOpen(!isOpen)}
          navigate={(nav) => navigate(nav)}
          handleLogOut={() => handleLogOut()}
          login={session}
          headersNoAuth={headersNoAuth}
          /* isGuest={isGuest}
          urlGuestParams={urlGuestParams} */
          location={location}
        />
      )}
    </div>
  )
}

const mapStateToProps = ({
  cart: { cartProducts },
  guestCart,
  login,
  /* productList, */
}) => ({
  session: login.login,
  cartProducts,
  /* urlGuestParams: productList.urlGuestParams, */
  guestCartProduct: guestCart.cartProducts,
})

Header.propTypes = {
  cartProducts: PropTypes.arrayOf(PropTypes.shape(PropTypes.any)),
  session: PropTypes.object.isRequired,
  /* urlGuestParams: PropTypes.object, */
  guestCartProduct: PropTypes.arrayOf(PropTypes.shape(PropTypes.any))
    .isRequired,
}

Header.defaultProps = {
  /* urlGuestParams: {}, */
  cartProducts: [],
}

export default connect(mapStateToProps)(Header)
