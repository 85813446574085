/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import BreadCrumbComponent from '../../components/BreadCrumb'
import Button from '../../components/Buttons/Button'
import DrawableMenu from '../../components/DrawableMenu'
import ErrorAlert from '../../components/ErrorAlert'
import Icon from '../../components/Icon'
import Loader from '../../components/Loader'
import NoResults from '../../components/NoResults/NoResults'
import ProductCard from './ProductCard'
import SearchBoxFilter from './SearchBoxFilter'
import SideFilters from './SideFilters'

import {
  ClearProductsList,
  ClearProductsListGuest,
  ClearFilterProducts,
  GetProductsListGuest,
  SetUrlGuestParams,
} from '../../redux/actions/CatalogActions'
import CartActions from '../../redux/actions/CartActions'
import GetMenu from '../../redux/actions/LayoutActions'
import { AddProductToSaved } from '../../redux/actions/SavedListActions'
import { ClearProductDetails } from '../../redux/actions/ProductDetailsAction'

import { extractSessionInfo, setPageTitleAndUserInfo } from '../../utils'
import HostCatalogTestUtils from '../../utils/testUtils/HostCatalogTestUtils'

import useHandleMobile from '../../hooks/useHandleMobile'
import useGuest from '../../hooks/useGuest'
import useProductFilters from '../../hooks/catalog/useProductFilters'
import useSetUrlFilters from '../../hooks/catalog/useSetUrlFilters'
import useFetchCatalog from '../../hooks/catalog/useFetchCatalog'

const { REACT_APP_SHOPIFY_DRAWABLE_MENU: shopifyDrawableMenu } = process.env
function Catalog(props) {
  const {
    session,
    likeProductError,
    unlikeProductError,
    menuItems,
    // productListFirstPageCache,
    hostActiveProductFilters,
  } = props

  const dispatch = useDispatch()
  const { mobile } = useHandleMobile()
  const { isGuest, propertyId } = useGuest()
  const params = useParams()

  const [showSideFilter, setShowSideFilter] = useState(false)

  const { userId, userName, userEmail, jwt } = extractSessionInfo(session)

  useEffect(() => {
    setPageTitleAndUserInfo('Catalog', userId, userName, userEmail)
  }, [])

  // current active product filters
  const productFilters = useProductFilters(hostActiveProductFilters)

  // Filter from url: obtain and update
  useSetUrlFilters(productFilters)

  useEffect(() => {
    dispatch(ClearProductsList())
    dispatch(ClearFilterProducts())
    dispatch(ClearProductsListGuest())
    dispatch(ClearProductDetails())
    dispatch(GetMenu(shopifyDrawableMenu))
    dispatch(SetUrlGuestParams(params))
    if (isGuest) {
      if (jwt) {
        dispatch(AddProductToSaved([]))
      }
      dispatch(GetProductsListGuest({ page: 0, id: propertyId, token: jwt }))
    }
  }, [])

  const {
    data: paginatedProductList,
    error,
    isFetching,
    page: currentPage,
    setPage,
  } = useFetchCatalog(productFilters, jwt)

  const [apiError, setApiError] = useState('')
  useEffect(() => {
    const newestApiError = error?.response?.data?.error
    if (newestApiError && newestApiError !== apiError) {
      // New error from api, send alert
      console.log('error (TODO): send through alert', newestApiError)
      setApiError(newestApiError)
    }
  }, [error])

  useEffect(() => {
    if (!isGuest) dispatch(CartActions.GetShoppingCart({ token: jwt }))
  }, [])

  useEffect(() => {
    if (!mobile) setShowSideFilter(false)
  }, [mobile])

  const showProductList = () =>
    paginatedProductList?.map((page) =>
      page?.data?.body?.products.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))
    )

  const noResultsMessage = () => {
    if (
      Array.isArray(paginatedProductList) &&
      paginatedProductList[0]?.data?.body?.products?.length === 0 &&
      !isFetching
    )
      return (
        <div className="no-response__container">
          <NoResults
            mainText="Sorry, we didn't find any results"
            secondaryText="No results"
          />
        </div>
      )
    return ''
  }

  const showFilters = (source) => (
    <SideFilters
      source={source}
      setShowSideFilter={() => setShowSideFilter(false)}
    />
  )

  return (
    <section className="catalog cont">
      {likeProductError && <ErrorAlert error={likeProductError} />}
      {unlikeProductError && <ErrorAlert error={unlikeProductError} />}

      <div className="catalog-menu">
        <SearchBoxFilter />
        {menuItems?.length >= 1 && userId ? (
          <DrawableMenu options={menuItems} />
        ) : null}
      </div>

      <div className="catalog-view">
        <button
          type="button"
          className="catalog__side-filter-btn"
          onClick={() => setShowSideFilter(!showSideFilter)}
        >
          <Icon
            item="filter-mobile"
            width="24px"
            height="24px"
            stroke="#030303"
            className="catalog__icon-filter"
          />
          FILTERS
        </button>

        {(!mobile || showSideFilter) && (
          <aside>
            {!mobile && (
              <Button id="filter_btn" tertiary text="" noButtonBehavior>
                <Icon
                  item="filter-desktop"
                  width="24px"
                  height="24px"
                  stroke="#030303"
                />
                <p>FILTERS</p>
              </Button>
            )}
            {showFilters(showSideFilter)}
          </aside>
        )}
        <div>
          <BreadCrumbComponent />
          <div className="list-show-more-container">
            <div
              className="catalog-list"
              sp-data-id={HostCatalogTestUtils.DataIds.PRODUCT_LIST_DIV}
            >
              {showProductList()}
              {noResultsMessage()}
            </div>

            {isFetching ? (
              <div className="show-more__container">
                <Loader show local />
              </div>
            ) : null}
            {!isFetching && productFilters.filters.hasNextPage ? (
              <div className="show-more__container">
                <button
                  type="button"
                  onClick={() => setPage(currentPage + 1)}
                  className="show-more no-shape"
                >
                  Show more products...
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

Catalog.propTypes = {
  // productListFirstPageCache: PropTypes.array.isRequired,
  session: PropTypes.shape({
    jwtToken: PropTypes.string,
    user: PropTypes.object,
  }).isRequired,
  likeProductError: PropTypes.string.isRequired,
  unlikeProductError: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  hostActiveProductFilters: PropTypes.object.isRequired,
}

function mapStateToProps({
  productList,
  login: { login },
  productDetails: { likeProductError, unlikeProductError },
  menuItems: { getMenuItems: menuItems },
  breadCrumb: { breadCrumb },
  hostActiveProductFilters,
}) {
  return {
    filteredProducts: productList.filterProduct,
    isError: productList.productListFailed,
    // productListFirstPageCache: productList.productListFirstPageCache,

    session: login,
    likeProductError,
    unlikeProductError,
    menuItems,
    breadCrumb,
    hostActiveProductFilters,
  }
}

export default connect(mapStateToProps)(Catalog)
