import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Icon from '../../Icon'

function InputComponent(props) {
  const {
    className,
    style,
    id,
    onChange,
    onBlur,
    onFocus,
    onKeydown,
    invalid,
    labelText,
    placeHolder,
    invalidText,
    type,
    name,
    outlined,
    value,
    title,
    showPasswordControl,
    isMandatory,
    helperText,
    autoComplete,
    disabled,
    spDataId,
  } = props
  const [inputType, setInputType] = useState('text')
  useEffect(() => {
    setInputType(type)
  }, [type])
  const showPassword = () => {
    const pwdInput = document.getElementById(id)
    return setInputType(pwdInput.type === 'password' ? 'text' : 'password')
  }

  const showLabel = (isOutlined) => {
    if (!isOutlined && !document.getElementById(id)?.value) {
      return null
    }
    return (
      <label invalid={invalid ? 'true' : undefined} htmlFor={id}>
        {labelText} {isMandatory && <p className="mandatory">*</p>}
      </label>
    )
  }

  return (
    <div
      className={`input-component ${helperText.length >= 1 ? 'helper' : ''} ${
        type === 'password' ? 'pwd' : ''
      }`}
      outlined={outlined ? 'true' : undefined}
      style={style && style !== {} ? style : {}}
    >
      {labelText && showLabel(outlined)}
      <input
        name={name}
        className={className}
        id={id}
        title={title}
        onChange={onChange}
        onKeyDown={onKeydown}
        onBlur={onBlur}
        onFocus={onFocus}
        invalid={invalid ? 'true' : undefined}
        placeholder={outlined ? placeHolder : labelText}
        outlined={outlined ? 'true' : undefined}
        autoComplete={autoComplete ? 'on' : 'off'}
        disabled={disabled}
        type={
          ['text', 'password', 'email', 'number'].includes(inputType)
            ? inputType
            : 'text'
        }
        value={value}
        sp-data-id={spDataId}
      />
      {showPasswordControl && showLabel() && (
        <button type="button" onClick={() => showPassword()}>
          <Icon
            item={inputType === 'password' ? 'show' : 'hide'}
            width="24px"
            height="24px"
          />
        </button>
      )}
      {invalid && <span>{invalidText}</span>}
      {helperText.length >= 1 && <span className="helper">{helperText}</span>}
    </div>
  )
}

InputComponent.propTypes = {
  id: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeydown: PropTypes.func,
  invalid: PropTypes.bool,
  autoComplete: PropTypes.bool,
  labelText: PropTypes.string,
  placeHolder: PropTypes.string,
  invalidText: PropTypes.string,
  helperText: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  outlined: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  showPasswordControl: PropTypes.bool,
  isMandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  spDataId: PropTypes.string,
}

InputComponent.defaultProps = {
  className: '',
  style: {},
  onChange: () => {},
  onKeydown: () => {},
  onBlur: () => {},
  onFocus: () => {},
  invalid: false,
  labelText: '',
  placeHolder: '',
  invalidText: 'This field is required.',
  helperText: '',
  type: 'text',
  name: '',
  outlined: false,
  value: undefined,
  title: '',
  showPasswordControl: false,
  isMandatory: false,
  autoComplete: false,
  disabled: false,
  spDataId: '',
}

export default InputComponent
