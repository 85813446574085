import React, { useState } from 'react'
import PropTypes from 'prop-types'
import infoIcon from '../../../assets/icons/info-circle.svg'
import Tooltip from '../../../components/Tooltip'

function ProductSpecifications(props) {
  const { productSpecifications } = props

  const [showToolTip, setShoTooltip] = useState(false)

  const handleIconHover = () => setShoTooltip(!showToolTip)

  return (
    <div className="product-specifications-summary-container">
      {productSpecifications?.map(({ name, value }) => (
        <div className="specifications-summary" key={name}>
          <span className="labe-container">
            {name === 'Weight' ? (
              <span className="label">
                {name}{' '}
                <img
                  id="infoIcon"
                  onMouseEnter={handleIconHover}
                  onMouseLeave={handleIconHover}
                  src={infoIcon}
                  alt="info circle"
                />
                :
              </span>
            ) : (
              <span className="label">{name}:</span>
            )}
          </span>
          <span className="value">{value}</span>
        </div>
      ))}
      <Tooltip
        target="infoIcon"
        text="Choose a variant to see the Weight"
        open={showToolTip}
        right
      />
    </div>
  )
}

ProductSpecifications.propTypes = {
  productSpecifications: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
}

export default ProductSpecifications
