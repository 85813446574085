import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import SelectComponent from '../../components/SelectComponent'
import Button from '../../components/Buttons/Button'
import RadioBtn from '../../components/RadioBtn'
import LoaderComponent from '../../components/Loader'
import DifferentAddress from './DifferentAddress'
import SameAddress from './SameAddress'
import Summary from './Summary'

import defaultCopy from '../../utils/dictionary'
import { extractSessionInfo } from '../../utils'
import GetPropertyList from '../../redux/actions/PropertyListActions'
import OrderActions from '../../redux/actions/OrderActions'
import ErrorAlert from '../../components/ErrorAlert'
import useGuest from '../../hooks/useGuest'

function Checkout({
  propertyList,
  isLoading,
  login,
  startCheckoutIsLoading,
  startCheckoutError,
  checkoutOrder,
}) {
  const {
    checkout: { helperTexts },
  } = defaultCopy
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { baseRoute } = useGuest()
  const [sameAddress, setSameAddress] = useState(false)
  const [diffAddress, setDiffAddress] = useState(false)
  const [startedCheckout, setStartedCheckout] = useState(false)
  const [propertySelected, setPropertySelected] = useState(null)
  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [dataToSend, setDataToSend] = useState({})
  const [list, setList] = useState({})

  const { userEmail } = extractSessionInfo(login)

  useEffect(() => {
    if (!Object.values(list).length >= 1) {
      dispatch(GetPropertyList({ token: login.jwtToken }))
    }
  }, [])

  useEffect(() => {
    if (Object.values(propertyList).length >= 1) {
      setList(propertyList)
    }
  }, [propertyList])

  useEffect(() => {
    if (propertySelected === '*') {
      navigate('/property/new')
    }
  }, [propertySelected])

  useEffect(() => {
    if (checkoutOrder && checkoutOrder.webCheckoutUrl && startedCheckout)
      window.location.href = checkoutOrder.webCheckoutUrl
  }, [checkoutOrder])

  const handleOptions = () => {
    const options = []
    if (Object.values(list).length >= 1) {
      const { elements } = list
      elements.map((item, idx) =>
        options.push({
          keyx: idx,
          text: item.nickName,
        })
      )
    }
    options.push({
      keyx: '*',
      text: 'Add property',
    })
    return options
  }

  const handlePropertySelected = ({ value }) => setPropertySelected(value)

  const handleCancel = () => navigate('/product/list')

  const handlePlaceOrder = () => {
    setStartedCheckout(true)
    dispatch(
      OrderActions.StartUserCheckout({
        shippingAddress: {
          ...dataToSend,
        },
        token: login.jwtToken,
        propertyUrl: `${baseRoute}/product/list`,
        userEmail,
      })
    )
  }

  const handleChangeRadioBtns = ({ name }) => {
    if (name === 'same_address') {
      setSameAddress(true)
      setDiffAddress(false)
    } else {
      setSameAddress(false)
      setDiffAddress(true)
    }
  }

  const shouldShowContent = () => {
    if (
      Object.values(list).length >= 1 &&
      propertySelected !== '*' &&
      propertySelected
    ) {
      const { zipCode, address, city, state } = list.elements[propertySelected]
      return (
        <>
          {/* <Wizard
            steps={['Shipping Address', 'Payment']}
            stepComponents={[
              <p>First component</p>,
              <p>Second component</p>
            ]}
          /> */}
          {diffAddress && <DifferentAddress setFields={setDataToSend} />}
          {sameAddress && (
            <SameAddress
              state={state}
              city={city}
              address={address}
              zipCode={zipCode}
              setFields={setDataToSend}
            />
          )}
        </>
      )
    }
    return null
  }

  const shouldEnableButton = () => {
    const arr = [
      dataToSend?.state,
      dataToSend?.city,
      dataToSend?.address,
      dataToSend?.zipCode,
    ]
    if (
      Object.values(dataToSend).length > 1 &&
      !arr.includes('') &&
      propertySelected &&
      propertySelected !== '*'
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    setButtonEnabled(shouldEnableButton())
  }, [dataToSend])

  return (
    Object.values(list).length >= 1 && (
      <div className="checkout">
        <LoaderComponent show={isLoading || startCheckoutIsLoading} />
        {startCheckoutError && <ErrorAlert error={startCheckoutError} />}
        <h1 className="checkout__title container">Checkout</h1>
        <div className="checkout container">
          <div className="checkout__info">
            <div className="shipping-address">
              <h2>Shipping Addres</h2>
              <div>
                <span>
                  <p>*</p>
                  <p>{helperTexts[0]}</p>
                </span>
                <SelectComponent
                  id="property"
                  name="property"
                  onChange={({ target }) => handlePropertySelected(target)}
                  options={handleOptions()}
                />
              </div>
              <div>
                <span>
                  <p>*</p>
                  <p>{helperTexts[1]}</p>
                </span>
                <RadioBtn
                  id="same_address"
                  name="same_address"
                  checked={sameAddress}
                  onChange={({ target }) => handleChangeRadioBtns(target)}
                  text="Same Address"
                />
                <RadioBtn
                  id="diff_address"
                  name="diff_address"
                  checked={diffAddress}
                  onChange={({ target }) => handleChangeRadioBtns(target)}
                  text="Different Address"
                />
              </div>
              {shouldShowContent()}
            </div>
            <div className="summary__info">
              <Summary />
            </div>
          </div>
          <div className="checkout__buttons">
            <Button secondary text="Cancel" action={() => handleCancel()} />
            <Button
              disabled={!buttonEnabled}
              text="Place Order"
              action={() => handlePlaceOrder()}
            />
          </div>
        </div>
      </div>
    )
  )
}

Checkout.propTypes = {
  login: PropTypes.object.isRequired,
  propertyList: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  startCheckoutIsLoading: PropTypes.bool.isRequired,
  startCheckoutError: PropTypes.string.isRequired,
  checkoutOrder: PropTypes.shape(PropTypes.any).isRequired,
}

function mapStateToProps({ propertyList, login, order }) {
  return {
    login: login.login,
    propertyList: propertyList.propertyList,
    isLoading: propertyList.propertyListIsLoading,
    startCheckoutIsLoading: order.startCheckoutIsLoading,
    startCheckoutError: order.startCheckoutError,
    checkoutOrder: order.checkoutOrder,
  }
}

export default connect(mapStateToProps)(Checkout)
