import { addQueryStringParamToUrl } from '../utils'
import Recreatable from '../utils/BaseClasses/Recreatable'

export const defaultHostProductFilters = Object.freeze({
  limit: 12,
  title: '',
  productType: '',
  vendors: [],
  priceMin: 0,
  priceMax: 0,
  tags: [],
  rooms: [],
  categories: [],
  search: '',
  ids: [],
  skuVendors: [],
  hasNextPage: false,
})

export default class HostProductFilters extends Recreatable {
  limit

  title

  productType

  vendors

  priceMin

  priceMax

  tags

  rooms

  categories

  search

  ids

  skuVendors

  hasNextPage

  get priceMinStr() {
    return String(this.priceMin) || ''
  }

  get priceMaxStr() {
    return String(this.priceMax) || ''
  }

  get vendorsStr() {
    return this.vendors.join(',') || ''
  }

  get tagsStr() {
    return this.tags.join(',') || ''
  }

  get filters() {
    return {
      limit: this.limit,
      title: this.title,
      productType: this.productType,
      vendors: this.vendors,
      priceMin: this.priceMin,
      priceMax: this.priceMax,
      tags: this.tags,
      rooms: this.rooms,
      categories: this.categories,
      search: this.search,
      ids: this.ids,
      skuVendors: this.skuVendors,
      hasNextPage: this.hasNextPage,
    }
  }

  constructor(
    limit,
    title,
    productType,
    vendors,
    priceMin,
    priceMax,
    tags,
    rooms,
    categories,
    search,
    ids,
    skuVendors,
    hasNextPage
  ) {
    super()
    this.limit = limit || defaultHostProductFilters.limit
    this.title = title || defaultHostProductFilters.title
    this.productType = productType || defaultHostProductFilters.productType
    this.vendors = vendors || defaultHostProductFilters.vendors
    this.priceMin = priceMin || defaultHostProductFilters.priceMin
    this.priceMax = priceMax || defaultHostProductFilters.priceMax
    this.tags = tags || defaultHostProductFilters.tags
    this.rooms = rooms || defaultHostProductFilters.rooms
    this.categories = categories || defaultHostProductFilters.categories
    this.search = search || defaultHostProductFilters.search
    this.ids = ids || defaultHostProductFilters.ids
    this.skuVendors = skuVendors || defaultHostProductFilters.skuVendors
    this.hasNextPage =
      typeof hasNextPage === 'boolean'
        ? hasNextPage
        : defaultHostProductFilters.hasNextPage
  }

  clearMenuFilters() {
    this.tags = []
    this.clearSideFilters()
  }

  clearSearchBoxFilter() {
    this.search = ''
    this.clearSideFilters()
  }

  clearPriceRange() {
    this.priceMin = 0
    this.priceMax = 0
  }

  clearSideFilters() {
    this.vendors = []
    this.categories = []
    this.hasNextPage = false
    this.clearPriceRange()
  }

  updateHasNextPage(hasNextPage) {
    this.hasNextPage = hasNextPage
  }

  updateFiltersFromUrl(filtersFromUrl = {}) {
    this.clearSearchBoxFilter()
    this.clearSideFilters()
    const { tags } = filtersFromUrl
    if (tags) this.tags = tags
    this.updateSearchBoxFilter(filtersFromUrl)
    this.updateSideFilters(filtersFromUrl)
  }

  updateSearchBoxFilter(filters = {}) {
    const { search } = filters
    if (search) this.search = search
  }

  updateSideFilters(filters = {}) {
    const { vendors, priceRange, categories } = filters
    if (typeof priceRange?.priceMax === 'number')
      this.priceMax = priceRange.priceMax
    if (typeof priceRange?.priceMin === 'number')
      this.priceMin = priceRange.priceMin
    if (vendors) this.vendors = vendors
    if (categories) this.categories = categories
  }

  addVendorsFilterToUrl(url) {
    if (this.vendors.length <= 0) return `${url}&vendor=`
    return `${url}&vendor=${this.vendorsStr}`
  }

  addTagsFilterToUrl(url) {
    if (this.tags.length <= 0) return `${url}&tags=`
    return `${url}&tags=${this.tagsStr}`
  }

  /**
   * translates current class filters into a url so API can filter products accordingly. Some filters require special logic for which extra methods were created e.g. Vendor.
   * @returns url string with the filters as parameters
   */
  filtersToApiStr() {
    let route = '/products/list?'
    Object.keys(defaultHostProductFilters).forEach((key) => {
      if (key === 'vendors') {
        route = this.addVendorsFilterToUrl(route)
        return
      }
      if (key === 'tags') {
        route = this.addTagsFilterToUrl(route)
        return
      }
      // Skip "hasNextPage" - it's not a real api filter
      if (key === 'hasNextPage') return
      route = addQueryStringParamToUrl(route, key, this[key])
    })

    return route
  }

  output() {
    return this.filters
  }

  /**
   * Creates a new instance of the class from an object containing the filter values
   * @param {filters} filterProps - Filters to create the object from
   * @returns new instance of ProductFilters with specified filters
   */
  static fromFilters(filterProps) {
    return new HostProductFilters(
      filterProps.limit,
      filterProps.title,
      filterProps.productType,
      filterProps.vendors,
      filterProps.priceMin,
      filterProps.priceMax,
      filterProps.tags,
      filterProps.rooms,
      filterProps.categories,
      filterProps.search,
      filterProps.ids,
      filterProps.skuVendors,
      filterProps.hasNextPage
    )
  }

  static reCreate(filterProps) {
    return HostProductFilters.fromFilters(filterProps)
  }
}
