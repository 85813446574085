import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

function SameAddress({ city, state, zipCode, address, setFields }) {
  useEffect(() => {
    setFields({
      address,
      additionalInfo: '',
      state,
      city,
      zipCode,
    })
  }, [])
  return (
    <section className="same-address">
      <span>
        <h4>Shipping Address:</h4>
        <p>{address}</p>
      </span>
      <span>
        <h4>City:</h4>
        <p>{city}</p>
      </span>
      <span>
        <h4>State:</h4>
        <p>{state}</p>
      </span>
      <span>
        <h4>Zip Code:</h4>
        <p>{zipCode}</p>
      </span>
    </section>
  )
}

SameAddress.propTypes = {
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
  setFields: PropTypes.func.isRequired,
}

export default SameAddress
