import { Logger } from 'aws-amplify'

const { REACT_APP_LOCALHOST_LOGGING, REACT_APP_ENVIRONMENT } = process.env

const shouldLogMessages =
  (REACT_APP_LOCALHOST_LOGGING === 'true' &&
    REACT_APP_ENVIRONMENT === 'localhost') ||
  REACT_APP_ENVIRONMENT !== 'localhost'

export const loggerName = `sp-logger-${REACT_APP_ENVIRONMENT}`
export const streamName = `${new Date().toDateString()}`

const logger = new Logger(loggerName)

// (TODO): Send logs to cloudwatch - amplify or through sp api
// Amplify.register(logger)
// logger.addPluggable(new AWSCloudWatchProvider())

export const LogLevels = Object.freeze({
  DEBUG: 'debug',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
})

/**
 * Util to log a message/trace
 * @param {string} label - Label to append to the message
 * @param {string|object} message - Message to log
 */
export default (label, message, logLevel = LogLevels.INFO) => {
  if (!shouldLogMessages) return {}
  const now = new Date().toISOString()
  let msgToLog = message
  if (typeof message !== 'string') {
    if (message instanceof Error) msgToLog = message.error
    else if (
      typeof message === 'object' &&
      message &&
      Object.keys(message).length > 0
    ) {
      msgToLog = ''
      Object.keys(message).forEach((key) => {
        msgToLog += `${key}: ${JSON.stringify(message[key])}, `
      })
    } else msgToLog = JSON.stringify(msgToLog)
  }

  msgToLog = `${now} | ${logLevel} | Environment: ${REACT_APP_ENVIRONMENT} | ${label}: ${msgToLog}`

  if (logLevel === LogLevels.DEBUG) logger.debug(msgToLog)
  else if (logLevel === LogLevels.INFO) logger.info(msgToLog)
  else if (logLevel === LogLevels.WARN) logger.warn(msgToLog)
  else if (logLevel === LogLevels.ERROR) logger.error(msgToLog)

  console.log(msgToLog)

  return { now, logLevel, label, message: msgToLog }
}
