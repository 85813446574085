/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'

function SelectComponent(props) {
  const {
    className,
    id,
    onChange,
    onFocus,
    invalid,
    labelText,
    placeHolder,
    invalidText,
    name,
    value,
    title,
    options,
    isMandatory,
  } = props

  const showOptions = () => {
    if (Object.values(options).length >= 1) {
      return options.map(({ text, keyx }, idx) => {
        const key = text + idx
        return (
          <option key={key} value={keyx}>
            {text}
          </option>
        )
      })
    }
    return null
  }

  return (
    <div className={`select-component  ${!labelText ? 'no-labeled' : ''}`}>
      {labelText && (
        <label invalid={invalid ? 'true' : undefined} htmlFor={id}>
          {labelText} {isMandatory && <p className="mandatory">*</p>}
        </label>
      )}
      <Icon className="select-snippet" item="snippet" />
      <select
        name={name}
        className={className}
        id={id}
        title={title}
        onChange={onChange}
        onFocus={onFocus}
        invalid={invalid ? 'true' : undefined}
        placeholder={placeHolder}
        value={value}
      >
        <option disabled selected hidden key="placeholder" value="*">
          {placeHolder}
        </option>
        {showOptions()}
      </select>
      {invalid && <span>{invalidText}</span>}
    </div>
  )
}

SelectComponent.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  labelText: PropTypes.string,
  placeHolder: PropTypes.string,
  invalidText: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
  isMandatory: PropTypes.bool,
  onFocus: PropTypes.func,
}

SelectComponent.defaultProps = {
  className: '',
  onChange: () => {},
  onFocus: () => {},
  invalid: false,
  labelText: '',
  placeHolder: '',
  invalidText: 'This field is required.',
  name: '',
  value: undefined,
  title: '',
  isMandatory: false,
}

export default SelectComponent
