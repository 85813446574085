/* eslint default-param-last: off */
import {
  START_RESET_PASSWORD_STARTED,
  START_RESET_PASSWORD_SUCCESS,
  START_RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD_STARTED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  RESET_PASSWORD_CLEAR,
} from '../constants'

const initialState = {
  step: 1,
  startResetPasswordIsLoading: false,
  startResetPasswordError: '',
  changePasswordIsLoading: false,
  changePasswordError: '',
  changePasswordSuccess: false,
}

const resetPasswordReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case START_RESET_PASSWORD_STARTED: {
      return {
        ...state,
        startResetPasswordIsLoading: true,
        startResetPasswordError: '',
      }
    }
    case START_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        startResetPasswordIsLoading: false,
        step: 2,
      }
    }
    case START_RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        startResetPasswordIsLoading: false,
        startResetPasswordError: payload.errorMessage,
      }
    }

    case CHANGE_PASSWORD_STARTED: {
      return {
        ...state,
        changePasswordIsLoading: true,
        changePasswordError: '',
      }
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePasswordIsLoading: false,
        changePasswordSuccess: true,
      }
    }
    case CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        changePasswordIsLoading: false,
        changePasswordError: payload.errorMessage,
      }
    }

    case RESET_PASSWORD_CLEAR: {
      return initialState
    }

    default:
      return state
  }
}

export default resetPasswordReducer
