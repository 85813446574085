/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { IntercomProvider } from 'react-use-intercom'

import Layout from './containers/Layout'
import SignUp from './pages/SignUp'
import Login from './pages/Login'
import Propertylist from './pages/PropertyList'
import AddProperty from './pages/AddProperty'
import PropertyDetails from './pages/PropertyDetails'
import Catalog from './pages/Catalog'
import CatalogGuest from './pages/Catalog/GuestCatalog'
import ProductDetails from './pages/ProductDetails'
import Cart from './pages/Cart'
import Orders from './pages/Orders'
import EditProperty from './pages/EditProperty'
import Checkout from './pages/Checkout'
import UserIsLogged from './UserIsLogged'
import FavoritesList from './pages/FavoritesList'
import OrdersDetails from './pages/OrdersDetails'
import ResetPassword from './pages/ResetPassword'
import SavedList from './pages/SavedList'

import defaultCopy from './utils/dictionary'
import LogoutPage from './pages/Logout'

function RoutesList() {
  return (
    <BrowserRouter>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={6}
        containerClassName="toast-component"
        toastOptions={defaultCopy.toastOptions}
      />
      <IntercomProvider autoBoot appId={process.env.REACT_APP_INTERCOM_ID}>
        <Routes>
          {/* HOME */}
          <Route
            path="/"
            element={
              <Layout>
                <Login />
              </Layout>
            }
          />

          {/* USER-MANAGEMENT */}
          <Route
            path="/sign-up"
            element={
              <Layout>
                <SignUp />
              </Layout>
            }
          />
          <Route
            path="/reset-password"
            element={
              <Layout>
                <ResetPassword />
              </Layout>
            }
          />
          <Route
            path="/logout"
            element={
              <Layout>
                <LogoutPage />
              </Layout>
            }
          />

          {/* HOME GUEST */}
          <Route
            path="/:propertyID/:propertyName"
            element={
              <Layout>
                <Login />
              </Layout>
            }
          />
          {/* USER-MANAGEMENT GUEST */}
          <Route
            path="/:propertyID/:propertyName/sign-up"
            element={
              <Layout>
                <SignUp />
              </Layout>
            }
          />
          <Route
            path="/:propertyID/:propertyName/reset-password"
            element={
              <Layout>
                <ResetPassword />
              </Layout>
            }
          />

          {/* PROPERTIES */}
          <Route
            path="/property/list"
            element={
              <Layout>
                <UserIsLogged>
                  <Propertylist />
                </UserIsLogged>
              </Layout>
            }
          />
          <Route
            path="/property/new"
            element={
              <Layout>
                <UserIsLogged>
                  <AddProperty />
                </UserIsLogged>
              </Layout>
            }
          />
          <Route
            path="/property/:id/details"
            element={
              <Layout>
                <UserIsLogged>
                  <PropertyDetails />
                </UserIsLogged>
              </Layout>
            }
          />
          <Route
            path="/property/:id/edit"
            element={
              <Layout>
                <UserIsLogged>
                  <EditProperty />
                </UserIsLogged>
              </Layout>
            }
          />

          {/* PRODUCTS */}
          <Route
            path="/product/list"
            element={
              <Layout>
                <UserIsLogged>
                  <Catalog />
                </UserIsLogged>
              </Layout>
            }
          />
          <Route
            path="/:propertyID/:propertyName/product/list"
            element={
              <Layout>
                <CatalogGuest />
              </Layout>
            }
          />
          <Route
            path="/product/:id/details"
            element={
              <Layout>
                <UserIsLogged>
                  <ProductDetails />
                </UserIsLogged>
              </Layout>
            }
          />
          <Route
            path="/:propertyID/:propertyName/product/:id/details"
            element={
              <Layout>
                <ProductDetails />
              </Layout>
            }
          />

          {/* CART */}
          <Route
            path="/cart"
            element={
              <Layout>
                <UserIsLogged>
                  <Cart />
                </UserIsLogged>
              </Layout>
            }
          />
          <Route
            path="/:propertyID/:propertyName/cart"
            element={
              <Layout>
                <Cart />
              </Layout>
            }
          />
          <Route
            path="/checkout"
            element={
              <Layout>
                <UserIsLogged>
                  <Checkout />
                </UserIsLogged>
              </Layout>
            }
          />

          {/* ORDERS */}
          <Route
            path="/orders"
            element={
              <Layout>
                <UserIsLogged>
                  <Orders />
                </UserIsLogged>
              </Layout>
            }
          />
          <Route
            path="/:propertyID/:propertyName/orders"
            element={
              <Layout>
                <UserIsLogged>
                  <Orders />
                </UserIsLogged>
              </Layout>
            }
          />
          <Route
            path="/orders/:id/details"
            element={
              <Layout>
                <UserIsLogged>
                  <OrdersDetails />
                </UserIsLogged>
              </Layout>
            }
          />

          {/* Favorites */}
          <Route
            path="/favorites"
            element={
              <Layout>
                <UserIsLogged>
                  <FavoritesList />
                </UserIsLogged>
              </Layout>
            }
          />

          {/* Saved */}
          <Route
            path="/:propertyID/:propertyName/saved"
            element={
              <Layout>
                <SavedList />
              </Layout>
            }
          />

          {/* NOT FOUND -- REDIRECTED TO LOGIN/CATALOG */}
          <Route
            path="*"
            element={
              <Layout>
                <UserIsLogged>
                  <Login />
                </UserIsLogged>
              </Layout>
            }
          />
        </Routes>
        {/* </Layout> */}
      </IntercomProvider>
    </BrowserRouter>
  )
}

export default RoutesList
