// ----------User Sign Up-------------- //
export const SIGN_UP_STARTED = 'SIGN_UP_STARTED'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAILED = 'SIGN_UP_FAILED'
export const SIGN_UP_CLEAR = 'SIGN_UP_CLEAR'
export const SIGN_UP_RESEND_CODE_STARTED = 'SIGN_UP_RESEND_CODE_STARTED'
export const SIGN_UP_RESEND_CODE_SUCCESS = 'SIGN_UP_RESEND_CODE_SUCCESS'
export const SIGN_UP_RESEND_CODE_FAILURE = 'SIGN_UP_RESEND_CODE_FAILURE'
export const SIGN_UP_CONFIRMATION_STARTED = 'SIGN_UP_CONFIRMATION_STARTED'
export const SIGN_UP_CONFIRMATION_SUCCESS = 'SIGN_UP_CONFIRMATION_SUCCESS'
export const SIGN_UP_CONFIRMATION_FAILURE = 'SIGN_UP_CONFIRMATION_FAILURE'

// ----------User Login-------------- //
export const LOGIN_STARTED = 'LOGIN_STARTED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGOUT = 'LOGOUT'
export const RESET_LOGIN_TOASTS = 'RESET_LOGIN_TOASTS'

// ---------- Get Menu Items -------------- //
export const GET_MENU_STARTED = 'GET_MENU_STARTED'
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS'
export const GET_MENU_FAILED = 'GET_MENU_FAILED'

// ---------- BreadCrumb -------------- //
export const FILL_BREADCRUMB = 'FILL_BREADCRUMB'
export const CLEAR_BREADCRUMB = 'CLEAR_BREADCRUMB'

// ---------- Reset password -------------- //
export const RESET_PASSWORD_CLEAR = 'RESET_PASSWORD_CLEAR'

export const START_RESET_PASSWORD_STARTED = 'START_RESET_PASSWORD_STARTED'
export const START_RESET_PASSWORD_SUCCESS = 'START_RESET_PASSWORD_SUCCESS'
export const START_RESET_PASSWORD_FAILURE = 'START_RESET_PASSWORD_FAILURE'

export const CHANGE_PASSWORD_STARTED = 'CHANGE_PASSWORD_STARTED'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

// ----------ADD PROPERTY-------------- //
export const ADD_PROPERTY_STARTED = 'ADD_PROPERTY_STARTED'
export const ADD_PROPERTY_SUCCESS = 'ADD_PROPERTY_SUCCESS'
export const ADD_PROPERTY_FAILED = 'ADD_PROPERTY_FAILED'
export const REMOVE_ADD_PROPERTY = 'REMOVE_ADD_PROPERTY'
export const RESET_ADD_PROPERTY_TOASTS = 'RESET_ADD_PROPERTY_TOASTS'

// ----------EDIT PROPERTY-------------- //
export const EDIT_PROPERTY_STARTED = 'EDIT_PROPERTY_STARTED'
export const EDIT_PROPERTY_SUCCESS = 'EDIT_PROPERTY_SUCCESS'
export const EDIT_PROPERTY_FAILED = 'EDIT_PROPERTY_FAILED'
export const CLEAR_EDIT_PROPERTY = 'CLEAR_EDIT_PROPERTY'

// ---------Get Property Details------- //
export const GET_PROPERTY_DETAILS_STARTED = 'GET_PROPERTY_DETAILS_STARTED'
export const GET_PROPERTY_DETAILS_SUCCESS = 'GET_PROPERTY_DETAILS_SUCCESS'
export const GET_PROPERTY_DETAILS_FAILED = 'GET_PROPERTY_DETAILS_FAILED'
export const CLEAR_PROPERTY_DETAILS = 'CLEAR_PROPERTY_DETAILS'
export const GET_PROPERTY_PURCHASE_HISTORY_STARTED =
  'GET_PROPERTY_PURCHASE_HISTORY_STARTED'
export const GET_PROPERTY_PURCHASE_HISTORY_SUCCESS =
  'GET_PROPERTY_PURCHASE_HISTORY_SUCCESS'
export const GET_PROPERTY_PURCHASE_HISTORY_FAILED =
  'GET_PROPERTY_PURCHASE_HISTORY_FAILED'
export const CLEAR_PROPERTY_PURCHASE_HISTORY = 'CLEAR_PROPERTY_PURCHASE_HISTORY'
export const RESET_PROPERTY_DETAILS_TOASTS = 'RESET_PROPERTY_DETAILS_TOASTS'
export const SET_PURCHASE_PAGINATION = 'SET_PURCHASE_PAGINATION'
export const CLEAR_PURCHASE_PAGINATION = 'CLEAR_PURCHASE_PAGINATION'

// ---------Get Property List------- //
export const GET_PROPERTY_LIST_STARTED = 'GET_PROPERTY_LIST_STARTED'
export const GET_PROPERTY_LIST_SUCCESS = 'GET_PROPERTY_LIST_SUCCESS'
export const GET_PROPERTY_LIST_FAILED = 'GET_PROPERTY_LIST_FAILED'
export const GET_COMMISSION_INFO_STARTED = 'GET_COMMISSION_INFO_STARTED'
export const GET_COMMISSION_INFO_SUCCESS = 'GET_COMMISSION_INFO_SUCCESS'
export const GET_COMMISSION_INFO_FAILED = 'GET_COMMISSION_INFO_FAILED'
export const WITHDRAW_COMMISSION_STARTED = 'WITHDRAW_COMMISSION_STARTED'
export const WITHDRAW_COMMISSION_SUCCESS = 'WITHDRAW_COMMISSION_SUCCESS'
export const WITHDRAW_COMMISSION_FAILED = 'WITHDRAW_COMMISSION_FAILED'
export const RESET_PROPERTY_LIST_TOASTS = 'RESET_PROPERTY_LIST_TOASTS'

// -------- Delete Property --------- //
export const DELETE_PROPERTY_STARTED = 'DELETE_PROPERTY_STARTED'
export const DELETE_PROPERTY_SUCCESS = 'DELETE_PROPERTY_SUCCESS'
export const DELETE_PROPERTY_FAILED = 'DELETE_PROPERTY_FAILED'
export const CLEAR_PROPERTY = 'CLEAR_PROPERTY'

// ---------Get Products List------- //
export const GET_PRODUCT_LIST_STARTED = 'GET_PRODUCT_LIST_STARTED'
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS'
export const GET_PRODUCT_LIST_FAILED = 'GET_PRODUCT_LIST_FAILED'
export const GET_PRODUCT_LIST_GUEST_STARTED = 'GET_PRODUCT_LIST_GUEST_STARTED'
export const GET_PRODUCT_LIST_GUEST_SUCCESS = 'GET_PRODUCT_LIST_GUEST_SUCCESS'
export const GET_PRODUCT_LIST_GUEST_FAILED = 'GET_PRODUCT_LIST_GUEST_FAILED'
export const REVIEW_PROPERTY_STARTED = 'REVIEW_PROPERTY_STARTED'
export const REVIEW_PROPERTY_SUCCESS = 'REVIEW_PROPERTY_SUCCESS'
export const REVIEW_PROPERTY_FAILURE = 'REVIEW_PROPERTY_FAILURE'
export const CLEAR_PRODUCT_LIST = 'CLEAR_PRODUCT_LIST'
export const CLEAR_PRODUCT_LIST_GUEST = 'CLEAR_PRODUCT_LIST_GUEST'
export const RESET_PRODUCT_LIST_TOASTS = 'RESET_PRODUCT_LIST_TOASTS'
export const SAVE_PRODUCT_LIST_FIRST_PAGE_CACHE =
  'SAVE_PRODUCT_LIST_FIRST_PAGE_CACHE'

// -------- Filter Product List ---------//
export const FILTER_PRODUCTS_STARTED = 'FILTER_PRODUCTS_STARTED'
export const FILTER_PRODUCTS_SUCCESS = 'FILTER_PRODUCTS_SUCCESS'
export const FILTER_PRODUCTS_FAILED = 'FILTER_PRODUCTS_FAILED'
export const CLEAR_FILTER_PRODUCTS = 'CLEAR_FILTER_PRODUCTS'

// ---------Get Filters List------- //
export const GET_FILTERS_STARTED = 'GET_FILTERS_STARTED'
export const GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS'
export const GET_FILTERS_FAILED = 'GET_FILTERS_FAILED'

// ---------Get Product Details ------- //
export const GET_PRODUCT_DETAILS_STARTED = 'GET_PRODUCT_DETAILS_STARTED'
export const GET_PRODUCT_DETAILS_SUCCESS = 'GET_PRODUCT_DETAILS_SUCCESS'
export const GET_PRODUCT_DETAILS_FAILED = 'GET_PRODUCT_DETAILS_FAILED'
export const CLEAR_PRODUCT_DETAILS = 'CLEAR_PRODUCT_DETAILS'
export const LIKE_PRODUCT_STARTED = 'LIKE_PRODUCT_STARTED'
export const LIKE_PRODUCT_SUCCESS = 'LIKE_PRODUCT_SUCCESS'
export const LIKE_PRODUCT_FAILURE = 'LIKE_PRODUCT_FAILURE'
export const UNLIKE_PRODUCT_STARTED = 'UNLIKE_PRODUCT_STARTED'
export const UNLIKE_PRODUCT_SUCCESS = 'UNLIKE_PRODUCT_SUCCESS'
export const UNLIKE_PRODUCT_FAILURE = 'UNLIKE_PRODUCT_FAILURE'
export const RATE_PRODUCT_STARTED = 'RATE_PRODUCT_STARTED'
export const RATE_PRODUCT_SUCCESS = 'RATE_PRODUCT_SUCCESS'
export const RATE_PRODUCT_FAILURE = 'RATE_PRODUCT_FAILURE'
export const RESET_PRODUCT_TOASTS = 'RESET_PRODUCT_TOASTS'

// --------- Cart ------- //
export const ADD_TO_CART_STARTED = 'ADD_TO_CART_STARTED'
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS'
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE'
export const GET_USER_CART_STARTED = 'GET_USER_CART_STARTED'
export const GET_USER_CART_SUCCESS = 'GET_USER_CART_SUCCESS'
export const GET_USER_CART_FAILURE = 'GET_USER_CART_FAILURE'
export const DELETE_CART_PRODUCT_STARTED = 'DELETE_CART_PRODUCT_STARTED'
export const DELETE_CART_PRODUCT_SUCCESS = 'DELETE_CART_PRODUCT_SUCCESS'
export const DELETE_CART_PRODUCT_FAILURE = 'DELETE_CART_PRODUCT_FAILURE'
export const UPDATE_CART_PRODUCT_QUANTITY_STARTED =
  'UPDATE_CART_PRODUCT_QUANTITY_STARTED'
export const UPDATE_CART_PRODUCT_QUANTITY_SUCCESS =
  'UPDATE_CART_PRODUCT_QUANTITY_SUCCESS'
export const UPDATE_CART_PRODUCT_QUANTITY_FAILURE =
  'UPDATE_CART_PRODUCT_QUANTITY_FAILURE'
export const RESET_USER_CART = 'RESET_USER_CART'
export const RESET_CART_TOASTS = 'RESET_CART_TOASTS'

// --------- Cart Guest------- //
export const ADD_TO_CART_GUEST = 'ADD_TO_CART_GUEST'
export const DELETE_CART_GUEST = 'DELETE_CART_GUEST'
export const UPDATE_CART_GUEST = 'UPDATE_CART_GUEST'
export const RESET_CART_GUEST = 'RESET_CART_GUEST'
export const RESET_CART_TOASTS_GUEST = 'RESET_CART_TOASTS_GUEST'

// --------- Checkout ------- //
export const START_CHECKOUT_STARTED = 'START_CHECKOUT_STARTED'
export const START_CHECKOUT_SUCCESS = 'START_CHECKOUT_SUCCESS'
export const START_CHECKOUT_FAILURE = 'START_CHECKOUT_FAILURE'
export const RESET_CHECKOUT_TOASTS = 'RESET_CHECKOUT_TOASTS'

// --------- ORDERS LIST ------- //
export const GET_ORDERS_LIST_STARTED = 'GET_ORDERS_LIST_STARTED'
export const GET_ORDERS_LIST_SUCCESS = 'GET_ORDERS_LIST_SUCCESS'
export const GET_ORDERS_LIST_FAILURE = 'GET_ORDERS_LIST_FAILURE'

// --------- CANCEL ORDERS ------- //
export const CANCEL_ORDER_STARTED = 'CANCEL_ORDER_STARTED'
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS'
export const CANCEL_ORDER_FAILURE = 'CANCEL_ORDER_FAILURE'
export const CLEAR_CANCEL_ORDERS = 'CLEAR_CANCEL_ORDERS'

// --------- ORDERS DETAILS ------- //
export const GET_ORDER_DETAILS_STARTED = 'GET_ORDER_DETAILS_STARTED'
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS'
export const GET_ORDER_DETAILS_FAILURE = 'GET_ORDER_DETAILS_FAILURE'

// --------- FAVORITES LIST ------- //
export const GET_FAVORITES_LIST_STARTED = 'GET_FAVORITES_LIST_STARTED'
export const GET_FAVORITES_LIST_SUCCESS = 'GET_FAVORITES_LIST_SUCCESS'
export const GET_FAVORITES_LIST_FAILURE = 'GET_FAVORITES_LIST_FAILURE'
export const CLEAR_FAVORITES_LIST = 'CLEAR_FAVORITES_LIST'

// --------- URL GUEST PARAMS ------- //
export const SET_URL_GUEST_PARAMS = 'SET_URL_GUEST_PARAMS'

// --------- SAVED LIST ------- //
export const ADD_TO_SAVED = 'ADD_TO_SAVED'
export const DELETE_SAVED = 'DELETE_SAVED'
export const CLEAR_SAVED_PRODUCTS = 'CLEAR_SAVED_PRODUCTS'

// --------- HOST PRODUCT FILTERS ------- //
export const SAVE_HOST_ACTIVE_PRODUCT_FILTERS =
  'SAVE_HOST_ACTIVE_PRODUCT_FILTERS'

// --------- GUEST PRODUCT FILTERS ------- //
export const SAVE_GUEST_ACTIVE_PRODUCT_FILTERS =
  'SAVE_GUEST_ACTIVE_PRODUCT_FILTERS'
