/* eslint default-param-last: off */
import {
  GET_PROPERTY_LIST_STARTED,
  GET_PROPERTY_LIST_SUCCESS,
  GET_PROPERTY_LIST_FAILED,
  DELETE_PROPERTY_STARTED,
  DELETE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_FAILED,
  GET_COMMISSION_INFO_STARTED,
  GET_COMMISSION_INFO_SUCCESS,
  GET_COMMISSION_INFO_FAILED,
  WITHDRAW_COMMISSION_STARTED,
  WITHDRAW_COMMISSION_SUCCESS,
  WITHDRAW_COMMISSION_FAILED,
  RESET_PROPERTY_LIST_TOASTS,
} from '../constants'

const initialStatePropertyList = {
  propertyList: {},
  propertyListStarted: false,
  propertyListSuccess: false,
  propertyListIsLoading: false,
  propertyListFailed: false,
  propertyListError: '',
}

const initialStateDeleteProperty = {
  deleteProperty: {},
  deletePropertyStarted: false,
  deletePropertySuccess: false,
  deletePropertyIsLoading: false,
  deletePropertyFailed: false,
  deletePropertyError: '',
}

const initialStateGetCommissions = {
  commissions: {},
  commissionsStarted: false,
  commissionsSuccess: false,
  commissionsFailed: false,
  commissionsError: '',
  commissionsIsLoading: false,
}

const initialStateWithdrawCommissions = {
  withdrawCommissionStarted: false,
  withdrawCommissionSuccess: false,
  withdrawCommissionFailed: false,
  withdrawCommissionError: '',
  withdrawCommissionisLoading: false,
}

export const initialState = {
  ...initialStatePropertyList,
  ...initialStateDeleteProperty,
  ...initialStateGetCommissions,
  ...initialStateWithdrawCommissions,
}

const propertyListReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_PROPERTY_LIST_STARTED: {
      return {
        ...state,
        ...initialStatePropertyList,
        propertyListStarted: true,
        propertyListIsLoading: true,
      }
    }
    case GET_PROPERTY_LIST_SUCCESS: {
      return {
        ...state,
        ...initialStatePropertyList,
        propertyListSuccess: true,
        propertyList: payload,
      }
    }
    case GET_PROPERTY_LIST_FAILED: {
      return {
        ...state,
        ...initialStatePropertyList,
        propertyListFailed: true,
        propertyListError: payload.errorMessage,
      }
    }
    case DELETE_PROPERTY_STARTED: {
      return {
        ...state,
        ...initialStateDeleteProperty,
        deletePropertyStarted: true,
        deletePropertyIsLoading: true,
      }
    }
    case DELETE_PROPERTY_SUCCESS: {
      return {
        ...state,
        ...initialStateDeleteProperty,
        deleteProperty: payload,
        deletePropertySuccess: true,
      }
    }
    case DELETE_PROPERTY_FAILED: {
      return {
        ...state,
        ...initialStateDeleteProperty,
        deletePropertyFailed: true,
        deletePropertyError: payload.errorMessage,
      }
    }

    case GET_COMMISSION_INFO_STARTED: {
      return {
        ...state,
        ...initialStateGetCommissions,
        commissionsStarted: true,
        commissionsIsLoading: true,
      }
    }
    case GET_COMMISSION_INFO_SUCCESS: {
      return {
        ...state,
        ...initialStateGetCommissions,
        commissionsSuccess: true,
        commissions: payload,
      }
    }
    case GET_COMMISSION_INFO_FAILED: {
      return {
        ...state,
        ...initialStateGetCommissions,
        commissionsFailed: true,
        commissionsError: payload.errorMessage,
      }
    }

    case WITHDRAW_COMMISSION_STARTED: {
      return {
        ...state,
        ...initialStateWithdrawCommissions,
        withdrawCommissionisLoading: true,
        withdrawCommissionStarted: true,
      }
    }
    case WITHDRAW_COMMISSION_SUCCESS: {
      return {
        ...state,
        ...initialStateWithdrawCommissions,
        withdrawCommissionSuccess: true,
        ...initialStateGetCommissions,
        commissions: payload,
      }
    }
    case WITHDRAW_COMMISSION_FAILED: {
      return {
        ...state,
        ...initialStateWithdrawCommissions,
        withdrawCommissionFailed: true,
        withdrawCommissionError: payload.errorMessage,
      }
    }

    case RESET_PROPERTY_LIST_TOASTS: {
      return {
        ...state,
        ...initialStateDeleteProperty,
        withdrawCommissionSuccess: false,
        withdrawCommissionError: '',
      }
    }

    default:
      return state
  }
}

export default propertyListReducer
