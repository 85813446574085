/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

import InputComponent from '../../components/Inputs/InputComponent'
import SelectComponent from '../../components/SelectComponent'
import LoaderComponent from '../../components/Loader'
import ModalComponent from '../../components/ModalComponent'
import Button from '../../components/Buttons/Button'
import Tabs from '../../components/Tabs'
// import infoIcon from '../../assets/icons/info-circle.svg'

import {
  AddProperty as AddPropertyAction,
  RemoveAddProperty,
} from '../../redux/actions/AddPropertyActions'
import { states, cities, getState } from '../../utils/countryStateCity'
import { validateLink } from '../../utils/fieldValidator'
import ErrorAlert from '../../components/ErrorAlert'
import defaultCopy from '../../utils/dictionary'
import { extractSessionInfo, setPageTitleAndUserInfo } from '../../utils'

function AddProperty({
  session,
  isLoading,
  addPropertySuccess,
  addPropertyError,
  addProperty,
}) {
  const {
    addProperty: { modal },
  } = defaultCopy
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { userId, userName, userEmail, jwt } = extractSessionInfo(session)

  useEffect(
    () => setPageTitleAndUserInfo('Add Property', userId, userName, userEmail),
    []
  )

  const [fields, setFields] = useState({
    nickname: '',
    zipCode: '',
    address: '',
    listingLink: '',
    city: '',
  })
  const [selectedTab, setSelectedTab] = useState('airbnb')
  const [showModal, setShowModal] = useState(false)
  const [invalidLink, setInvalidLink] = useState(false)
  const [invalidCalendar, setInvalidCalendar] = useState(false)
  const [selectCity, setSelectCity] = useState(false)
  const [invalidNickName, setInvalidNickName] = useState(false)
  useEffect(() => {
    const { listingLink } = fields
    setInvalidLink(listingLink.length >= 1 ? !validateLink(listingLink) : false)
    if (fields?.calendarLink) {
      setInvalidCalendar(
        fields?.calendarLink.length >= 1
          ? !validateLink(fields.calendarLink)
          : false
      )
    } else {
      setInvalidCalendar(false)
    }
  }, [fields])

  useEffect(() => {
    if (addPropertySuccess) {
      const { id } = addProperty
      navigate(`/property/${id}/details`)
      dispatch(RemoveAddProperty())
    }
  }, [addPropertySuccess])

  useEffect(() => {
    if (addPropertyError) {
      setInvalidNickName(true)
    }
  }, [addPropertyError])

  const handleChange = ({ name, value }) => {
    if (name === 'nickname') {
      setInvalidNickName(false)
    }
    setFields({ ...fields, [name]: value })
  }

  const handleCancel = () => navigate('/property/list')

  const handleSave = () => {
    const {
      nickname,
      address,
      state,
      city,
      zipCode,
      calendarLink,
      listingLink,
      address2,
    } = fields
    const newState = state ? getState(state) : state
    if (
      [nickname, listingLink, zipCode, address]?.some(
        (item) => item.length >= 1
      ) &&
      fields?.state &&
      fields?.city
    ) {
      dispatch(
        AddPropertyAction(
          {
            nickName: nickname.trim(),
            address,
            state: newState,
            secondAddress: address2,
            city,
            zipCode,
            calendarLink,
            listingLink,
          },
          jwt
        )
      )
    }
  }
  const shouldDisableButton = () => {
    const { nickname, address, zipCode, listingLink } = fields
    if (
      [nickname, listingLink, zipCode, address]?.some(
        (item) => item.length < 1
      ) ||
      !fields?.state ||
      !fields?.city
    ) {
      return true
    }
    return false
  }

  const shouldShowModal = () => setShowModal(!showModal)

  const handleFocus = () => {
    if (fields.city.length > 0) {
      setSelectCity(true)
    }
  }

  const showSelectedTabContent = () =>
    modal[selectedTab].map((item) => (
      <li>
        <span>
          <p>{item}</p>
        </span>
      </li>
    ))
  return (
    <section className="add-property property-container">
      {isLoading && (
        <LoaderComponent show={isLoading} text="Signing in please wait..." />
      )}
      {addPropertyError && <ErrorAlert error={addPropertyError} />}
      <h4>Add Property</h4>
      <article className="add-property__user-info">
        <div className="add-property--direction">
          <div className="add-property__nickname">
            <InputComponent
              id="nickname"
              name="nickname"
              labelText="Nickname"
              isMandatory
              onChange={({ target }) => handleChange(target)}
              invalid={invalidNickName}
              outlined
              helperText="You won’t be able to edit this field once you have created the property"
              invalidText="Already exist a property with that name."
            />
          </div>

          <div
            className={invalidNickName ? 'listing-link resize' : 'listing-link'}
          >
            <InputComponent
              id="listing_link"
              name="listingLink"
              labelText="Listing link"
              isMandatory
              invalid={invalidLink}
              onChange={({ target }) => handleChange(target)}
              outlined
              invalidText="Please enter a correct URL"
            />
            {/* <img src={infoIcon} alt="info circle" /> */}
          </div>
        </div>
        <div className="calendar-link">
          <InputComponent
            id="calendar_link"
            name="calendarLink"
            labelText="Calendar link"
            invalid={invalidCalendar}
            invalidText="Please enter a correct URL"
            onChange={({ target }) => handleChange(target)}
            outlined
          />
          <span>
            {/* <img src={infoIcon} alt="info circle" /> */}
            <button type="button" onClick={() => shouldShowModal()}>
              <p>How to find your calendar link</p>
            </button>
          </span>
        </div>
      </article>
      <h5>Shipping address</h5>
      <div className="add-property__shipping-info row">
        <InputComponent
          id="address"
          name="address"
          labelText="Address"
          isMandatory
          onChange={({ target }) => handleChange(target)}
          outlined
          className="col-12 col-sm-6"
        />
        <div className="add-property__state-city-input">
          <Dropdown isOpen={selectCity} toggle={() => {}}>
            <DropdownToggle nav>
              <InputComponent
                id="city"
                name="city"
                onFocus={() => handleFocus()}
                labelText="City"
                isMandatory
                value={fields.city}
                onChange={({ target }) => {
                  handleChange(target)
                  setSelectCity(true)
                }}
                outlined
                className="col-12 col-sm-6"
                autoComplete={false}
              />
            </DropdownToggle>
            <DropdownMenu>
              {cities(undefined, fields?.state ? fields.state : undefined)
                .filter((city) =>
                  city.text.toLowerCase().startsWith(fields.city.toLowerCase())
                )
                .map((city) => (
                  <DropdownItem
                    onClick={() => {
                      setFields({ ...fields, city: city.text })
                      setSelectCity(false)
                    }}
                  >
                    {city.text}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        <SelectComponent
          id="state"
          name="state"
          labelText="State"
          isMandatory
          onFocus={() => setSelectCity(false)}
          onChange={({ target }) => handleChange(target)}
          options={states()}
          className="col-12 col-sm-6"
        />
        <InputComponent
          id="zip_code"
          name="zipCode"
          labelText="Zip code"
          isMandatory
          onChange={({ target }) => handleChange(target)}
          outlined
          className="col-12 col-sm-6"
        />
        <InputComponent
          id="address2"
          name="address2"
          labelText="Apartment/Floor/Other"
          onChange={({ target }) => handleChange(target)}
          outlined
          className="col-12 col-sm-6"
        />
      </div>
      <div className="add-property__buttons">
        <Button
          id="cancel"
          secondary
          text="Cancel"
          action={() => handleCancel()}
        />
        <Button
          id="save"
          text="Save"
          disabled={shouldDisableButton()}
          action={() => handleSave()}
        />
      </div>
      <ModalComponent className="tutorial" show={showModal}>
        <div>
          <Tabs
            options={[
              { name: 'AIRBNB', key: 'airbnb' },
              { name: 'VRBO', key: 'vrbo' },
            ]}
            actions={[
              () => setSelectedTab('airbnb'),
              () => setSelectedTab('vrbo'),
            ]}
            active={selectedTab}
          />
          <div className="tutorial-modal__tab-content">
            <ul>{showSelectedTabContent()}</ul>
          </div>
          <Button id="closeTutorial" action={shouldShowModal} text="Go Back" />
        </div>
      </ModalComponent>
    </section>
  )
}

AddProperty.propTypes = {
  session: PropTypes.object.isRequired,
  addProperty: PropTypes.object.isRequired,
  addPropertySuccess: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  addPropertyError: PropTypes.string.isRequired,
}

function mapStateToProps({ addProperty, login }) {
  return {
    session: login.login,
    addProperty: addProperty.addProperty,
    addPropertySuccess: addProperty.addPropertySuccess,
    isLoading: addProperty.addPropertyIsLoading,
    addPropertyError: addProperty.addPropertyError,
  }
}

export default connect(mapStateToProps)(AddProperty)
