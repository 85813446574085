/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import Button from '../../components/Buttons/Button'
import InputComponent from '../../components/Inputs/InputComponent'
import LoaderComponent from '../../components/Loader'
import LogoGoogle from '../../assets/icons/logo-google.svg'
import LogoFacebook from '../../assets/icons/logo-facebook.svg'

import { UserLogin } from '../../redux/actions/LoginActions'
import Logout from '../../redux/actions/LogoutActions'
import CartActions from '../../redux/actions/CartActions'
import { validateEmail } from '../../utils/fieldValidator'
import notifyToast from '../../utils/notifyToast'

import defaultCopy from '../../utils/dictionary'
import { extractSessionInfo, setPageTitleAndUserInfo } from '../../utils'
import useGuest from '../../hooks/useGuest'

function Login(props) {
  const { session, isLoading, loginError } = props
  const navigate = useNavigate()
  const { propertyId, propertyName, baseRoute } = useGuest()
  const { userId, userName, userEmail, userType, isFirstTimeLogin, jwt } =
    extractSessionInfo(session)

  useEffect(
    () => setPageTitleAndUserInfo('Login', userId, userName, userEmail),
    []
  )

  const [formFields, setFormFields] = useState({
    email: '',
    password: '',
  })
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [invalidPassword, setInvalidPassword] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    if (Object.values(session).length >= 1) {
      dispatch(CartActions.GetShoppingCart({ token: jwt }))
      if (userType === 'host') {
        if (propertyId || propertyName) {
          dispatch(Logout())
          notifyToast('error', 'This is a host account', 2500)
        } else {
          // If user is logging for the first time, send to property list. Otherwise, send to product list
          notifyToast('success', 'User logged successfully', 2000)
          if (userId) {
            if (!isFirstTimeLogin) {
              if (process.env?.REACT_APP_ENVIRONMENT === 'production') {
                window.location.href = `${process.env.REACT_APP_SHOPIFY_HOME_PAGE}?name=${userName}`
              } else {
                navigate('/product/list')
              }
            } else {
              navigate('/property/list')
            }
          } else {
            navigate('/product/list')
          }
        }
      } else if (userType === 'guest') {
        if (!propertyId || !propertyName) {
          dispatch(Logout())
          notifyToast('error', 'This is a guest account', 2500)
        } else {
          notifyToast('success', 'User logged successfully', 2000)
          navigate(`${baseRoute}/product/list`)
        }
      }
    }
  }, [session])

  const setLocalEmailError = (err) => {
    if (err) {
      setEmailError(err)
      setInvalidEmail(true)
    } else {
      setEmailError('')
      setInvalidEmail(false)
    }
  }
  const setLocalPasswordError = (err) => {
    if (err) {
      setPasswordError(err)
      setInvalidPassword(true)
    } else {
      setPasswordError('')
      setInvalidPassword(false)
    }
  }

  useEffect(() => {
    if (typeof loginError !== 'string') return

    const lowercaseError = loginError.toLowerCase()

    if (
      lowercaseError.indexOf('email') > -1 ||
      lowercaseError.indexOf('user ') > -1
    )
      setLocalEmailError(loginError)
    else if (lowercaseError.indexOf('incorrect') > -1)
      setLocalPasswordError(loginError)
  }, [loginError])

  useEffect(() => {
    const { email } = formFields
    if (email.length >= 1 && !validateEmail(email)) {
      setLocalEmailError(defaultCopy.ValidationMessages.INVALID_EMAIL)
    } else {
      setLocalEmailError('')
    }
  }, [formFields])

  const handleChange = ({ name, value }) => {
    if (name === 'email') {
      setInvalidEmail(false)
    } else {
      setInvalidPassword(false)
    }
    setFormFields({ ...formFields, [name]: value })
  }

  const handleSubmit = () => {
    const { email, password } = formFields
    if (!invalidPassword && !invalidEmail) {
      return dispatch(
        UserLogin({
          email,
          password,
        })
      )
    }
    return null
  }

  const shouldActivateButton = () => {
    if (
      invalidEmail ||
      invalidPassword ||
      Object.values(formFields)?.some((item) => item.length < 1)
    ) {
      return false
    }
    return true
  }

  const handleKeyDown = ({ key }) => {
    if (!shouldActivateButton()) return
    if (key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <section className="login container">
      {isLoading && (
        <LoaderComponent
          show={isLoading} /*  text="Signing in please wait..."  */
        />
      )}

      <h1 data-testid="login-title">Login</h1>

      <div className="login__auth-buttons">
        <button type="button" id="Google">
          <span>
            <img src={LogoGoogle} alt="logo google" />
            Sign in with Google
          </span>
        </button>
        <button type="button" id="Facebook">
          <span>
            <img src={LogoFacebook} alt="logo facebook" />
            Sign in with Facebook
          </span>
        </button>
      </div>

      <div className="login__form">
        <div className="login__form-first_input">
          <InputComponent
            id="email"
            type="email"
            invalid={invalidEmail}
            invalidText={emailError}
            name="email"
            labelText="Email"
            onChange={({ target }) => handleChange(target)}
          />
        </div>
        <InputComponent
          id="password"
          type="password"
          invalid={invalidPassword}
          name="password"
          invalidText={passwordError}
          labelText="Password"
          onChange={({ target }) => handleChange(target)}
          onKeydown={(key) => handleKeyDown(key)}
          showPasswordControl
        />
        <span>
          <strong>
            <Link to={`${baseRoute}/reset-password`}> Forgot password?</Link>
          </strong>
        </span>
        <Button
          id="login-form-btn"
          disabled={!shouldActivateButton()}
          action={() => handleSubmit()}
          text="Log In"
        />
        <span className="sign-up-link">
          Don&#39;t have an account?
          <Link to={`${baseRoute}/sign-up`}> Sign up</Link>
        </span>
      </div>
    </section>
  )
}

Login.propTypes = {
  session: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loginError: PropTypes.string.isRequired,
}

function mapStateToProps({ login }) {
  return {
    session: login.login,
    isLoading: login.loginIsLoading,
    loginError: login.loginError,
  }
}

export default connect(mapStateToProps)(Login)
