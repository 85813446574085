import React from 'react'
import PropTypes from 'prop-types'

function ErrorAlert(props) {
  const { error } = props
  return <span className="toast-container">{error}</span>
}

ErrorAlert.propTypes = {
  error: PropTypes.string.isRequired,
}

export default ErrorAlert
