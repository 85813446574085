/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import Button from '../../components/Buttons/Button'
import InputComponent from '../../components/Inputs/InputComponent'
import ModalComponent from '../../components/ModalComponent'
import LoaderComponent from '../../components/Loader'
import LogoGoogle from '../../assets/icons/logo-google.svg'
import LogoFacebook from '../../assets/icons/logo-facebook.svg'
import Checkbox from '../../components/CheckBox'

import SignUpActions from '../../redux/actions/SignUpActions'
import { validateEmail, validatePassword } from '../../utils/fieldValidator'

import defaultCopy from '../../utils/dictionary'
import { setPageTitleAndUserInfo } from '../../utils'
import useGuest from '../../hooks/useGuest'
import notifyToast from '../../utils/notifyToast'

const { UserSignUp, ClearSignUp, ResendSignupConfirmationCode, ConfirmSignUp } =
  SignUpActions

function SignUp({
  signUpSuccess,
  isLoading,
  signUpError,
  signUpConfirmAccountError,
  signUpConfirmationCodeError,
  signUpResendConfirmationCodeSuccess,
  signUpConfirmAccountSuccess,
}) {
  const navigate = useNavigate()
  const { isGuest, baseRoute } = useGuest()
  const [formFields, setFormFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
    policy: false,
    password: '',
  })
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [invalidPassword, setInvalidPassword] = useState(false)
  const [invalidFirstName, setInvalidFirstName] = useState(false)
  const [invalidLastName, setInvalidLastName] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const userType = isGuest ? 'guest' : 'host'
  const dispatch = useDispatch()

  // code verification modal
  const [confirmationCode, setConfirmationCode] = useState('')
  const [confirmationCodeError, setConfirmationCodeError] = useState('')
  const confirmationCodeBtnEnabled = !!(
    confirmationCode && !confirmationCodeError
  )
  const hasValidConfirmationData = () => !!(formFields.email && signUpSuccess)
  const onConfirmationCodeChange = (newValue) => {
    if (typeof newValue !== 'string') return
    setConfirmationCode(newValue)
    if (newValue.length !== 6 || Number.isNaN(Number(newValue))) {
      setConfirmationCodeError(
        defaultCopy.ValidationMessages.INVALID_VERIFICATION_CODE
      )
    } else {
      setConfirmationCodeError('')
    }
  }
  const onResendConfirmationCodeClicked = () => {
    if (!hasValidConfirmationData()) return
    const { email } = formFields
    dispatch(ResendSignupConfirmationCode({ email }))
    setConfirmationCode('')
    setConfirmationCodeError('')
  }
  const onConfirmCodeClick = () => {
    if (!hasValidConfirmationData()) return
    if (!confirmationCodeBtnEnabled || confirmationCode === '') return
    const { email } = formFields
    dispatch(ConfirmSignUp({ email, code: confirmationCode }))
  }
  const errorInConfirmationModal =
    confirmationCodeError ||
    signUpConfirmAccountError ||
    signUpConfirmationCodeError

  useEffect(() => setPageTitleAndUserInfo('Sign up'), [])
  useEffect(() => dispatch(ClearSignUp()), [])

  const setLocalEmailError = (err) => {
    if (err) {
      setEmailError(err)
      setInvalidEmail(true)
    } else {
      setEmailError('')
      setInvalidEmail(false)
    }
  }
  const setLocalPasswordError = (err) => {
    if (err) {
      setPasswordError(err)
      setInvalidPassword(true)
    } else {
      setPasswordError('')
      setInvalidPassword(false)
    }
  }
  const errorOnName = (error) => {
    if (error.match('Validation')) {
      return error.split('length')[1]
    }
    return error
  }
  const setLocalFirstNameError = (err) => {
    if (err) {
      const newError = errorOnName(err)
      setFirstNameError(newError)
      setInvalidFirstName(true)
    } else {
      setFirstNameError('')
      setInvalidFirstName(false)
    }
  }
  const setLocalLastNameError = (err) => {
    if (err) {
      const newError = errorOnName(err)
      setLastNameError(newError)
      setInvalidLastName(true)
    } else {
      setLastNameError('')
      setInvalidLastName(false)
    }
  }

  useEffect(() => {
    if (typeof signUpError !== 'string') return

    if (!signUpError) {
      setLocalEmailError('')
      setLocalPasswordError('')
    }
    const lowercaseError = signUpError.toLowerCase()

    if (
      lowercaseError.indexOf('email') > -1 ||
      lowercaseError.indexOf('user') > -1
    )
      setLocalEmailError(signUpError)
    else if (lowercaseError.indexOf('incorrect') > -1)
      setLocalPasswordError(signUpError)
    else if (lowercaseError.indexOf('firstname') > -1)
      setLocalFirstNameError(signUpError)
    else if (lowercaseError.indexOf('lastname') > -1)
      setLocalLastNameError(signUpError)
  }, [signUpError])

  useEffect(() => {
    const { email, password } = formFields
    if (email.length >= 1 && !validateEmail(email)) {
      setLocalEmailError(defaultCopy.ValidationMessages.INVALID_EMAIL)
    } else {
      setLocalEmailError('')
    }
    if (password.length >= 1 && !validatePassword(password)) {
      setLocalPasswordError(defaultCopy.ValidationMessages.INVALID_PASSWORD)
    } else {
      setLocalPasswordError('')
    }
    setInvalidEmail(email.length >= 1 ? !validateEmail(email) : false)
    setInvalidPassword(
      password.length >= 1 ? !validatePassword(password) : false
    )
  }, [formFields])

  useEffect(() => {
    if (signUpConfirmAccountSuccess) {
      notifyToast('success', defaultCopy.signUp.successToastMessage, 2000)
      navigate(`${baseRoute}/`)
    }
  }, [signUpConfirmAccountSuccess])

  const handleChange = (target) => {
    if (target.name === 'firstName') {
      setInvalidFirstName(false)
    }
    if (target.name === 'lastName') {
      setInvalidLastName(false)
    }
    if (target.name === 'policy') {
      return setFormFields({ ...formFields, [target.name]: target.checked })
    }
    return setFormFields({ ...formFields, [target.name]: target.value })
  }

  const handleSubmit = () => {
    const { firstName, lastName, email, password, policy } = formFields
    if (!(invalidPassword || invalidEmail) && policy) {
      return dispatch(
        UserSignUp({
          firstName,
          lastName,
          email,
          password,
          userType,
          isGuest,
        })
      )
    }
    return null
  }

  const shouldActivateButton = () => {
    if (
      invalidEmail ||
      invalidPassword ||
      invalidFirstName ||
      invalidLastName ||
      !formFields.policy ||
      Object.values(formFields)?.some((item) => item.length < 1)
    ) {
      return false
    }
    return true
  }

  const termsAndConditions = isGuest
    ? 'https://spaceplaceapp.com/pages/guest_terms.html'
    : 'https://spaceplaceapp.com/pages/terms.html'

  return (
    <section className="sign-up container">
      {isLoading && (
        <LoaderComponent show={isLoading} text="Signing in please wait..." />
      )}

      <h1 data-testid="signup-title">Create Account</h1>

      <div className="sign-up__auth-buttons">
        <button type="button" id="Google">
          <span>
            <img src={LogoGoogle} alt="logo google" />
            Sign in with Google
          </span>
        </button>
        <button type="button" id="Facebook">
          <span>
            <img src={LogoFacebook} alt="logo facebook" />
            Sign in with Facebook
          </span>
        </button>
      </div>

      <div className="sign-up__form">
        <div className="sign-up__form-name">
          <InputComponent
            id="firstName"
            name="firstName"
            labelText="First name"
            invalid={invalidFirstName}
            invalidText={firstNameError}
            onChange={({ target }) => handleChange(target)}
          />
          <InputComponent
            id="lastName"
            name="lastName"
            labelText="Last name"
            invalid={invalidLastName}
            invalidText={lastNameError}
            onChange={({ target }) => handleChange(target)}
          />
        </div>
        <InputComponent
          id="email"
          type="email"
          invalid={invalidEmail}
          invalidText={emailError}
          name="email"
          labelText="Email"
          onChange={({ target }) => handleChange(target)}
        />
        <InputComponent
          id="password"
          type="password"
          invalid={invalidPassword}
          name="password"
          title="Must contain at least a number or special character"
          invalidText={passwordError}
          labelText="Password"
          onChange={({ target }) => handleChange(target)}
          showPasswordControl
        />
        <div className="checkbox-field">
          <Checkbox
            id="policy_terms"
            name="policy"
            checked={formFields.policy}
            onClick={({ target }) => handleChange(target)}
          />
          <p>
            I agree to the{' '}
            <a href={termsAndConditions} target="_blank">
              terms and privacy policy
            </a>
          </p>
        </div>
        <Button
          id="create-account"
          disabled={!shouldActivateButton()}
          action={() => handleSubmit()}
          text="Create Account"
        />
        <span>
          Already have an account?
          <Link to={`${baseRoute}/`}> Log in</Link>
        </span>
      </div>
      <ModalComponent show={signUpSuccess} className="sign-up">
        <div>
          <h4>{defaultCopy.signUp.modal[0]}</h4>
          <InputComponent
            id="confirmation-code-input-id"
            name="confirmation-code-input-id"
            labelText={defaultCopy.signUp.confirmationCodeInput.placeholder}
            title="Confirmation code"
            type="text"
            onChange={(e) => onConfirmationCodeChange(e.target.value)}
            value={confirmationCode}
            invalid={!!errorInConfirmationModal}
            invalidText={errorInConfirmationModal}
            style={{ width: '400px', marginBottom: '10px' }}
          />
          <p>
            {defaultCopy.signUp.modal[1]}{' '}
            <button
              type="button"
              onClick={() => onResendConfirmationCodeClicked()}
            >
              {defaultCopy.signUp.modal[2]}
            </button>
            {signUpResendConfirmationCodeSuccess && <span>Sent</span>}
          </p>
          <Button
            id="redirectToLogin"
            text="Confirm code"
            disabled={!confirmationCodeBtnEnabled}
            primary
            action={() => onConfirmCodeClick()}
          />
        </div>
      </ModalComponent>
    </section>
  )
}

SignUp.propTypes = {
  signUpSuccess: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  signUpError: PropTypes.string.isRequired,
  signUpConfirmAccountError: PropTypes.string.isRequired,
  signUpConfirmationCodeError: PropTypes.string.isRequired,
  signUpResendConfirmationCodeSuccess: PropTypes.bool.isRequired,
  signUpConfirmAccountSuccess: PropTypes.bool.isRequired,
}

function mapStateToProps({ signUp }) {
  return {
    signUpSuccess: signUp.signUpSuccess,
    isLoading: signUp.signUpIsLoading,
    signUpError: signUp.signUpError,
    signUpResendConfirmationCodeSuccess:
      signUp.signUpResendConfirmationCodeSuccess,
    signUpConfirmAccountSuccess: signUp.signUpConfirmAccountSuccess,
    signUpConfirmAccountError: signUp.signUpConfirmAccountError,
    signUpConfirmationCodeError: signUp.signUpConfirmationCodeError,
  }
}

export default connect(mapStateToProps)(SignUp)
