/* eslint default-param-last: off */
import {
  RESET_PROPERTY_DETAILS_TOASTS,
  GET_PROPERTY_DETAILS_STARTED,
  GET_PROPERTY_DETAILS_SUCCESS,
  GET_PROPERTY_DETAILS_FAILED,
  GET_PROPERTY_PURCHASE_HISTORY_STARTED,
  GET_PROPERTY_PURCHASE_HISTORY_SUCCESS,
  GET_PROPERTY_PURCHASE_HISTORY_FAILED,
  CLEAR_PROPERTY_PURCHASE_HISTORY,
  CLEAR_PROPERTY_DETAILS,
} from '../constants'

const initialStateGetPropertyDetails = {
  propertyDetails: {},
  propertyDetailsStarted: false,
  propertyDetailsSuccess: false,
  propertyDetailsIsLoading: false,
  propertyDetailsFailed: false,
  propertyDetailsError: '',
}

const purchaseHistoryInitialState = {
  purchaseHistory: {},
  purchaseHistoryStarted: false,
  purchaseHistorySuccess: false,
  purchaseHistoryIsLoading: false,
  purchaseHistoryFailed: false,
  purchaseHistoryError: '',
}

export const initialState = {
  ...initialStateGetPropertyDetails,
  ...purchaseHistoryInitialState,
}

const propertyDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_PROPERTY_DETAILS_STARTED: {
      return {
        ...initialState,
        propertyDetails: {},
        propertyDetailsSuccess: false,
        propertyDetailsStarted: true,
        propertyDetailsIsLoading: true,
        propertyDetailsFailed: false,
        propertyDetailsError: '',
      }
    }
    case GET_PROPERTY_DETAILS_SUCCESS: {
      return {
        ...state,
        propertyDetailsSuccess: true,
        propertyDetails: payload,
        propertyDetailsIsLoading: false,
      }
    }
    case GET_PROPERTY_DETAILS_FAILED: {
      return {
        ...state,
        propertyDetailsFailed: true,
        propertyDetailsError: payload.errorMessage,
        propertyDetailsIsLoading: false,
      }
    }
    case CLEAR_PROPERTY_DETAILS: {
      return {
        ...state,
        ...initialStateGetPropertyDetails,
      }
    }
    case GET_PROPERTY_PURCHASE_HISTORY_STARTED: {
      return {
        ...initialState,
        purchaseHistory: {},
        purchaseHistorySuccess: false,
        purchaseHistoryStarted: true,
        purchaseHistoryIsLoading: true,
        purchaseHistoryFailed: false,
        purchaseHistoryError: '',
      }
    }
    case GET_PROPERTY_PURCHASE_HISTORY_SUCCESS: {
      return {
        ...state,
        purchaseHistorySuccess: true,
        purchaseHistory: payload,
        purchaseHistoryIsLoading: false,
      }
    }
    case GET_PROPERTY_PURCHASE_HISTORY_FAILED: {
      return {
        ...state,
        purchaseHistoryFailed: true,
        purchaseHistoryError: payload.errorMessage,
        purchaseHistoryIsLoading: false,
      }
    }
    case CLEAR_PROPERTY_PURCHASE_HISTORY: {
      return {
        ...state,
        ...purchaseHistoryInitialState,
      }
    }
    case RESET_PROPERTY_DETAILS_TOASTS: {
      return {
        ...state,
        propertyDetailsStarted: false,
        propertyDetailsSuccess: false,
        propertyDetailsIsLoading: false,
        propertyDetailsFailed: false,
        propertyDetailsError: '',
        purchaseHistoryStarted: false,
        purchaseHistorySuccess: false,
        purchaseHistoryIsLoading: false,
        purchaseHistoryFailed: false,
        purchaseHistoryError: '',
      }
    }
    default:
      return state
  }
}

export default propertyDetailsReducer
