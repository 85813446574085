export default class ReduxConnector {
  dispatch

  action

  constructor(dispatch, action) {
    this.dispatch = dispatch
    this.action = action
  }

  /**
   * Function to save the current state of the domain class into redux
   * @param {object} output - Current state of the domain instance class
   */
  save(output) {
    this.dispatch(this.action(output))
  }
}
