import analytics from '../../utils/analytics'
import api from '../../utils/api'
import {
  handleActionCatch,
  validateServerResponse,
} from '../../utils/errorUtils'
import notifyToast from '../../utils/notifyToast'
import {
  GET_ORDERS_LIST_STARTED,
  GET_ORDERS_LIST_SUCCESS,
  GET_ORDERS_LIST_FAILURE,
  CANCEL_ORDER_STARTED,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAILURE,
  CLEAR_CANCEL_ORDERS,
} from '../constants'

export const GetOrdersListStarted = () => ({
  type: GET_ORDERS_LIST_STARTED,
})

export const GetOrdersListSuccess = (payload) => ({
  type: GET_ORDERS_LIST_SUCCESS,
  payload,
})

export const GetOrdersListFailure = (payload) => ({
  type: GET_ORDERS_LIST_FAILURE,
  payload,
})

export const CancelOrderStarted = () => ({
  type: CANCEL_ORDER_STARTED,
})

export const CancelOrderSuccess = (payload) => {
  notifyToast('success', `Order #${payload.orderNumber} deleted`)
  return { type: CANCEL_ORDER_SUCCESS, payload }
}

export const CancelOrderFailure = (payload) => ({
  type: CANCEL_ORDER_FAILURE,
  payload,
})

export const ClearCancelOrders = (payload) => ({
  type: CLEAR_CANCEL_ORDERS,
  payload,
})

const GetOrdersList =
  ({ page = 0, size = 10, token }) =>
  async (dispatch) => {
    dispatch(GetOrdersListStarted())
    try {
      const result = await api.Get(`/orders?page=${page}&size=${size}`, token)
      validateServerResponse(result)
      dispatch(GetOrdersListSuccess(result.data.data))
    } catch (error) {
      handleActionCatch(
        error,
        dispatch,
        GetOrdersListFailure,
        'Get Orders List',
        { page, size, token }
      )
    }
  }

export const CancelOrder =
  ({ token, id, orderNumber }) =>
  async (dispatch) => {
    dispatch(CancelOrderStarted())
    try {
      const result = await api.Post(`/orders/cancel/${id}`, undefined, token)
      validateServerResponse(result)
      dispatch(CancelOrderSuccess({ ...result.data.data, orderNumber }))
      analytics.cancelOrder(id, false)
    } catch (error) {
      handleActionCatch(error, dispatch, CancelOrderFailure, 'Cancel Order', {
        token,
        id,
      })
    }
  }

export default GetOrdersList
