import React from 'react'
import PropTypes from 'prop-types'

function DottedHr({ color }) {
  return <hr className={`custom-dotted-hr ${color}`} />
}
DottedHr.propTypes = {
  color: PropTypes.string,
}

DottedHr.defaultProps = {
  color: 'main',
}
export default DottedHr
