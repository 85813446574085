import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { getCoords } from '../../utils'

const initialPostion = { top: null, left: null }
function Tooptip(props) {
  const { target, open, text, left, right, top, bottom } = props
  const [side, setSite] = useState('right')
  const [position, setPosition] = useState(initialPostion)

  const positionCalculate = (sidePosition, targetPosition, size) => {
    let leftPosition
    let topPosition
    switch (sidePosition) {
      case 'right':
        leftPosition = targetPosition.left + size.width + 10
        topPosition = targetPosition.top
        break
      case 'left':
        leftPosition = targetPosition.left - text.length * 5.5 - size.width - 10
        topPosition = targetPosition.top
        break
      case 'top':
        leftPosition =
          targetPosition.left - (text.length * 6.2) / 2 + size.width / 2
        topPosition = targetPosition.top - size.height - 10
        break
      case 'bottom':
        leftPosition =
          targetPosition.left - (text.length * 6.2) / 2 + size.width / 2
        topPosition = targetPosition.top + size.height + 10
        break
      default:
        leftPosition = targetPosition.left
        topPosition = targetPosition.top
        break
    }
    return { top: topPosition, left: leftPosition, display: 'block' }
  }

  useEffect(() => {
    if (open) {
      const targetPosition = getCoords(target)
      const element = document.getElementById(target)
      const { offsetWidth: width, offsetHeight: height } = element
      const tooltipPosition = positionCalculate(side, targetPosition, {
        width,
        height,
      })
      setPosition(tooltipPosition)
    } else {
      setPosition(initialPostion)
    }
  }, [target, open, side])

  useEffect(() => {
    if (top) {
      setSite('top')
    }
    if (bottom) {
      setSite('bottom')
    }
    if (left) {
      setSite('left')
    }
    if (right) {
      setSite('right')
    }
  }, [top, bottom, right, left])

  return (
    <div
      id={`${target}Tooltip`}
      style={position}
      className={`tooltip-component ${open ? 'open' : ''}`}
    >
      <div
        className={`tooltip-component__content 
        ${left ? 'left' : ''}
        ${right ? 'right' : ''}
        ${top ? 'top' : ''}
        ${bottom ? 'bottom' : ''}
      `}
      >
        {text}
      </div>
    </div>
  )
}

Tooptip.propTypes = {
  target: PropTypes.string.isRequired,
  text: PropTypes.string,
  open: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  top: PropTypes.bool,
  bottom: PropTypes.bool,
}

Tooptip.defaultProps = {
  text: '',
  open: false,
  left: false,
  right: false,
  top: false,
  bottom: false,
}

export default Tooptip
