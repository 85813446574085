import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import MobileCards from '../../containers/MobileCards'
import DottedHr from '../../components/Separators/DottedHr'
import { roundToDecimals } from '../../utils/productUtils'

function MobileOrderList({ navigation, orders }) {
  return orders.map((order) => (
    <MobileCards
      key={order.id}
      action={() => navigation(order.externalOrderId)}
    >
      <div className="mobile-card__order-number">
        <h5 className="order-title">Order Number:</h5>
        <p>{order.orderNumber}</p>
      </div>
      <DottedHr />
      <div className="mobile-card__info">
        <div className="mobile-card__info-name">
          <h5>Status:</h5>
          <h5>Order Date:</h5>
          <h5>Total:</h5>
        </div>
        <div className="mobile-card__info-value">
          <p>{order.fulfillmentStatus}</p>
          <p>
            {order.createdAt
              ? format(new Date(order.createdAt), 'yyyy-MM-dd')
              : null}
          </p>
          <p>{`$${roundToDecimals(order.totalPrice)}`}</p>
        </div>
      </div>
    </MobileCards>
  ))
}

MobileOrderList.propTypes = {
  navigation: PropTypes.func.isRequired,
  orders: PropTypes.object.isRequired,
}

export default MobileOrderList
