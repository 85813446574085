/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'

function RadioBtn({ text, id, checked, onChange, name }) {
  return (
    <div className={`form-check radio-btn ${id}`}>
      <input
        className="form-check-input"
        type="radio"
        name={name}
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor={id}>
        {text}
      </label>
    </div>
  )
}

RadioBtn.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
}

RadioBtn.defaultProps = {
  text: '',
  checked: false,
  onChange: () => {},
  name: '',
}

export default RadioBtn
