import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CartProductRow from './CartProductRow'
import Button from '../../components/Buttons/Button'
import SelectComponent from '../../components/SelectComponent'
import CartTotalsSummary from './CartTotalsSummary'
import Coupons from './Coupons'
import Hr from '../../components/Separators/Hr'
import ErrorAlert from '../../components/ErrorAlert'
import Loader from '../../components/Loader'
import Icon from '../../components/Icon'

import CartActions from '../../redux/actions/CartActions'
import {
  DeleteProductGuestCart,
  UpdateProductGuestCart,
  ResetGuestCart,
} from '../../redux/actions/GuestCartActions'
import { calculateCartTotals, roundToDecimals } from '../../utils/productUtils'
import useHandleMobile from '../../hooks/useHandleMobile'
import GetPropertyList from '../../redux/actions/PropertyListActions'
import OrderActions from '../../redux/actions/OrderActions'

import { extractSessionInfo, setPageTitleAndUserInfo } from '../../utils'
import useGuest from '../../hooks/useGuest'
import NoResults from '../../components/NoResults/NoResults'

const { GetShoppingCart, DeleteCartItem, UpdateCartItemQuantity } = CartActions

const getTotalAmount = (productList) =>
  roundToDecimals(
    productList.reduce((acum, curr) => acum + curr.price * curr.quantity, 0)
  )

const Cart = (props) => {
  const {
    session,
    cartProducts,
    getCartIsLoading,
    getCartError,
    deleteCartProductIsLoading,
    deleteCartProductError,
    updateCartProductQuantityError,
    propertyList,
    checkoutOrder,
    isCheckoutLoading,
    guestCartProduct,
  } = props
  const { isGuest, propertyId, propertyName, baseRoute } = useGuest()
  const { mobile } = useHandleMobile()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { userId, userName, userEmail, jwt } = extractSessionInfo(session)

  useEffect(
    () =>
      setPageTitleAndUserInfo(
        `Shopping Cart${isGuest ? ' (Guest)' : ''}`,
        userId,
        userName,
        userEmail
      ),
    []
  )

  const [list, setList] = useState({})
  const [propertySelected, setPropertySelected] = useState(null)
  const [startedCheckout, setStartedCheckout] = useState(false)
  // eslint-disable-next-line consistent-return
  const productsToRender = () => {
    if (isGuest && !jwt) {
      return guestCartProduct
    }
    if (!isGuest || jwt) {
      return cartProducts
    }
  }
  useEffect(() => {
    if (!isGuest || jwt) dispatch(GetShoppingCart({ token: jwt }))
    if (!Object.values(list).length >= 1 && !isGuest) {
      dispatch(GetPropertyList({ token: jwt }))
    }
  }, [])

  useEffect(() => {
    if (Object.values(propertyList).length >= 1) {
      setList(propertyList)
    }
  }, [propertyList])

  const { subtotal, shipping, taxes, savings } = calculateCartTotals(
    productsToRender()
  )

  const isLoading = getCartIsLoading || deleteCartProductIsLoading
  const error =
    getCartError || deleteCartProductError || updateCartProductQuantityError

  useEffect(() => {
    if (
      checkoutOrder &&
      (checkoutOrder.webCheckoutUrl || checkoutOrder.webUrl) &&
      startedCheckout
    ) {
      window.location.href = !isGuest
        ? checkoutOrder.webCheckoutUrl
        : checkoutOrder.webUrl
    }
  }, [checkoutOrder])

  useEffect(() => {
    if (propertySelected === '*') {
      navigate('/property/new')
    }
  }, [propertySelected])

  const goToCheckout = () => {
    setStartedCheckout(true)
    if (!isGuest) {
      const {
        zipCode,
        address,
        secondAddress,
        city,
        state,
        id,
        nickName: propertyNickname,
      } = list.elements[propertySelected]
      dispatch(
        OrderActions.StartUserCheckout({
          shippingAddress: {
            zipCode,
            address,
            additionalInfo: secondAddress,
            city,
            state,
          },
          token: jwt,
          id,
          totalAmount: getTotalAmount(cartProducts),
          propertyNickname,
          propertyUrl: `${baseRoute}/product/list`,
          userEmail,
        })
      )
    } else {
      const preToCheckout = []
      if (!jwt) {
        productsToRender().forEach((product) => {
          preToCheckout.push({
            externalProductId: product.id,
            variantId: product.variantId,
            quantity: product.quantity,
          })
        })
      }
      const productsToCheckout = {
        propertyId,
        productsToCheckout: preToCheckout,
      }

      dispatch(
        OrderActions.StartUserCheckout({
          id: 'guest',
          products: productsToCheckout,
          token: jwt,
          totalAmount: getTotalAmount(guestCartProduct),
          propertyNickname: propertyName,
          propertyId,
          propertyUrl: `${baseRoute}/product/list`,
          userEmail,
        })
      )
      dispatch(ResetGuestCart())
    }
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function Summary() {
    return (
      <div className="col-12 col-xl-3 cart-summary_cart-container">
        <CartTotalsSummary
          product={{
            subtotal,
            shipping,
            taxes,
            savings,
          }}
        />
        <Coupons />
      </div>
    )
  }

  const handlePropertySelected = ({ value }) => setPropertySelected(value)

  const handleOptions = () => {
    const options = []
    if (Object.values(list).length >= 1) {
      const { elements } = list
      elements.map((item, idx) =>
        options.push({
          keyx: idx,
          text: item.nickName,
        })
      )
    }
    options.push({
      keyx: '*',
      text: 'Add property',
    })
    return options
  }

  const shouldDisableButton = () => {
    if ((!propertySelected && !isGuest) || !productsToRender().length) {
      return true
    }
    if (isGuest && !productsToRender().length) {
      return true
    }
    return false
  }

  const actionButtons = () => (
    <div className="cart-buttons">
      <Button
        id="continueShopping"
        action={() => navigate(`${baseRoute}/product/list`)}
        text="Continue Shopping"
        secondary
      />
      <Button
        id="checkout"
        disabled={shouldDisableButton()}
        action={() => goToCheckout()}
        text="Checkout"
      />
    </div>
  )

  const handleDeleteProduct = (variantToDelete) => {
    if (!isGuest || jwt)
      dispatch(
        DeleteCartItem({
          ...variantToDelete,
          token: jwt,
          isGuest,
          propertyUrl: `${baseRoute}/product/list`,
        })
      )
    if (isGuest && !jwt) {
      dispatch(
        DeleteProductGuestCart({
          ...variantToDelete,
          propertyUrl: `${baseRoute}/product/list`,
        })
      )
    }
  }

  const handleUpdateQuantity = (option, product, newQuantity) => {
    if (isGuest && !jwt) {
      const productNewQuantity = productsToRender()
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < productNewQuantity.length; i++) {
        const target = productNewQuantity[i]
        if (target.variantId === product.variantId) {
          if (option === 'change' && (newQuantity || newQuantity === '')) {
            target.quantity = newQuantity
          }
          if (option === 'decrement') {
            target.quantity = product.quantity - 1
          }
          if (option === 'increment') {
            target.quantity = product.quantity + 1
          }
          dispatch(UpdateProductGuestCart(productNewQuantity))
        }
      }
    }
    if (!isGuest || jwt) {
      if (option === 'change' && (newQuantity || newQuantity === '')) {
        dispatch(
          UpdateCartItemQuantity({
            ...product,
            token: jwt,
            quantity: newQuantity,
          })
        )
      }
      if (option === 'decrement') {
        dispatch(
          UpdateCartItemQuantity({
            ...product,
            token: jwt,
            quantity: product.quantity - 1,
          })
        )
      }
      if (option === 'increment') {
        dispatch(
          UpdateCartItemQuantity({
            ...product,
            token: jwt,
            quantity: product.quantity + 1,
          })
        )
      }
    }
  }

  return (
    <section className="cart-container container-fluid">
      <Loader show={isLoading || isCheckoutLoading} />
      {error !== '' && <ErrorAlert error={error} />}
      <div className={isGuest ? 'row center-guest' : 'row'}>
        <div
          className={
            isGuest
              ? 'col-12 col-xl-9 cart-products-section center-col'
              : 'col-12 col-xl-9 cart-products-section'
          }
        >
          <div className="cart-products-container">
            <div className="title-container">
              <h1 className="section-title">My Cart</h1>
            </div>
            <Hr />
            {productsToRender().length === 0 && (
              <div className="no-response__container">
                <NoResults mainText="Your shopping cart is empty" />
              </div>
            )}
            <div className="product-rows">
              {productsToRender().map((product, i) => (
                <CartProductRow
                  product={product}
                  mobile={mobile}
                  altBackground={i % 2 === 1}
                  onDelete={(variantToDelete) =>
                    handleDeleteProduct(variantToDelete)
                  }
                  onQuantityChange={(newQuantity) =>
                    handleUpdateQuantity('change', product, newQuantity)
                  }
                  onQuantityDecrement={() =>
                    handleUpdateQuantity('decrement', product, null)
                  }
                  onQuantityIncrement={() =>
                    handleUpdateQuantity('increment', product, null)
                  }
                />
              ))}
            </div>
            {isGuest && (
              <div className="subtotal-guest">
                Subtotal<p> ${subtotal}</p>
              </div>
            )}
          </div>
          {!isGuest && <Hr />}
          {mobile && !isGuest && Summary()}
          {!isGuest && <Hr />}
          {!isGuest && (
            <div className="cart-container__select-property">
              <div className="cart-container__info-section">
                <Icon item="info-circle" width="25px" height="25px" />
                <p className="select-property-info">
                  In order to properly map these products to the correct
                  property, please indicate where these products will reside.
                  This does not determine shipping address. You can change where
                  to ship these products on the next screen.
                </p>
              </div>
              <div className="cart-container__select-section">
                <p className="select-property_text">
                  {' '}
                  Select the property for your products
                </p>
                <SelectComponent
                  id="cart-property"
                  name="property"
                  onChange={({ target }) => handlePropertySelected(target)}
                  options={handleOptions()}
                />
              </div>
            </div>
          )}
          {mobile && actionButtons()}
        </div>
        {!mobile && !isGuest && Summary()}
      </div>
      {!mobile && actionButtons()}
    </section>
  )
}

Cart.propTypes = {
  session: PropTypes.object.isRequired,
  cartProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  propertyList: PropTypes.object.isRequired,
  getCartIsLoading: PropTypes.bool.isRequired,
  isCheckoutLoading: PropTypes.bool.isRequired,
  getCartError: PropTypes.string.isRequired,
  deleteCartProductIsLoading: PropTypes.bool.isRequired,
  deleteCartProductError: PropTypes.string.isRequired,
  updateCartProductQuantityError: PropTypes.string.isRequired,
  checkoutOrder: PropTypes.shape(PropTypes.any).isRequired,
  guestCartProduct: PropTypes.arrayOf(PropTypes.shape(PropTypes.any))
    .isRequired,
}

const mapStateToProps = ({
  login,
  cart: {
    cartProducts,
    getCartIsLoading,
    getCartError,
    deleteCartProductIsLoading,
    deleteCartProductError,
    updateCartProductQuantityError,
  },
  propertyList,
  guestCart,
  order: { checkoutOrder, startCheckoutIsLoading: isCheckoutLoading },
}) => ({
  session: login.login,
  cartProducts,
  guestCartProduct: guestCart.cartProducts,
  propertyList: propertyList.propertyList,
  getCartIsLoading,
  getCartError,
  deleteCartProductIsLoading,
  deleteCartProductError,
  updateCartProductQuantityError,
  checkoutOrder,
  isCheckoutLoading,
})

export default connect(mapStateToProps)(Cart)
