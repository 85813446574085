import GA4React from 'ga-4-react'
import logMessage, { LogLevels } from './logMessage'
import { getTotalAmount } from './productUtils'

const { REACT_APP_ENVIRONMENT = 'localhost' } = process.env

const waitTimeBeforeGAInitMs = 1000
const waitTimeBeforeRetryMs = 500
const maxRetries = 5
const gaMeasurementId = 'G-Y1JSEPHMCJ'

let ga4React

const currency = 'USD'
const itemListId = (isGuest) => `${isGuest ? 'guest' : 'host'}_list`
const itemListName = (isGuest) => `${isGuest ? 'Guest' : 'Host'} Products`

const Labels = Object.freeze({
  // Default events:
  SIGN_UP: 'sign_up',
  LOGIN: 'login',
  SEARCH: 'search',
  ADD_TO_CART: 'add_to_cart',
  ADD_TO_WISHLIST: 'add_to_wishlist',
  BEGIN_CHECKOUT: 'begin_checkout',
  REMOVE_FROM_CART: 'remove_from_cart',
  VIEW_CART: 'view_cart',
  VIEW_ITEM: 'view_item',
  VIEW_ITEM_LIST: 'view_item_list',
  PAGE_VIEW: 'page_view',

  // Custom events:
  FILTER_BY_VENDOR: 'filter_by_vendor',
  FILTER_BY_CATEGORY: 'filter_by_category',
  ADD_PROPERTY: 'add_property',
  RATE_PRODUCT: 'rate_product',
  CANCEL_ORDER: 'cancel_order',
  REDIRECT_TO_CHECKOUT: 'redirect_to_checkout',

  // Custom guest events:
  VIEW_GUEST_CATALOG: 'view_guest_catalog',
  ADD_TO_CART_GUEST: 'add_to_cart_guest',
  REMOVE_FROM_CART_GUEST: 'remove_from_cart_guest',
  REDIRECT_TO_CHECKOUT_GUEST: 'redirect_to_checkout_guest',
  VIEW_PRODUCT_DETAIL_GUEST: 'view_product_detail_guest',
  ADD_TO_SAVED_PRODUCTS_GUEST: 'add_to_saved_products_guest',
})

const initializeGA4 = () => {
  try {
    setTimeout(() => {
      ga4React = new GA4React(gaMeasurementId, {
        debug_mode: false, // TODO: change back to false
        send_page_view: false, // Manually sent to be more accurate with react-routing
      })
      ga4React
        .initialize()
        .catch((err) =>
          logMessage('Error in library initializing ga4React', err)
        )
    }, waitTimeBeforeGAInitMs)
  } catch (ex) {
    logMessage(
      'Error in function catch initializing ga4React',
      ex,
      LogLevels.ERROR
    )
  }
}

const labelIsValid = (label) => {
  const exists = Object.values(Labels).find(
    (labelValue) => labelValue === label
  )

  return !!exists
}

const gtag = (props, currentAttemptNumber = 0) => {
  const retryOperation = () => {
    // retries the gtag operation after a wait time if max retries is not exceeded
    // multiplicative backoff
    if (currentAttemptNumber < maxRetries)
      setTimeout(() => {
        gtag(props, currentAttemptNumber + 1)
      }, Math.round(waitTimeBeforeRetryMs * (currentAttemptNumber + 1)))
  }

  try {
    if (REACT_APP_ENVIRONMENT !== 'production') {
      return
    }

    if (!ga4React || !ga4React.gtag) {
      // Not initialized yet, try to make another attempt after waiting specified time
      retryOperation()

      logMessage(
        'ga4React not initialized',
        {
          ga4React,
          currentAttemptNumber,
        },
        LogLevels.ERROR
      )
      return
    }

    ga4React.gtag(...props)
  } catch (ex) {
    retryOperation()
    logMessage(
      'Error trying to send gtag to ga4',
      {
        ex,
        props,
        currentAttemptNumber,
      },
      LogLevels.ERROR
    )
  }
}

const sendConfig = (config) => gtag(['config', gaMeasurementId, config])

const sendEvent = (label, props) => {
  if (!labelIsValid(label)) {
    logMessage('Invalid analytics event label', label, LogLevels.ERROR)
    return
  }

  gtag(['event', label, props])
}

/**
 * Helper method to set the page title dynamically in the ga4 event
 * @param {string} pageTitle - Title of the page to add to the ga4 event
 */
const setPageTitle = (pageTitle) => sendEvent(Labels.PAGE_VIEW, pageTitle)

const addToCart = (
  price,
  externalProductId,
  title,
  quantity,
  productOptionsJSON,
  isGuest,
  variantId
) =>
  sendEvent(Labels.ADD_TO_CART, {
    currency,
    value: price,
    is_guest: isGuest,
    variant_id: variantId,
    total_amount: getTotalAmount(price, quantity),
    items: [
      {
        item_id: externalProductId,
        item_name: title,
        quantity,
        currency,
        price,
        item_list_id: itemListId(isGuest),
        item_list_name: itemListName(isGuest),
        item_variant: productOptionsJSON,
      },
    ],
  })

const removeFromCart = (
  price,
  externalProductId,
  title,
  quantity,
  productOptionsJSON,
  isGuest,
  variantId
) =>
  sendEvent(Labels.REMOVE_FROM_CART, {
    currency,
    value: price,
    is_guest: isGuest,
    product_id: externalProductId,
    variant_id: variantId,
    total_amount: getTotalAmount(price, quantity),
    items: [
      {
        item_id: externalProductId,
        item_name: title,
        currency,
        item_list_id: itemListId(isGuest),
        itemListName: itemListName(isGuest),
        item_variant: productOptionsJSON,
        price,
        quantity,
      },
    ],
  })

const signUp = (method, isGuest, firstName, lastName, email) =>
  sendEvent(Labels.SIGN_UP, {
    is_guest: isGuest,
    method,
    first_name: firstName,
    last_name: lastName,
    email,
  })

const filterByKeyword = (keyword, isGuest) =>
  sendEvent(Labels.SEARCH, {
    search_term: keyword,
    is_guest: isGuest,
  })

const filterByVendor = (vendor, isGuest) =>
  sendEvent(Labels.FILTER_BY_VENDOR, {
    product_vendor: vendor,
    is_guest: isGuest,
  })

const filterByCategory = (category, isGuest) =>
  sendEvent(Labels.FILTER_BY_CATEGORY, {
    product_category: category,
    is_guest: isGuest,
  })

const addProperty = (nickname, address, state, city) =>
  sendEvent(Labels.ADD_PROPERTY, {
    property_nickname: nickname,
    property_address: address,
    property_state: state,
    property_city: city,
  })

// (TODO): Finish this event
// const redirectToCheckout = ()

const rateProduct = (score, externalProductId, vendor, sku) =>
  sendEvent(Labels.RATE_PRODUCT, {
    product_id: externalProductId,
    rating_score: score,
    product_vendor: vendor,
    product_sku: sku,
  })

const cancelOrder = (id, isGuest) =>
  sendEvent(Labels.CANCEL_ORDER, {
    order_id: id,
    is_guest: isGuest,
  })

const redirectToCheckout = (
  propertyId,
  propertyNickname,
  propertyState,
  propertyCity,
  propertyAddress,
  totalAmount,
  isGuest
) =>
  sendEvent(Labels.REDIRECT_TO_CHECKOUT, {
    property_id: propertyId,
    property_nickname: propertyNickname,
    property_state: propertyState,
    property_city: propertyCity,
    property_address: propertyAddress,
    total_amount: totalAmount,
    is_guest: isGuest,
  })

const addUserInfo = (userId, name, email) =>
  sendConfig({
    user_id: userId,
    user_name: name,
    user_email: email,
  })

const viewGuestCatalog = (propertyUrl, propertyId, propertyName) =>
  sendEvent(Labels.VIEW_GUEST_CATALOG, {
    property_url: propertyUrl,
    property_id: propertyId,
    property_nickname: propertyName,
  })

const addToCartGuest = (
  price,
  externalProductId,
  title,
  quantity,
  productOptionsJSON,
  isGuest,
  variantId,
  propertyUrl
) =>
  sendEvent(Labels.ADD_TO_CART_GUEST, {
    currency,
    value: price,
    is_guest: isGuest,
    variant_id: variantId,
    total_amount: getTotalAmount(price, quantity),
    items: [
      {
        item_id: externalProductId,
        item_name: title,
        quantity,
        currency,
        price,
        item_list_id: itemListId(isGuest),
        item_list_name: itemListName(isGuest),
        item_variant: productOptionsJSON,
      },
    ],
    property_url: propertyUrl,
  })

const removeFromCartGuest = (
  price,
  externalProductId,
  title,
  quantity,
  productOptionsJSON,
  isGuest,
  variantId,
  propertyUrl
) =>
  sendEvent(Labels.REMOVE_FROM_CART_GUEST, {
    currency,
    value: price,
    is_guest: isGuest,
    product_id: externalProductId,
    variant_id: variantId,
    total_amount: getTotalAmount(price, quantity),
    items: [
      {
        item_id: externalProductId,
        item_name: title,
        currency,
        item_list_id: itemListId(isGuest),
        itemListName: itemListName(isGuest),
        item_variant: productOptionsJSON,
        price,
        quantity,
      },
    ],
    property_url: propertyUrl,
  })

const redirectToCheckoutGuest = (
  propertyId,
  propertyNickname,
  totalAmount,
  isGuest,
  propertyUrl,
  email
) =>
  sendEvent(Labels.REDIRECT_TO_CHECKOUT_GUEST, {
    property_id: propertyId,
    property_nickname: propertyNickname,
    total_amount: totalAmount,
    is_guest: isGuest,
    property_url: propertyUrl,
    email,
  })

const viewProductDetailGuest = (propertyUrl, productId, title) =>
  sendEvent(Labels.VIEW_PRODUCT_DETAIL_GUEST, {
    property_url: propertyUrl,
    product_id: productId,
    product_name: title,
  })

const addToSavedProductsGuest = (propertyUrl, productId, title) =>
  sendEvent(Labels.ADD_TO_SAVED_PRODUCTS_GUEST, {
    property_url: propertyUrl,
    product_id: productId,
    product_name: title,
  })

export default {
  signUp,
  addToCart,
  addUserInfo,
  addProperty,
  rateProduct,
  cancelOrder,
  setPageTitle,
  initializeGA4,
  removeFromCart,
  filterByVendor,
  filterByKeyword,
  filterByCategory,
  redirectToCheckout,
  viewGuestCatalog,
  addToCartGuest,
  removeFromCartGuest,
  redirectToCheckoutGuest,
  viewProductDetailGuest,
  addToSavedProductsGuest,
}
