import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../../components/Buttons/Button'

function OptionsSelector(props) {
  const { productOptions, onOptionChosen } = props

  const shouldBeDisabled = (idx, quantity, index) => {
    if (!(quantity.length === 0)) {
      if (quantity[idx] === 0) {
        if (productOptions.length === 3 && index === 1) {
          return false
        }
        return true
      }
      return false
    }
    return false
  }

  return (
    <div className="product-options">
      {productOptions.map(
        ({ name, values, chosenValue, quantity = [] }, index) => (
          <div className="option-container">
            <span>Select {name}:</span>
            <div className="option-values">
              {values.map((value, idx) => (
                <Button
                  id={`product-option-value-${value}`}
                  text={value}
                  action={() => onOptionChosen(name, value)}
                  secondary={value === chosenValue}
                  tertiary={value !== chosenValue}
                  className={
                    shouldBeDisabled(idx, quantity, index) ? 'out-of-stock' : ''
                  }
                />
              ))}
            </div>
          </div>
        )
      )}
    </div>
  )
}

OptionsSelector.propTypes = {
  productOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
      chosenValue: PropTypes.bool,
      quantity: PropTypes.number,
    })
  ).isRequired,
  onOptionChosen: PropTypes.func.isRequired,
}

export default OptionsSelector
