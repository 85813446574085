export const hostItems = [
  {
    item: 'Terms & conditions',
    link: 'https://spaceplaceapp.com/pages/terms.html',
  },
  {
    item: 'Privacy policy',
    link: 'https://spaceplaceapp.com/pages/privacy.html',
  },
  {
    item: 'Return & Shipment policies',
    link: 'https://spaceplaceapp.com/pages/return_policy.html',
  },
]

export const guestItems = [
  {
    item: 'Terms of service',
    link: 'https://spaceplaceapp.com/pages/guest_terms.html',
  },
  {
    item: 'Return & Shipment policies',
    link: 'https://spaceplaceapp.com/pages/return_policy.html',
  },
]
