import { useSearchParams } from 'react-router-dom'

const urlFilterKeys = ['tags', 'vendor', 'categories', 'search']

/**
 * Helper hook function to encapsulate the url params filters logic
 * @param {ProductFiltersReduxConnector} productFilters - Current product filters
 */
function useSetUrlFilters(productFilters) {
  const [searchParams, setSearchParams] = useSearchParams()

  const obtainFiltersFromUrl = () => {
    let urlFilters = {}

    urlFilterKeys.forEach((filterKey) => {
      let defaultFilterValue = []
      let modifiedFilterKey = filterKey
      if (filterKey === 'search') defaultFilterValue = ''
      if (filterKey === 'vendor') modifiedFilterKey = 'vendors'

      urlFilters = {
        ...urlFilters,
        [modifiedFilterKey]: searchParams.has(filterKey)
          ? searchParams.get(filterKey).split(',')
          : defaultFilterValue,
      }
    })
    return urlFilters
  }

  if (urlFilterKeys.some((filterKey) => searchParams.has(filterKey))) {
    const urlFilters = obtainFiltersFromUrl()
    productFilters.updateFiltersFromUrl(urlFilters)
    setSearchParams()
  }
}

export default useSetUrlFilters
