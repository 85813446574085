/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import LoaderComponent from '../../components/Loader'
import GetPropertyDetails from '../../redux/actions/PropertyDetailsAction'
import {
  EditProperty as EditPropertyAction,
  ClearEditProperty,
} from '../../redux/actions/EditPropertyActions'
import ErrorAlert from '../../components/ErrorAlert'
import Button from '../../components/Buttons/Button'
import InputComponent from '../../components/Inputs/InputComponent'
import MobileEdit from '../../containers/MobileEdit'
import SelectComponent from '../../components/SelectComponent'

import useHandleMobile from '../../hooks/useHandleMobile'
import {
  states,
  cities,
  getState,
  getStateCode,
} from '../../utils/countryStateCity'
import { validateLink } from '../../utils/fieldValidator'
import { extractSessionInfo, setPageTitleAndUserInfo } from '../../utils'

function EditProperty(props) {
  const {
    session,
    propertyDetails,
    editProperty,
    isLoading,
    isError,
    propertyDetailsError,
  } = props
  const { mobile } = useHandleMobile()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { userId, userName, userEmail, jwt } = extractSessionInfo(session)

  useEffect(
    () => setPageTitleAndUserInfo('Edit Property', userId, userName, userEmail),
    []
  )

  const [selectCity, setSelectCity] = useState(false)
  const [details, setDetails] = useState(null)
  const [fields, setFields] = useState({
    nickname: '',
    zipCode: '',
    address: '',
    listingLink: '',
  })
  const [propertyId, setPropertyId] = useState(0)
  const [invalidLink, setInvalidLink] = useState(false)
  const [invalidCalendar, setInvalidCalendar] = useState(false)

  const handleCancel = () => {
    dispatch(ClearEditProperty())
    navigate('/property/list')
  }

  useEffect(() => {
    const id = location.pathname.split('/')[2]
    setPropertyId(id)
    if (!details?.city && userId) {
      dispatch(
        GetPropertyDetails({
          id,
          token: jwt,
        })
      )
    }
  }, [])

  useEffect(() => {
    const { listingLink } = fields
    setInvalidLink(
      listingLink?.length >= 1 ? !validateLink(listingLink) : false
    )
    if (fields?.calendarLink) {
      setInvalidCalendar(
        fields?.calendarLink.length >= 1
          ? !validateLink(fields.calendarLink)
          : false
      )
    } else {
      setInvalidCalendar(false)
    }
  }, [fields])

  useEffect(() => {
    if (isError) navigate('/property/list')
  }, [isError])

  useEffect(() => {
    if (propertyDetails) {
      setDetails(propertyDetails)
    }
  }, [propertyDetails])

  useEffect(() => {
    if (editProperty.editPropertySuccess) {
      handleCancel()
    }
  }, [editProperty])

  useEffect(() => {
    if (
      details !== undefined &&
      details !== null &&
      Object.values(details).length >= 1
    ) {
      const optionalAddres =
        details?.secondAddress === 'optional' ? '' : details?.secondAddress
      setFields({
        nickname: details?.nickName,
        zipCode: details?.zipCode,
        address: details?.address,
        secondAddress: optionalAddres,
        listingLink: details?.listingLink,
        calendarLink: details?.calendarLink,
        state: getStateCode(details?.state),
        city: details?.city,
      })
    }
  }, [details])

  const handleChange = ({ name, value }) =>
    setFields({ ...fields, [name]: value })

  const handleSave = () => {
    const {
      nickname,
      address,
      state,
      city,
      zipCode,
      calendarLink,
      listingLink,
      secondAddress,
    } = fields
    if (
      [nickname, listingLink, zipCode, address]?.some(
        (item) => item.length >= 1
      ) &&
      fields?.state &&
      fields?.city
    ) {
      const newState = state ? getState(state) : state
      dispatch(
        EditPropertyAction(
          {
            id: propertyId,
            nickName: nickname,
            address,
            state: newState,
            secondAddress: secondAddress || '',
            city,
            zipCode,
            calendarLink,
            listingLink,
          },
          jwt
        )
      )
    }
  }

  const shouldDisableButton = () => {
    const { nickname, address, zipCode, listingLink } = fields
    if (
      [nickname, listingLink, zipCode, address]?.some(
        (item) => item.length < 1
      ) ||
      !fields?.state ||
      !fields?.city
    ) {
      return true
    }
    return false
  }

  return (
    <section className="edit-property">
      <LoaderComponent show={isLoading} text="Please wait..." />
      {propertyDetailsError && <ErrorAlert error={propertyDetailsError} />}
      {!!details && fields?.state && (
        <>
          <span className="edit-property__back-link">
            <Link to="/property/list">{'<- BACK'}</Link>
          </span>
          <div className="edit-property__info-container">
            <h4>Details</h4>
            {!mobile && (
              <article className="edit-property__info">
                <div className="details-info">
                  <div>
                    <h5>Property nickname:</h5>
                    <span>{details?.nickName}</span>
                    <h5>
                      <p>*</p>City:
                    </h5>
                    <div className="edit-property__state-city-input">
                      <Dropdown isOpen={selectCity}>
                        <DropdownToggle nav>
                          <InputComponent
                            id="city"
                            name="city"
                            labelText=""
                            isMandatory
                            value={fields.city}
                            onChange={({ target }) => handleChange(target)}
                            onFocus={() => setSelectCity(true)}
                            outlined
                            className="col-12 col-sm-6"
                            autoComplete={false}
                          />
                        </DropdownToggle>
                        <DropdownMenu>
                          {cities()
                            .filter((city) =>
                              city.text.toLowerCase().startsWith(fields.city)
                            )
                            .map((city) => (
                              <DropdownItem
                                onClick={() => {
                                  setFields({ ...fields, city: city.text })
                                  setSelectCity(false)
                                }}
                              >
                                {city.text}
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                  <div>
                    <h5>
                      <p>*</p>Shipping address:
                    </h5>
                    <InputComponent
                      name="address"
                      id="address"
                      outlined
                      value={fields?.address}
                      onChange={({ target }) => handleChange(target)}
                    />
                    <h5>
                      <p>*</p>State:
                    </h5>
                    <SelectComponent
                      id="state"
                      name="state"
                      value={fields.state}
                      options={states()}
                      onChange={({ target }) => handleChange(target)}
                    />
                  </div>
                  <div>
                    <h5>Apartment/Floor/Other:</h5>
                    <InputComponent
                      name="secondAddress"
                      id="secondAddress"
                      outlined
                      value={fields?.secondAddress}
                      onChange={({ target }) => handleChange(target)}
                    />
                    <h5>
                      <p>*</p>Zip code:
                    </h5>
                    <InputComponent
                      outlined
                      name="zipCode"
                      id="zipCode"
                      value={fields?.zipCode}
                      onChange={({ target }) => handleChange(target)}
                    />
                  </div>
                  <div>
                    <h5>
                      <p>*</p>Listing link:
                    </h5>
                    <InputComponent
                      name="listingLink"
                      id="listingLink"
                      outlined
                      value={fields?.listingLink}
                      invalid={invalidLink}
                      onChange={({ target }) => handleChange(target)}
                    />
                    <h5>Calendar link:</h5>
                    <InputComponent
                      name="calendarLink"
                      id="calendarLink"
                      outlined
                      value={fields?.calendarLink}
                      invalid={invalidCalendar}
                      onChange={({ target }) => handleChange(target)}
                    />
                  </div>
                </div>
              </article>
            )}
            {mobile && (
              <MobileEdit
                details={details}
                fields={fields}
                setFields={setFields}
                handleChange={(target) => handleChange(target)}
              />
            )}
            {fields.nickname && (
              <div className="edit-property__buttons">
                <Button
                  id="cancel"
                  secondary
                  text={mobile ? 'Cancel' : 'Discard Changes'}
                  action={() => handleCancel()}
                />
                <Button
                  id="save"
                  text="Save"
                  disabled={shouldDisableButton()}
                  action={() => handleSave()}
                />
              </div>
            )}
          </div>
        </>
      )}
    </section>
  )
}

EditProperty.propTypes = {
  session: PropTypes.object.isRequired,
  propertyDetails: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  editProperty: PropTypes.object.isRequired,
  isError: PropTypes.bool.isRequired,
  propertyDetailsError: PropTypes.string.isRequired,
}

function mapStateToProps({ login, propertyDetails, editProperty }) {
  return {
    session: login.login,
    propertyDetails: propertyDetails.propertyDetails,
    isLoading:
      propertyDetails.propertyDetailsIsLoading ||
      editProperty.editPropertyIsLoading,
    editProperty,
    isError: propertyDetails.propertyDetailsFailed,
    propertyDetailsError: propertyDetails.propertyDetailsError,
  }
}

export default connect(mapStateToProps)(EditProperty)
