import toast from 'react-hot-toast'

function notifyToast(type, text, time = 3500) {
  const toastType = {
    success: 'success',
    error: 'error',
  }
  let toastText = text
  if (typeof toastText !== 'string') {
    if (text instanceof Error) toastText = text.message
    else if (text?.toString) toastText = text.toString()
    else toastText = JSON.stringify(toastText)
  }
  return toast[toastType[type]](toastText, { duration: time })
}

export default notifyToast
