/* eslint default-param-last: off */
import {
  GET_ORDER_DETAILS_STARTED,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILURE,
} from '../constants'

export const initialState = {
  orderDetails: {},
  orderDetailsSuccess: false,
  orderDetailsIsLoading: false,
  orderDetailsFailed: false,
  orderDetailsError: '',
}

const orderDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_ORDER_DETAILS_STARTED: {
      return {
        ...initialState,
        orderDetailsIsLoading: true,
      }
    }
    case GET_ORDER_DETAILS_SUCCESS: {
      return {
        ...state,
        orderDetailsIsLoading: false,
        orderDetailsSuccess: true,
        orderDetails: payload,
      }
    }
    case GET_ORDER_DETAILS_FAILURE: {
      return {
        ...state,
        orderDetailsIsLoading: false,
        orderDetailsFailed: true,
        orderDetailsError: payload.errorMessage,
      }
    }

    default:
      return state
  }
}

export default orderDetailsReducer
