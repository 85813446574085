const defaultCopy = {
  addProperty: {
    modal: {
      airbnb: [
        'Select the listing you want',
        'Go to pricing and availability > Calendar sync',
        'Select export calendar',
        'Copy the link',
        'Exit',
      ],
      vrbo: [
        'Select the listing you want',
        'Select calendar',
        'Select import & export',
        'Export calendar',
        'Copy the link',
      ],
    },
  },
  headersNoAuth: [
    {
      text: 'Home',
      url: 'https://spaceplaceapp.com/#',
    },
    {
      text: 'Products',
      url: 'https://spaceplaceapp.com/#products',
    },
    {
      text: 'Our Brands',
      url: 'https://spaceplaceapp.com/#brands',
    },
    {
      text: 'Testimonials',
      url: 'https://spaceplaceapp.com/#testimonials',
    },
    {
      text: 'Shoppable Rentals',
      url: 'https://spaceplaceapp.com/#rentals',
    },
    {
      text: 'About Us',
      url: 'https://spaceplaceapp.com/#about',
    },
    {
      text: 'Blog',
      url: 'https://spaceplaceapp.com/pages/blog.html',
    },
  ],
  catalog: {
    tabs: [
      { key: 'bathroom', name: 'Bathroom', item: 'bathroom' },
      { key: 'bedroom', name: 'Bedroom', item: 'bedroom' },
      { key: 'kitchen', name: 'Kitchen', item: 'kitchen' },
      { key: 'dinning_room', name: 'Dining Room', item: 'dinning-room' },
      { key: 'living_room', name: 'Living Room', item: 'living-room' },
    ],
    filters: {
      Category: ['New Listing', 'Essentials'],
      Brand: ['BLIND BARBER', 'HUPPY'],
      Price: ['Under $50', '$50 to $100', '$100 to $200', '$200 & Above'],
    },
  },
  signUp: {
    confirmationCodeInput: {
      placeholder: 'Confirmation code',
    },
    modal: [
      'We have sent an email with a confirmation code to your email address. Please check your spam folder as well.',
      // eslint-disable-next-line quotes
      "Didn't receive email?",
      'Resend',
    ],
    successToastMessage: 'Account created successfully',
  },
  checkout: {
    helperTexts: [
      'Select the Property for your products',
      'Is the shipping address the same of the property?',
    ],
  },
  toastOptions: {
    position: 'top-center',
    style: {
      'box-sizing': 'border-box',
      'border-radius': '8px',
    },
    success: {
      style: {
        background: '#E8FAF2',
        border: '1px solid #AAECD0',
      },
    },
    error: {
      style: {
        background: '#FFEDED',
        border: '1px solid #FFBDBE',
      },
    },
    info: {
      style: {
        background: '#E8F4FF',
        border: '1px solid #AAD6FF',
      },
    },
    warning: {
      style: {
        background: '#FFF7E7',
        border: '1px solid #FEE1A8',
      },
    },
  },
  resetPassword: {
    userEmail: {
      title: 'Reset Password',
      description:
        'Please enter the e-mail address for your account. A verification code will be sent to you.',
      buttonText: 'Continue',
      inputLabel: 'Email',
    },
    verificationCode: {
      // title: 'Verification Code',
      // description: 'Please, enter the verification code sent to your email',
      // buttonText: 'Continue',
      inputLabel: 'Verification Code',
    },
    createPassword: {
      title: 'Create New Password',
      description: 'Please enter the verification code and your new password',
      buttonText: 'Reset Password',
      inputLabel: 'Password',
    },
    successToastMessage: 'New password created successfully',
  },
  ValidationMessages: {
    INVALID_EMAIL: 'Please enter a valid email address',
    INVALID_PASSWORD: 'Include at least 8 characters and a symbol or a number',
    INVALID_VERIFICATION_CODE: 'Invalid verification code',
  },
}

export default defaultCopy
