import { SAVE_GUEST_ACTIVE_PRODUCT_FILTERS } from '../constants'

const SaveGuestProductFilters = (guestProductFilters) => (dispatch) =>
  dispatch({
    type: SAVE_GUEST_ACTIVE_PRODUCT_FILTERS,
    payload: guestProductFilters,
  })

export default {
  SaveGuestProductFilters,
}
