import Logout from '../redux/actions/LogoutActions'
import notifyToast from './notifyToast'
import logMessage, { LogLevels } from './logMessage'
import { noop } from '.'

const DEFAULT_ERROR = 'Unknown error'
const EMPTY_RESPONSE_ERROR = 'Response from server is empty'
const NO_DATA_RECEIVED = 'Response from server does not have data'
const YOUR_SESSION_EXPIRED = 'Your session has expired'

export const isUnauthenticated = (error) =>
  error && error.toJSON && error.toJSON().status === 401

export const handleUnauthenticated = (dispatch = noop) => {
  notifyToast('error', YOUR_SESSION_EXPIRED)
  dispatch(Logout({ showSuccessToast: false }))
}

export const handleActionCatch = (
  error,
  dispatch,
  action,
  actionName = 'action',
  initialPayload = {}
) => {
  let errorMessage
  let errorName = ''

  // Javascript error (run time)
  if (error instanceof Error) {
    errorName = `${error.name} `
    errorMessage = error.message
  }

  // Axios error
  if (error.response) {
    errorName = 'AxiosError '
    errorMessage = error.response.data.error
  }

  // Unknown error
  if (!errorMessage) {
    errorName = 'Error '
    errorMessage = DEFAULT_ERROR
  }

  // Send error to redux
  if (dispatch && action) dispatch(action({ errorMessage, initialPayload }))

  // Check if session expired
  if (isUnauthenticated(error)) {
    handleUnauthenticated(dispatch)
  }
  return logMessage(
    `Error executing ${actionName}`,
    `${errorName}${errorMessage}`,
    LogLevels.ERROR
  )
}

/**
 * Checks that the response from the server is not empty and is successful
 * @param {ServerResponse} response - Raw response from the server
 */
export const validateServerResponse = (response) => {
  if (!response) throw new Error(EMPTY_RESPONSE_ERROR)

  if (!response.data) throw new Error(NO_DATA_RECEIVED)

  if (!response.data.success) throw new Error(response.data.error)
}
