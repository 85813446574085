import { SAVE_HOST_ACTIVE_PRODUCT_FILTERS } from '../constants'

const SaveHostProductFilters = (hostProductFilters) => (dispatch) =>
  dispatch({
    type: SAVE_HOST_ACTIVE_PRODUCT_FILTERS,
    payload: hostProductFilters,
  })

export default {
  SaveHostProductFilters,
}
