import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useIntercom } from 'react-use-intercom'
import PropTypes from 'prop-types'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ErrorBoundary from '../ErrorBoundary'

function Layout({ children, login, urlGuestParams }) {
  const { update, shutdown, boot } = useIntercom()

  useEffect(() => {
    if (Object.values(login).length >= 1) {
      const {
        user: { name, email },
      } = login
      update({
        name,
        email,
      })
    } else {
      shutdown()
      boot()
    }
  }, [login])

  return (
    <ErrorBoundary>
      <article className="layout-container">
        <Header />
        <div className="layout-body">{children}</div>
        <Footer urlGuestParams={urlGuestParams} />
      </article>
    </ErrorBoundary>
  )
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  login: PropTypes.object.isRequired,
  urlGuestParams: PropTypes.object.isRequired,
}

function mapStateToProps({
  login: { login },
  productList: { urlGuestParams },
}) {
  return {
    login,
    urlGuestParams,
  }
}

export default connect(mapStateToProps)(Layout)
