import ProductFilters from '../../domain/HostProductFilters'
import ReduxConnector from '../../utils/BaseClasses/ReduxConnector'

export default class HostProductFiltersRedux extends ReduxConnector {
  filters

  constructor(reduxProps, dispatch, action) {
    super(dispatch, action)
    this.filters = ProductFilters.reCreate(reduxProps)
  }

  updateHasNextPage(hasNextPage) {
    this.filters.updateHasNextPage(hasNextPage)
    this.save(this.filters.output())
  }

  clearMenuFilters() {
    this.filters.clearMenuFilters()
    this.save(this.filters.output())
  }

  clearPriceRange() {
    this.filters.clearPriceRange()
    this.save(this.filters.output())
  }

  clearSideFilters() {
    this.filters.clearSideFilters()
    this.save(this.filters.output())
  }

  clearSearchBoxFilter() {
    this.filters.clearSearchBoxFilter()
    this.save(this.filters.output())
  }

  updateSearchBoxFilter(filters) {
    this.filters.updateSearchBoxFilter(filters)
    this.save(this.filters.output())
  }

  updateSideFilters(filters) {
    this.filters.updateSideFilters(filters)
    this.save(this.filters.output())
  }

  updateFiltersFromUrl(filtersFromUrl) {
    this.filters.updateFiltersFromUrl(filtersFromUrl)
    this.save(this.filters.output())
  }
}
