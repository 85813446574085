import React from 'react'
import PropTypes from 'prop-types'

import { roundToDecimals } from '../../utils/productUtils'

function ComparisonFullPrice({ fullPrice }) {
  return (
    <span className="comparison-full-price">{`$${roundToDecimals(
      fullPrice
    )}`}</span>
  )
}

ComparisonFullPrice.propTypes = {
  fullPrice: PropTypes.number.isRequired,
}

export default ComparisonFullPrice
