import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as NoResultsIcon } from '../../assets/icons/no-results-icon.svg'

function NoResults({ mainText, secondaryText }) {
  return (
    <>
      <NoResultsIcon />
      {secondaryText ? <h3 className="no-response">{secondaryText}</h3> : null}
      <h3 className="no-response">{mainText}</h3>
    </>
  )
}

NoResults.propTypes = {
  mainText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
}

NoResults.defaultProps = {
  secondaryText: '',
}
export default NoResults
