import React from 'react'
import PropTypes from 'prop-types'

import { noop } from '../../../utils'

function NumericSelector(props) {
  const {
    id,
    value,
    onIncrement,
    onDecrement,
    minValue = 0,
    maxValue = 99,
    readOnly = false,
    onChange = noop,
  } = props
  const decrementClicked = (e) => {
    e.stopPropagation()
    if (value <= minValue) {
      return noop
    }

    return onDecrement()
  }

  const incrementClicked = (e) => {
    e.stopPropagation()
    if (value >= maxValue) {
      return noop
    }

    return onIncrement()
  }

  const manualChange = ({ target: { value: eventValue } }) => {
    if (eventValue === '0' && eventValue.length > 0) return onChange(minValue)
    if (eventValue < minValue) return onChange(eventValue)
    if (eventValue > maxValue) return onChange(maxValue)

    return onChange(Number(eventValue))
  }

  return (
    <div id={id} className="numeric-selector">
      <button type="button" onClick={decrementClicked}>
        <span>-</span>
      </button>
      <input
        className="quantity-input__screen"
        type="number"
        value={value}
        readOnly={readOnly}
        onChange={manualChange}
        onClick={(e) => e.stopPropagation()}
      />
      <button type="button" onClick={incrementClicked}>
        <span>+</span>
      </button>
    </div>
  )
}

NumericSelector.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  readOnly: PropTypes.bool,
}

NumericSelector.defaultProps = {
  minValue: 0,
  maxValue: 99,
  readOnly: false,
  onChange: noop,
}

export default NumericSelector
