/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'

import PaginationComponent from '../Pagination'

function TableComponent(props) {
  const { className, id, tableHead, children, paginateOptions } = props

  const showTableHead = () => tableHead.map((header) => <th>{header}</th>)

  const shouldShowPagination = () => {
    if (Object.values(paginateOptions).length >= 1) {
      return (
        <tr>
          {tableHead.map((item, idx) => {
            if (idx < tableHead.length - 1) {
              return <td key={item} />
            }
            return (
              <div>
                <PaginationComponent props={paginateOptions} />
              </div>
            )
          })}
        </tr>
      )
    }
    return null
  }

  return (
    <Table striped id={id} className={`table-component ${className}`}>
      {tableHead.length >= 1 && (
        <thead>
          <tr key="table-head">{showTableHead()}</tr>
        </thead>
      )}
      {children}
      {shouldShowPagination()}
    </Table>
  )
}

TableComponent.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  tableHead: PropTypes.array,
  children: PropTypes.element,
  paginateOptions: PropTypes.object,
}

TableComponent.defaultProps = {
  className: '',
  tableHead: [],
  children: <tbody />,
  paginateOptions: {},
}

export default TableComponent
