import { useDispatch } from 'react-redux'
import GuestActiveProductFiltersActions from '../../redux/actions/GuestActiveProductFiltersActions'
import HostActiveProductFiltersActions from '../../redux/actions/HostActiveProductFiltersActions'
import GuestProductFiltersRedux from '../../redux/connectors/GuestProductFiltersRedux'
import HostProductFiltersRedux from '../../redux/connectors/HostProductFiltersRedux'
import useGuest from '../useGuest'

const { SaveGuestProductFilters } = GuestActiveProductFiltersActions
const { SaveHostProductFilters } = HostActiveProductFiltersActions

/**
 * Gets the current filters for host and guest
 * @param {Object} activeProductFilters - Current state of filters
 * @returns Object instantiated from the appropriate class (if is guest or not)
 */
function useProductFilters(activeProductFilters) {
  const { isGuest } = useGuest()

  const dispatch = useDispatch()

  const ProductFiltersRedux = isGuest
    ? GuestProductFiltersRedux
    : HostProductFiltersRedux
  const SaveFiltersFunc = isGuest
    ? SaveGuestProductFilters
    : SaveHostProductFilters

  const productFilters = new ProductFiltersRedux(
    activeProductFilters,
    dispatch,
    SaveFiltersFunc
  )

  return productFilters
}

export default useProductFilters
