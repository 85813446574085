/* eslint default-param-last: off */
import { FILL_BREADCRUMB, CLEAR_BREADCRUMB } from '../constants'

export const initialState = {
  breadCrumb: [],
}

const BreadCrumbReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case FILL_BREADCRUMB: {
      return {
        ...initialState,
        breadCrumb: payload,
      }
    }
    case CLEAR_BREADCRUMB: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}

export default BreadCrumbReducer
