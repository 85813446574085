import { Auth } from 'aws-amplify'

import analytics from '../../utils/analytics'
import api from '../../utils/api'
import {
  handleActionCatch,
  validateServerResponse,
} from '../../utils/errorUtils'
import {
  SIGN_UP_STARTED,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILED,
  SIGN_UP_CLEAR,
  SIGN_UP_RESEND_CODE_STARTED,
  SIGN_UP_RESEND_CODE_SUCCESS,
  SIGN_UP_RESEND_CODE_FAILURE,
  SIGN_UP_CONFIRMATION_STARTED,
  SIGN_UP_CONFIRMATION_SUCCESS,
  SIGN_UP_CONFIRMATION_FAILURE,
} from '../constants'

export const SignUpStarted = (payload) => ({
  type: SIGN_UP_STARTED,
  ...payload,
})

export const SignUpSuccess = (payload) => ({
  type: SIGN_UP_SUCCESS,
  ...payload,
})

export const SignUpFailed = (payload) => ({
  type: SIGN_UP_FAILED,
  payload,
})

export const SignUpResendCodeStarted = (payload) => ({
  type: SIGN_UP_RESEND_CODE_STARTED,
  payload,
})

export const SignUpResendCodeSuccess = (payload) => ({
  type: SIGN_UP_RESEND_CODE_SUCCESS,
  payload,
})

export const SignUpResendCodeFailure = (payload) => ({
  type: SIGN_UP_RESEND_CODE_FAILURE,
  payload,
})

export const SignUpConfirmationStarted = (payload) => ({
  type: SIGN_UP_CONFIRMATION_STARTED,
  payload,
})

export const SignUpConfirmationSuccess = (payload) => ({
  type: SIGN_UP_CONFIRMATION_SUCCESS,
  payload,
})

export const SignUpConfirmationFailure = (payload) => ({
  type: SIGN_UP_CONFIRMATION_FAILURE,
  payload,
})

export const SignUpClear = () => ({ type: SIGN_UP_CLEAR })

const UserSignUp = (payload) => async (dispatch) => {
  dispatch(SignUpStarted(payload))
  try {
    const { isGuest, firstName, lastName, email, password } = payload
    const name = `${firstName} ${lastName}`

    // Save in cognito aws
    const { userSub } = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name,
        'custom:first_name': firstName,
        'custom:last_name': lastName,
      },
    })

    // delete password from payload to api
    const payloadForApi = { ...payload, cognitoId: userSub }
    delete payloadForApi.password

    // save user in api
    const result = await api.Post('/user/register', payloadForApi)
    validateServerResponse(result)
    dispatch(SignUpSuccess(result.data.data))
    analytics.signUp('email/password', isGuest, firstName, lastName, email)
  } catch (error) {
    handleActionCatch(error, dispatch, SignUpFailed, 'User Signup', payload)
  }
}

const ResendSignupConfirmationCode = (payload) => async (dispatch) => {
  dispatch(SignUpResendCodeStarted(payload))
  try {
    const { email } = payload
    await Auth.resendSignUp(email)
    dispatch(SignUpResendCodeSuccess({}))
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      SignUpResendCodeFailure,
      'User Signup Resend Code',
      payload
    )
  }
}

const ConfirmSignUp = (payload) => async (dispatch) => {
  dispatch(SignUpConfirmationStarted(payload))
  try {
    const { email, code } = payload
    await Auth.confirmSignUp(email, code)
    dispatch(SignUpConfirmationSuccess({}))
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      SignUpConfirmationFailure,
      'User Signup Confirmation',
      payload
    )
  }
}

const ClearSignUp = () => (dispatch) => dispatch(SignUpClear())

export default {
  UserSignUp,
  ClearSignUp,
  ResendSignupConfirmationCode,
  ConfirmSignUp,
}
