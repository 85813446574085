/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import PropTypes from 'prop-types'
import HostActiveProductFiltersActions from '../../redux/actions/HostActiveProductFiltersActions'
import HostProductFiltersRedux from '../../redux/connectors/HostProductFiltersRedux'

import Icon from '../Icon'

const { SaveHostProductFilters } = HostActiveProductFiltersActions

function BreadCrumbComponent({ hostActiveProductFilters, menuItems }) {
  const dispatch = useDispatch()

  const productFilters = new HostProductFiltersRedux(
    hostActiveProductFilters,
    dispatch,
    SaveHostProductFilters
  )

  const findTagInMainCategories = (newTag) => {
    const tagInMainCategory = menuItems.find(
      (mainCategory) => mainCategory.title.toLowerCase() === newTag
    )
    return tagInMainCategory ? [tagInMainCategory.title] : []
  }

  const findTagInSubCategories = (newTag) => {
    for (let i = 0, ln = menuItems.length; i < ln; i += 1) {
      const { items } = menuItems[i]
      const tagInSubCategory = items.find(
        ({ title }) => title.toLowerCase() === newTag
      )

      if (tagInSubCategory) {
        return [menuItems[i].title, tagInSubCategory.title]
      }
    }
    return []
  }

  let breadCrumbItems = []
  // TODO: change to handle more than one tag
  const tag = productFilters.filters.tagsStr
  const newTag = tag ? tag.toLowerCase().replaceAll('and', '&') : ''

  if (newTag) {
    breadCrumbItems = findTagInMainCategories(newTag)
    if (breadCrumbItems.length === 0) {
      breadCrumbItems = findTagInSubCategories(newTag)
    }
  }

  const handleItems = () =>
    breadCrumbItems.map((item, idx) => {
      if (breadCrumbItems.length - 1 === idx) {
        return (
          <BreadcrumbItem key={item} active>
            {item}
          </BreadcrumbItem>
        )
      }
      return <BreadcrumbItem key={item}>{item}</BreadcrumbItem>
    })

  return breadCrumbItems?.length >= 1 ? (
    <div className="breadcrumb__container">
      <button type="button" onClick={() => productFilters.clearMenuFilters()}>
        <Icon item="arrow-left" /> Back
      </button>
      <Breadcrumb>{handleItems()}</Breadcrumb>
    </div>
  ) : null
}

BreadCrumbComponent.propTypes = {
  items: PropTypes.array.isRequired,
  hostActiveProductFilters: PropTypes.object.isRequired,
  menuItems: PropTypes.array.isRequired,
}

const mapStateToProps = ({
  hostActiveProductFilters,
  menuItems: { getMenuItems: menuItems },
}) => ({
  hostActiveProductFilters,
  menuItems,
})

export default connect(mapStateToProps)(BreadCrumbComponent)
