/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import PropTypes from 'prop-types'

import Icon from '../Icon'

function index(props) {
  const { options, actions, active } = props
  const handleOptions = () =>
    options.map((tab, idx) => (
      <NavItem key={tab.key} className={active === tab.key ? 'active' : ''}>
        <NavLink className="" onClick={actions[idx]}>
          {tab?.item ? <Icon item={tab.item} width="20px" height="20px" /> : ''}
          {tab.name}
        </NavLink>
      </NavItem>
    ))

  return <Nav tabs>{handleOptions()}</Nav>
}

index.propTypes = {
  options: PropTypes.array.isRequired,
  actions: PropTypes.array.isRequired,
  active: PropTypes.string,
}

index.defaultProps = {
  active: '*',
}

export default index
