import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import htmlToReact from 'html-react-parser'
import _ from 'lodash'
import { useSWRConfig } from 'swr'

import ProductImages from './ProductImages'
import Button from '../../components/Buttons/Button'
import ComparisonFullPrice from '../../components/ComparisonFullPrice'
import Icon from '../../components/Icon'
import IconButton from '../../components/Buttons/IconButton'
import HeartUnfilledIcon from '../../assets/icons/heart-unfilled.svg'
import HeartFilledIcon from '../../assets/icons/heart-filled.svg'
import BookmarkFilled from '../../assets/icons/bookmark-filled.svg'
import BookmarkUnfilled from '../../assets/icons/bookmark-unfilled.svg'
import NumericSelector from '../../components/Inputs/NumericSelector'
import OptionsSelector from './ProductOptions/OptionsSelector'
import StarRating from '../../components/StarRating'
import ProductSpecifications from './ProductOptions/ProductSpecifications'
import { AddProductToGuestCart } from '../../redux/actions/GuestCartActions'

import ProductDetailsActions, {
  ClearProductDetails,
} from '../../redux/actions/ProductDetailsAction'
import CartActions from '../../redux/actions/CartActions'
import {
  AddProductToSaved,
  DeleteProductSaved,
} from '../../redux/actions/SavedListActions'
import { SetUrlGuestParams } from '../../redux/actions/CatalogActions'
import LoaderComponent from '../../components/Loader'
import ModalComponent from '../../components/ModalComponent'
import ErrorAlert from '../../components/ErrorAlert'

import notifyToast from '../../utils/notifyToast'
import { extractSessionInfo, setPageTitleAndUserInfo } from '../../utils'
import { roundToDecimals } from '../../utils/productUtils'
import analytics from '../../utils/analytics'
import useGuest from '../../hooks/useGuest'

const { GetProductDetails, LikeProduct, UnLikeProduct, RateProduct } =
  ProductDetailsActions
const { AddProductToCart } = CartActions
function ProductDetails(props) {
  const {
    isLoading,
    isAddLoading,
    productDetails: {
      id,
      title,
      price,
      liked,
      rating,
      images,
      options: initialProductOptions,
      variants,
      vendor,
      descriptionHtml,
    },
    guestCartProduct,
    productDetailsError,
    likeProductError,
    unlikeProductError,
    rateProductSuccess,
    session,
    savedProductList,
    likeProductIdSuccess,
    unlikeProductIdSuccess,
  } = props

  const { cache } = useSWRConfig()
  const dispatch = useDispatch()
  const params = useParams()
  const { isGuest, baseRoute } = useGuest()
  const { id: productId } = params

  const { userId, userName, userEmail, jwt } = extractSessionInfo(session)
  const isLoggedIn = !!jwt

  useEffect(() => {
    let newPageTitle = 'Product Details'
    if (isGuest) newPageTitle += ' (Guest)'
    setPageTitleAndUserInfo(newPageTitle, userId, userName, userEmail)
  }, [])

  useEffect(() => {
    if (isGuest && title !== null) {
      analytics.viewProductDetailGuest(`${baseRoute}/product/list`, id, title)
    }
  }, [title])

  const [quantity, setQuantity] = useState(1)
  const [productOptions, setProductOptions] = useState(initialProductOptions)
  const [chosenOptions, setChosenOptions] = useState({})
  const [chosenVariant, setChosenVariant] = useState(variants[0])
  const [showModal, setShowModal] = useState(false)
  const [productRating, setProductRating] = useState(0)
  const productToSave = {
    id,
    title,
    variants,
    rating,
    image: chosenVariant?.image,
    liked,
  }
  const isSaved =
    savedProductList?.filter((saved) => saved.id === id).length >= 1
  const productSpecifications = [
    {
      name: 'Weight',
      value:
        chosenVariant && chosenVariant.weight
          ? `${chosenVariant.weight} ${chosenVariant.weight_unit}`
          : '',
    },
  ]

  const setVariantBasedOnProducts = () => {
    if (productOptions?.every((option) => option?.chosenValue)) {
      const options = productOptions?.map(({ name, chosenValue }) => ({
        name,
        value: chosenValue,
      }))
      const currentVariant = variants?.find((variant) =>
        options?.every(({ value }, i) => variant[`option${i + 1}`] === value)
      )
      setChosenVariant(currentVariant)
      setChosenOptions(options)
    }
  }

  useEffect(() => {
    // didMount
    dispatch(ClearProductDetails())
    dispatch(GetProductDetails({ id: productId, token: jwt }))
    if (isGuest) {
      dispatch(SetUrlGuestParams(params))
    }
  }, [])

  useEffect(() => {
    setChosenVariant(variants[0])
  }, [variants])

  useEffect(() => {
    if (
      (likeProductIdSuccess && likeProductIdSuccess === id) ||
      (unlikeProductIdSuccess && unlikeProductIdSuccess === id)
    ) {
      // clear cache of the catalogs to avoid discrepancies between local cache
      // and updated backend data
      cache.clear()
    }
  }, [likeProductIdSuccess, unlikeProductIdSuccess])

  useEffect(() => {
    if (Object.values(initialProductOptions).length >= 1) {
      const currentOptions = initialProductOptions?.map((option) => ({
        ...option,
        chosenValue: option.values[0],
      }))
      setProductOptions(currentOptions)
    }
  }, [initialProductOptions])

  const handleUnexistentVariants = () => {
    let arr
    const quantity1 = []
    const options2 = []
    const options3 = []
    const quantity2 = []
    const quantity3 = []
    if (productOptions.length === 1 && productOptions[0].values.length > 1) {
      variants?.forEach((item) => quantity1.push(item.quantity))
      productOptions[0].quantity = quantity1
    } else if (productOptions.length === 2) {
      arr = _.filter(variants, { option1: productOptions[0].chosenValue })
      arr?.forEach((item) => {
        options2.push(item.option2)
        quantity2.push(item.quantity)
      })
      productOptions[1].values = options2
      productOptions[1].quantity = quantity2
    } else if (productOptions.length === 3) {
      arr = _.filter(variants, { option1: productOptions[0].chosenValue })
      arr?.forEach((item) => {
        if (!options2?.includes(item.option2)) {
          options2.push(item.option2)
          quantity2.push(item.quantity)
        }
      })
      productOptions[1].values = options2
      productOptions[1].quantity = quantity2

      arr = _.filter(variants, {
        option1: productOptions[0].chosenValue,
        option2: productOptions[1].chosenValue,
      })
      arr?.forEach((item) => {
        options3.push(item.option3)
        quantity3.push(item.quantity)
      })
      productOptions[2].values = options3
      productOptions[2].quantity = quantity3
    }
  }

  useEffect(() => {
    if (
      initialProductOptions?.every((item) => item.values.length === 1) &&
      !productOptions?.every((item) => item.chosenValue.length >= 1)
    ) {
      setProductOptions(
        productOptions?.map((option) => ({
          ...option,
          chosenValue: option.values[0],
        }))
      )
    }
    handleUnexistentVariants()
    setVariantBasedOnProducts()
  }, [productOptions])

  useEffect(() => {
    if (rateProductSuccess) {
      dispatch(GetProductDetails({ id: productId }))
    }
  }, [rateProductSuccess])

  const shouldEnableButton = () => {
    if (initialProductOptions?.every((item) => item.values.length === 1)) {
      return true
    }
    return productOptions?.every((item) => !!item.chosenValue)
  }

  const likeButtonPushed = () => {
    const likeAction = liked ? UnLikeProduct : LikeProduct
    dispatch(
      likeAction({
        id,
        title,
        token: jwt,
        propertyUrl: `${baseRoute}/product/list`,
        isGuest,
      })
    )
  }

  const onOptionChosen = (name, value) => {
    setProductOptions(
      productOptions?.map((option) => {
        if (option.name === name) {
          return {
            ...option,
            chosenValue: option.chosenValue === value ? '' : value,
          }
        }
        return option
      })
    )
  }

  const addToCart = () => {
    const product = {
      id,
      title,
      price: chosenVariant?.price || price,
      quantity,
      image: chosenVariant?.image || images[0]?.src || '',
      productOptionsJSON: JSON.stringify(chosenOptions),
      compareAtPrice: chosenVariant?.compareAtPrice,
      stock: chosenVariant?.quantity,
    }

    if (chosenVariant && chosenVariant?.id) {
      product.variantId = chosenVariant.id
      if (!isGuest || isLoggedIn)
        dispatch(
          AddProductToCart({
            ...product,
            token: jwt,
            isGuest,
            propertyUrl: `${baseRoute}/product/list`,
          })
        )
      if (isGuest && !isLoggedIn) {
        dispatch(AddProductToGuestCart([...guestCartProduct, product]))
        analytics.addToCartGuest(
          product.price,
          product.id,
          product.title,
          product.quantity,
          product.productOptionsJSON,
          true,
          product.variantId,
          `${baseRoute}/product/list`
        )
      }
    } else {
      notifyToast(
        'error',
        'The selected combination of options is out of stock'
      )
    }
  }

  const handleCartGuest = () => {
    if (isLoggedIn) {
      addToCart()
    } else {
      const productIds = guestCartProduct?.map(
        (idProduct) => idProduct.variantId
      )
      const isProductIncluded = productIds?.includes(chosenVariant?.id)
      if (isProductIncluded) {
        const productNewQuantity = guestCartProduct
        productNewQuantity?.forEach((product) => {
          if (product.variantId === chosenVariant?.id) {
            // eslint-disable-next-line
            product.quantity += 1
            dispatch(AddProductToGuestCart(productNewQuantity))
            analytics.addToCartGuest(
              product.price,
              id,
              title,
              product.quantity,
              product.productOptionsJSON,
              true,
              product.variantId,
              `${baseRoute}/product/list`
            )
          }
        })
      } else {
        addToCart()
      }
    }
  }

  const handleTextButton = () => {
    if (chosenVariant?.quantity === 0) {
      return 'Out of stock'
    }
    if (isGuest) {
      return 'Buy Now'
    }
    return 'Add to cart'
  }

  const handleClickRate = () => {
    setProductRating(0)
    setShowModal(true)
  }
  const handleAddRating = (rate) => setProductRating(rate)
  const handleCancelRating = () => setShowModal(false)
  const handleSaveRating = () => {
    const { sku } = variants[0]
    dispatch(RateProduct({ productRating, productId, vendor, sku }))
    setShowModal(false)
  }
  const isDisabled = () => {
    if (productRating === 0) {
      return true
    }
    return false
  }

  const hasVariants = productOptions[0]?.values[0] !== 'Default Title'
  const productDescription = () => {
    const filteredProductSpecifications = productSpecifications?.filter(
      (item) => item?.value
    )
    return (
      <div className="summary-container">
        <span className="label">Description: </span>
        <div id="productDescription" className="description col-12 col-md-6">
          {'' || htmlToReact(descriptionHtml || '')}
        </div>
        {filteredProductSpecifications?.length >= 1 && (
          <div className="options-summary col-12 col-md-6">
            <ProductSpecifications
              productSpecifications={filteredProductSpecifications}
            />
          </div>
        )}
      </div>
    )
  }
  const handleSaveProduct = () => {
    if (isLoggedIn) {
      likeButtonPushed()
    } else if (isSaved) {
      dispatch(DeleteProductSaved(id))
    } else {
      dispatch(AddProductToSaved([...savedProductList, productToSave]))
    }
  }

  const controlQuantitySelector = () => chosenVariant?.quantity

  const showGuestFilledIcon = (isSaved && !isLoggedIn) || (liked && isLoggedIn)

  return (
    <section className="product-detail">
      <LoaderComponent show={isLoading || isAddLoading} />
      {productDetailsError && <ErrorAlert error={productDetailsError} />}
      {likeProductError && <ErrorAlert error={likeProductError} />}
      {unlikeProductError && <ErrorAlert error={unlikeProductError} />}
      <div className="row">
        <div className="carousel-container col-12 col-lg-6">
          <ProductImages
            images={images}
            chosenVariant={chosenVariant}
            mainImage={chosenVariant?.image || null}
          />
        </div>

        <div className="details-container col-12 col-lg-6">
          <div className="name-favorite">
            <h1 className="product-name">{title}</h1>
            {!isGuest ? (
              <button
                type="button"
                className="favorite-btn favorite-btn--mobile"
              >
                <IconButton
                  id="product-details-like-button"
                  action={() => likeButtonPushed()}
                  tertiary
                  svg={liked ? HeartFilledIcon : HeartUnfilledIcon}
                />
              </button>
            ) : (
              <button
                type="button"
                className="favorite-btn favorite-btn--mobile"
              >
                <IconButton
                  id="product-details-guest-save-btn"
                  action={() => handleSaveProduct()}
                  tertiary
                  svg={showGuestFilledIcon ? BookmarkFilled : BookmarkUnfilled}
                />
              </button>
            )}
          </div>
          <div className="rating">
            <StarRating
              id="product-detail-star-rating"
              maxScore={5}
              rating={rating.rating}
            />
            <span>{rating.ratings} ratings</span>
            {isGuest && (
              <button
                type="button"
                onClick={() => handleClickRate()}
                className="rate-product-btb"
              >
                <Icon item="edit" stroke="#ff8a48" width="20px" height="20px" />
                <span>Rate product</span>
              </button>
            )}
          </div>

          <div className="price">
            <span className="discount">
              {`$${roundToDecimals(chosenVariant?.price || price)}`}
            </span>
            <ComparisonFullPrice fullPrice={chosenVariant?.compareAtPrice} />
          </div>

          <p className="label">
            Brand:
            <span className="brand-name"> {vendor}</span>
          </p>

          {hasVariants && (
            <div className="options">
              <OptionsSelector
                productOptions={productOptions}
                onOptionChosen={onOptionChosen}
              />
            </div>
          )}

          <div className="buttons">
            <NumericSelector
              id="product-details-quantity-selector"
              value={quantity}
              onIncrement={() => setQuantity(quantity + 1)}
              onDecrement={() => setQuantity(quantity - 1)}
              minValue={1}
              maxValue={controlQuantitySelector()}
              onChange={(value) => setQuantity(value)}
            />
            <Button
              id="product-details-quantity-button"
              className="btn-to-cart"
              text={handleTextButton()}
              action={isGuest ? () => handleCartGuest() : () => addToCart()}
              disabled={
                !shouldEnableButton() ||
                chosenVariant?.quantity === 0 ||
                quantity?.length === 0
              }
            />
            {!isGuest ? (
              <button
                type="button"
                className="favorite-btn favorite-btn--desktop"
              >
                <IconButton
                  id="product-details-like-button"
                  action={() => likeButtonPushed()}
                  tertiary
                  svg={liked ? HeartFilledIcon : HeartUnfilledIcon}
                />
              </button>
            ) : (
              <button
                type="button"
                className="favorite-btn favorite-btn--desktop"
              >
                <IconButton
                  id="product-details-guest-save-btn"
                  action={() => handleSaveProduct()}
                  tertiary
                  svg={showGuestFilledIcon ? BookmarkFilled : BookmarkUnfilled}
                />
              </button>
            )}
          </div>
          {productDescription()}
        </div>
      </div>

      <ModalComponent show={showModal} className="rate-product">
        <h4>Add Rate</h4>
        <div className="rate-product-modal__rating">
          <StarRating
            id="guest-product"
            maxScore={5}
            clickable
            rating={productRating}
            action={(event) => handleAddRating(event)}
            noborder={false}
          />
          <div className="rating-score">{productRating}/5</div>
        </div>
        <div className="rate-product-modal__buttons">
          <Button
            id="cancelRating"
            secondary
            text="Cancel"
            action={() => handleCancelRating()}
          />
          <Button
            id="saveRating"
            text="Save"
            action={() => handleSaveRating()}
            disabled={isDisabled()}
          />
        </div>
      </ModalComponent>
    </section>
  )
}

ProductDetails.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAddLoading: PropTypes.bool.isRequired,
  productDetails: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    price: PropTypes.number,
    priceNoDiscount: PropTypes.number,
    liked: PropTypes.bool,
    rating: PropTypes.shape({
      ratings: PropTypes.number,
      rating: PropTypes.number,
    }),
    descriptionHtml: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    images: PropTypes.arrayOf(PropTypes.object),
    variants: PropTypes.arrayOf(PropTypes.any),
    vendor: PropTypes.string,
  }).isRequired,
  session: PropTypes.shape({
    jwtToken: PropTypes.string,
  }).isRequired,
  productDetailsError: PropTypes.string.isRequired,
  likeProductError: PropTypes.string.isRequired,
  unlikeProductError: PropTypes.string.isRequired,
  rateProductSuccess: PropTypes.bool.isRequired,
  guestCartProduct: PropTypes.arrayOf(PropTypes.any).isRequired,
  savedProductList: PropTypes.array.isRequired,
  likeProductIdSuccess: PropTypes.number.isRequired,
  unlikeProductIdSuccess: PropTypes.number.isRequired,
}

const mapStateToProps = ({
  productDetails: {
    productDetails,
    productDetailsIsLoading: isLoading,
    productDetailsError,
    likeProductError,
    unlikeProductError,
    rateProductSuccess,
    likeProductIdSuccess,
    unlikeProductIdSuccess,
  },
  savedList: { savedProductList },
  login: { login },
  cart: { addCartIsLoading: isAddLoading },
  guestCart: { cartProducts },
}) => ({
  productDetails,
  isLoading,
  isAddLoading,
  session: login,
  productDetailsError,
  likeProductError,
  unlikeProductError,
  rateProductSuccess,
  guestCartProduct: cartProducts,
  savedProductList,
  likeProductIdSuccess,
  unlikeProductIdSuccess,
})

export default connect(mapStateToProps)(ProductDetails)
