import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ClearProductDetails } from '../redux/actions/ProductDetailsAction'

/**
 * Custom hook with everything that the application needs to do one time
 * at first mount or before dismount:
 * 1. reset host product filters
 * 2. clear product details redux state
 */
const useAppInit = () => {
  const dispatch = useDispatch()

  /* eslint-disable arrow-body-style */
  useEffect(() => {
    return () => {
      dispatch(ClearProductDetails())
    }
  })
}

export default useAppInit
