import analytics from '../../utils/analytics'
import api from '../../utils/api'
import {
  handleActionCatch,
  validateServerResponse,
} from '../../utils/errorUtils'
import notifyToast from '../../utils/notifyToast'
import {
  RESET_ADD_PROPERTY_TOASTS,
  ADD_PROPERTY_STARTED,
  ADD_PROPERTY_SUCCESS,
  ADD_PROPERTY_FAILED,
  REMOVE_ADD_PROPERTY,
} from '../constants'

export const AddPropertyStarted = (payload) => ({
  type: ADD_PROPERTY_STARTED,
  ...payload,
})

export const AddPropertySuccess = (payload) => {
  notifyToast('success', 'Property created successfully', 2000)
  return { type: ADD_PROPERTY_SUCCESS, payload }
}

export const AddPropertyFailed = (payload) => ({
  type: ADD_PROPERTY_FAILED,
  payload,
})

export const RemoveAddProperty = () => ({ type: REMOVE_ADD_PROPERTY })

const ResetAddPropertyToasts = () => ({ type: RESET_ADD_PROPERTY_TOASTS })

export const AddProperty = (payload, token) => async (dispatch) => {
  dispatch(AddPropertyStarted(payload))
  try {
    const result = await api.Post('/property', payload, token)
    validateServerResponse(result)
    dispatch(AddPropertySuccess(result.data.data))
    const { nickName, address, state, city } = payload
    analytics.addProperty(nickName, address, state, city)
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      AddPropertyFailed,
      'Add property',
      payload
    )
  }
  dispatch(ResetAddPropertyToasts())
}

export default AddProperty
