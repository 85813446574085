import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import InputComponent from '../../components/Inputs/InputComponent'
import SelectComponent from '../../components/SelectComponent'

import { states, cities } from '../../utils/countryStateCity'

function MobileEdit({ details, fields, setFields, handleChange }) {
  const [selectCity, setSelectCity] = useState(false)
  return (
    <article>
      <form className="form-mobile">
        <div className="form-mobile__section1">
          <h6 className="form-mobile-required">Nickname</h6>
          <InputComponent
            name="nickName"
            id="nickName"
            outlined
            value={details?.nickName}
            onChange={() => {}}
            disabled
          />
          <h6 className="form-mobile-required">Listing link</h6>
          <InputComponent
            name="listingLink"
            id="listingLink"
            outlined
            value={fields?.listingLink}
            // invalid={invalidLink}
            onChange={({ target }) => handleChange(target)}
          />
          <h6>Calendar link</h6>
          <InputComponent
            name="calendarLink"
            id="calendarLink"
            outlined
            value={fields?.calendarLink}
            // invalid={invalidCalendar}
            onChange={({ target }) => handleChange(target)}
          />
          {/* <a href="#s"> How to find your calendar link</a> */}
        </div>
        <div className="form-mobile__section2">
          <h4>Shipping address</h4>
          <h6 className="form-mobile-required">Address</h6>
          <InputComponent
            name="address"
            id="address"
            outlined
            value={fields?.address}
            onChange={({ target }) => handleChange(target)}
          />
          <h6 className="form-mobile-required form-mobile-separator-boder">
            City
          </h6>
          <div className="edit-property__state-city-input">
            <Dropdown isOpen={selectCity}>
              <DropdownToggle nav>
                <InputComponent
                  id="city"
                  name="city"
                  labelText=""
                  isMandatory
                  value={fields.city}
                  onChange={({ target }) => handleChange(target)}
                  onFocus={() => setSelectCity(true)}
                  outlined
                  className="col-12 col-sm-6"
                  autoComplete={false}
                />
              </DropdownToggle>
              <DropdownMenu>
                {cities()
                  .filter((city) =>
                    city.text.toLowerCase().startsWith(fields.city)
                  )
                  .map((city) => (
                    <DropdownItem
                      onClick={() => {
                        setFields({ ...fields, city: city.text })
                        setSelectCity(false)
                      }}
                    >
                      {city.text}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          <h6 className="form-mobile-required">State</h6>
          <SelectComponent
            id="state"
            name="state"
            value={fields?.state}
            options={states()}
            onChange={({ target }) => handleChange(target)}
          />
          <h6 className="form-mobile-required form-mobile-separator-boder">
            Zip code
          </h6>
          <InputComponent
            outlined
            name="zipCode"
            id="zipCode"
            value={fields?.zipCode}
            onChange={({ target }) => handleChange(target)}
          />
          <h6>Apartment/Floor/Other:</h6>
          <InputComponent
            name="secondAddress"
            id="secondAddress"
            outlined
            value={fields?.secondAddress}
            onChange={({ target }) => handleChange(target)}
          />
        </div>
      </form>
    </article>
  )
}

MobileEdit.propTypes = {
  details: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
}

export default MobileEdit
