/* eslint default-param-last: off */
import {
  GET_ORDERS_LIST_STARTED,
  GET_ORDERS_LIST_SUCCESS,
  GET_ORDERS_LIST_FAILURE,
  CANCEL_ORDER_STARTED,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAILURE,
  CLEAR_CANCEL_ORDERS,
} from '../constants'

export const orderListinitialState = {
  orderList: {},
  orderListSuccess: false,
  orderListIsLoading: false,
  orderListFailed: false,
  orderListError: '',
}

export const cancelOrderInitialState = {
  cancelOrder: {},
  cancelOrderSuccess: false,
  cancelOrderIsLoading: false,
  cancelOrderFailed: false,
  cancelOrderError: '',
}

export const initialState = {
  ...orderListinitialState,
  ...cancelOrderInitialState,
}

const orderReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_ORDERS_LIST_STARTED: {
      return {
        ...initialState,
        orderListIsLoading: true,
        orderListSuccess: false,
        orderListFailed: false,
        orderListError: '',
      }
    }
    case GET_ORDERS_LIST_SUCCESS: {
      return {
        ...state,
        orderListIsLoading: false,
        orderListSuccess: true,
        orderList: payload,
      }
    }
    case GET_ORDERS_LIST_FAILURE: {
      return {
        ...state,
        orderListIsLoading: false,
        orderListFailed: true,
        orderListError: payload.errorMessage,
      }
    }
    case CANCEL_ORDER_STARTED: {
      return {
        ...state,
        cancelOrderIsLoading: true,
        cancelOrderSuccess: false,
        cancelOrderFailed: false,
        cancelOrderError: '',
      }
    }
    case CANCEL_ORDER_SUCCESS: {
      return {
        ...state,
        cancelOrderIsLoading: false,
        cancelOrderSuccess: true,
        cancelOrder: payload,
      }
    }
    case CANCEL_ORDER_FAILURE: {
      return {
        ...state,
        cancelOrderIsLoading: false,
        cancelOrderFailed: true,
        cancelOrderError: payload.errorMessage,
      }
    }
    case CLEAR_CANCEL_ORDERS: {
      return {
        ...state,
        ...cancelOrderInitialState,
      }
    }
    default:
      return state
  }
}

export default orderReducer
