/* eslint default-param-last: off */
import {
  SIGN_UP_STARTED,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILED,
  SIGN_UP_CLEAR,
  SIGN_UP_RESEND_CODE_STARTED,
  SIGN_UP_RESEND_CODE_SUCCESS,
  SIGN_UP_RESEND_CODE_FAILURE,
  SIGN_UP_CONFIRMATION_STARTED,
  SIGN_UP_CONFIRMATION_SUCCESS,
  SIGN_UP_CONFIRMATION_FAILURE,
} from '../constants'

export const initialState = {
  signUp: {},
  signUpStarted: false,
  signUpSuccess: false,
  signUpIsLoading: false,
  signUpFailed: {},
  signUpError: '',
  signUpConfirmationCodeError: '',
  signUpConfirmAccountError: '',
  signUpResendConfirmationCodeSuccess: true,
  signUpConfirmAccountSuccess: false,
}

const signUpStartedState = {
  signUpStarted: true,
  signUpIsLoading: true,
  signUpError: '',
  signUpConfirmationCodeError: '',
  signUpConfirmAccountError: '',
  signUpResendConfirmationCodeSuccess: false,
  signUpConfirmAccountSuccess: false,
}

const signUpReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SIGN_UP_STARTED: {
      return {
        ...state,
        ...signUpStartedState,
      }
    }
    case SIGN_UP_SUCCESS: {
      return {
        ...state,
        signUp: payload,
        signUpSuccess: true,
        signUpIsLoading: false,
      }
    }
    case SIGN_UP_FAILED: {
      return {
        ...state,
        signUpError: payload.errorMessage,
        signUpIsLoading: false,
      }
    }
    case SIGN_UP_CLEAR:
      return initialState

    case SIGN_UP_RESEND_CODE_STARTED: {
      return {
        ...state,
        ...signUpStartedState,
      }
    }
    case SIGN_UP_RESEND_CODE_SUCCESS: {
      return {
        ...state,
        signUpIsLoading: false,
        signUpStartedState: false,
        signUpResendConfirmationCodeSuccess: true,
      }
    }
    case SIGN_UP_RESEND_CODE_FAILURE: {
      return {
        ...state,
        signUpIsLoading: false,
        signUpConfirmationCodeError: payload.errorMessage,
      }
    }

    case SIGN_UP_CONFIRMATION_STARTED: {
      return {
        ...state,
        ...signUpStartedState,
      }
    }
    case SIGN_UP_CONFIRMATION_SUCCESS: {
      return {
        ...state,
        signUpIsLoading: false,
        signUpConfirmAccountSuccess: true,
      }
    }
    case SIGN_UP_CONFIRMATION_FAILURE: {
      return {
        ...state,
        signUpIsLoading: false,
        signUpConfirmAccountError: payload.errorMessage,
      }
    }
    default:
      return state
  }
}

export default signUpReducer
