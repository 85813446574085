/* eslint default-param-last: off */
import {
  RESET_ADD_PROPERTY_TOASTS,
  ADD_PROPERTY_STARTED,
  ADD_PROPERTY_SUCCESS,
  ADD_PROPERTY_FAILED,
  REMOVE_ADD_PROPERTY,
} from '../constants'

export const initialState = {
  addProperty: {},
  addPropertyStarted: false,
  addPropertySuccess: false,
  addPropertyIsLoading: false,
  addPropertyFailed: false,
  addPropertyError: '',
}

const addPropertyReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case ADD_PROPERTY_STARTED: {
      return {
        ...state,
        addPropertyStarted: true,
        addPropertyIsLoading: true,
        addPropertyError: '',
      }
    }
    case ADD_PROPERTY_SUCCESS: {
      return {
        ...state,
        addPropertySuccess: true,
        addPropertyIsLoading: false,
        addProperty: payload,
      }
    }
    case ADD_PROPERTY_FAILED: {
      return {
        ...state,
        addPropertyFailed: true,
        addPropertyError: payload.errorMessage,
        addPropertyIsLoading: false,
      }
    }
    case REMOVE_ADD_PROPERTY: {
      return {
        ...initialState,
      }
    }
    case RESET_ADD_PROPERTY_TOASTS: {
      return {
        ...state,
        addPropertyStarted: false,
        addPropertySuccess: false,
        addPropertyIsLoading: false,
        addPropertyFailed: false,
        addPropertyError: '',
      }
    }
    default:
      return state
  }
}

export default addPropertyReducer
