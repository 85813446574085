import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSWRConfig } from 'swr'
import {
  ClearFilterProducts,
  ClearProductsList,
} from '../../redux/actions/CatalogActions'
import api from '../../utils/api'
import {
  handleUnauthenticated,
  isUnauthenticated,
} from '../../utils/errorUtils'
import pagination from '../../utils/pagination'
import useGuest from '../useGuest'

const { PaginationTypes } = pagination

/**
 * Helper hook to fetch products for host and guest catalog. Pass the product filters
 * as a parameter (previously built from other hook) and the jwt.
 * @param {ProductFiltersReduxConnector} productFilters - Current product filters
 * @param {string} jwt - Auth token for current session
 * @returns Object with data, error, isFetching, page and setPage
 */
function useFetchCatalog(productFilters, jwt) {
  const { cache } = useSWRConfig()
  const { isGuest } = useGuest()
  const dispatch = useDispatch()
  const [hasLoggedOut, setHasLoggedOut] = useState(false)

  const paginationType = isGuest
    ? PaginationTypes.PAGE
    : PaginationTypes.PAGE_INFO

  const onFetchSuccess = (newData) => {
    const { hasNextPage } = pagination.getProductsResponsePagination(
      newData,
      paginationType
    )
    if (productFilters.filters.hasNextPage !== hasNextPage)
      productFilters.updateHasNextPage(hasNextPage)

    if (isGuest && !productFilters.filters.visited)
      productFilters.updateVisited(1)
  }

  const apiRoute = productFilters.filters.filtersToApiStr()
  const { data, error, isValidating, size, setSize } = api.GetWithInfiniteSWR(
    apiRoute,
    jwt,
    {},
    onFetchSuccess,
    paginationType
  )

  if (isUnauthenticated(error) && !hasLoggedOut && !isGuest) {
    setHasLoggedOut(true)
    dispatch(ClearFilterProducts())
    dispatch(ClearProductsList())
    cache.clear() // Clear SWR cache before logging out
    handleUnauthenticated(dispatch)
  }

  return {
    data,
    error,
    isFetching: isValidating,
    page: size,
    setPage: setSize,
  }
}

export default useFetchCatalog
