import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useSWRConfig } from 'swr'

import LoaderComponent from '../../components/Loader'
import ProductCard from '../Catalog/ProductCard'

import GetFavoritesList, {
  ClearFavoritesList,
} from '../../redux/actions/FavoritesListActions'
import { extractSessionInfo, setPageTitleAndUserInfo } from '../../utils'
import NoResults from '../../components/NoResults/NoResults'

function FavoritesList({
  session,
  favoritesList,
  isLoading,
  isProductDetailLoading,
  unlikeProductIdSuccess,
}) {
  const { cache } = useSWRConfig()
  const dispatch = useDispatch()
  const [listToShow, setListToShow] = useState([])

  const { userId, userName, userEmail, jwt } = extractSessionInfo(session)

  useEffect(
    () =>
      setPageTitleAndUserInfo('Favorites List', userId, userName, userEmail),
    []
  )

  useEffect(() => {
    dispatch(ClearFavoritesList())
    dispatch(GetFavoritesList({ token: jwt, page: 0 }))
  }, [])

  useEffect(() => {
    if (favoritesList?.elements?.length >= 1 && favoritesList?.currentPage) {
      const { elements, currentPage } = favoritesList
      if (currentPage > 1 && elements) {
        setListToShow([...listToShow, ...elements])
      } else {
        setListToShow(elements)
      }
    } else {
      setListToShow([])
    }
  }, [favoritesList])

  useEffect(() => {
    if (!isProductDetailLoading && unlikeProductIdSuccess !== 0) {
      if (listToShow.length === 0) return
      const listToShowCopy = [...listToShow]
      const removedIndex = listToShowCopy.findIndex(
        (item) => item.id === unlikeProductIdSuccess
      )
      if (removedIndex > -1) {
        listToShowCopy.splice(removedIndex, 1)
        setListToShow(listToShowCopy)
      }
    }
  }, [isProductDetailLoading, unlikeProductIdSuccess])

  useEffect(() => {
    if (
      unlikeProductIdSuccess &&
      !favoritesList?.elements?.includes(unlikeProductIdSuccess)
    ) {
      // clear cache of the catalogs to avoid discrepancies between local cache
      // and updated backend data
      cache.clear()
    }
  }, [unlikeProductIdSuccess])

  const handleNextPage = () => {
    dispatch(
      GetFavoritesList({
        token: jwt,
        page: favoritesList.currentPage,
      })
    )
  }

  const renderList = () => {
    if (listToShow.length >= 1) {
      return listToShow.map((favorite) => (
        <ProductCard product={favorite} onLikedOrUnliked={() => { }} />
      ))
    }
    if (listToShow.length === 0 && !isLoading) {
      return (
        <div className="no-response__container">
          <NoResults
            mainText="Sorry, you don't have favorite products"
            secondaryText="No results"
          />
        </div>
      )
    }
    return ''
  }

  const isThereInfo = () => !!listToShow

  const canShowMore =
    favoritesList?.elements?.length >= 1 &&
    favoritesList?.currentPage !== favoritesList?.totalPages

  const shouldShowMoreButton = () => {
    if (canShowMore) {
      return (
        <div className="show-more__container">
          {isLoading ? (
            <LoaderComponent show local />
          ) : (
            <button
              type="button"
              onClick={() => handleNextPage()}
              className="show-more no-shape"
            >
              Show more products...
            </button>
          )}
        </div>
      )
    }
    return null
  }

  return (
    <section className="favorites-view">
      <h5>My Favorites</h5>
      {isThereInfo() && (
        <div className="favorites-view__list-container">
          <div className="favorites-list">{renderList()}</div>
          {shouldShowMoreButton()}
        </div>
      )}
      <LoaderComponent show={isLoading && !canShowMore} local />
    </section>
  )
}

FavoritesList.propTypes = {
  session: PropTypes.object.isRequired,
  favoritesList: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isProductDetailLoading: PropTypes.bool.isRequired,
  unlikeProductIdSuccess: PropTypes.number.isRequired,
}

function mapStateToProps({ login, favoritesList, productDetails }) {
  return {
    session: login.login,
    favoritesList: favoritesList.favoritesList,
    isLoading: favoritesList.favoritesListIsLoading,
    isProductDetailLoading: productDetails.productDetailsIsLoading,
    unlikeProductIdSuccess: productDetails.unlikeProductIdSuccess,
  }
}

export default connect(mapStateToProps)(FavoritesList)
