import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import NumericSelector from '../../../components/Inputs/NumericSelector'
import IconButton from '../../../components/Buttons/IconButton'

import Icon from '../../../components/Icon'
import { noop } from '../../../utils'
import { roundToDecimals } from '../../../utils/productUtils'

function CartProductRow(props) {
  const {
    product: {
      image,
      title,
      price,
      variantId,
      quantity,
      productOptionsJSON,
      stock,
      externalProductId,
    },
    mobile,
    altBackground,
    onQuantityIncrement,
    onQuantityDecrement,
    onQuantityChange,
    onDelete,
    showActionButtons = true,
    onClick,
    preview,
  } = props

  // eslint-disable-next-line react/no-unstable-nested-components
  const TrashBtn = () => (
    <div className="options-buttons-container">
      <IconButton
        action={() =>
          onDelete({
            externalProductId,
            variantId,
            price,
            title,
            quantity,
            productOptionsJSON,
          })
        }
        component={<Icon item="trash" width="25px" height="25px" />}
        secondary
        noBorders
      />
    </div>
  )

  const formatOptions = (optionsJson) =>
    JSON.parse(optionsJson || '[]')
      .reduce((acum, curr) => `${acum} ${curr.name}: ${curr.value} - `, '')
      .slice(0, -3)

  const [formattedOptions, setFormattedOptions] = useState(
    formatOptions(productOptionsJSON)
  )

  useEffect(() => {
    setFormattedOptions(formatOptions(productOptionsJSON))
  }, [productOptionsJSON])

  return (
    <div
      className={`cart-product-row-container ${preview ? 'preview' : ''}`}
      altBackground={altBackground ? 'true' : undefined}
      onClick={onClick}
      role="none"
    >
      <div className="cart-product-row-container__left-container">
        <img src={image} className="image-container" alt="Product main" />
        <div className="title-container">
          <h4 className="product-title">{title}</h4>
          {!formattedOptions.match('Default Title') && (
            <p>{formattedOptions}</p>
          )}
          {mobile && (
            <h2 className="price">
              <span>{`$${roundToDecimals(price * quantity)}`}</span>
            </h2>
          )}
        </div>
      </div>
      <div className="cart-product-row-container__right-container">
        <div className="quantity-container">
          {showActionButtons && mobile && TrashBtn()}
          <NumericSelector
            minValue={1}
            maxValue={stock}
            onChange={(newQuantity) => onQuantityChange(newQuantity)}
            onDecrement={() => onQuantityDecrement(quantity - 1)}
            onIncrement={() => onQuantityIncrement(quantity + 1)}
            value={quantity}
          />
        </div>
        {!mobile && (
          <h2 className="price">
            <span>{`$${roundToDecimals(price * quantity)}`}</span>
          </h2>
        )}
        {showActionButtons && !mobile && TrashBtn()}
      </div>
    </div>
  )
}

CartProductRow.propTypes = {
  product: PropTypes.shape({
    variantId: PropTypes.string,
    externalProductId: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    nextDelivery: PropTypes.string,
    price: PropTypes.number,
    priceNoDiscount: PropTypes.number,
    taxes: PropTypes.number,
    quantity: PropTypes.number,
    productOptionsJSON: PropTypes.string,
    stock: PropTypes.number,
  }).isRequired,
  mobile: PropTypes.bool.isRequired,
  altBackground: PropTypes.bool,
  onQuantityIncrement: PropTypes.func.isRequired,
  onQuantityDecrement: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  showActionButtons: PropTypes.bool,
  onClick: PropTypes.func,
  preview: PropTypes.bool,
}

CartProductRow.defaultProps = {
  altBackground: false,
  showActionButtons: true,
  onClick: noop,
  preview: false,
}

export default CartProductRow
