import React, { useState } from 'react'
import { Pagination, PaginationItem } from 'reactstrap'
import PropTypes from 'prop-types'

function PaginationComponent({ props }) {
  const { currentPage, totalPages, handleNextPage, handlePreviousPage } = props
  const [page, setPage] = useState(currentPage)

  const nextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1)
      handleNextPage(page + 1)
    }
  }

  const previusPage = () => {
    if (page > 0) {
      setPage(page - 1)
      handlePreviousPage(page - 1)
    }
  }

  const handleCurrent = (id) => {
    setPage(id)
    handleNextPage(id)
  }

  const showPages = () => {
    const arrToShow = [...Array(totalPages)]
    return arrToShow.map((item, idx) => (
      <PaginationItem active={idx === currentPage}>
        <button
          type="button"
          className="page-link no-shape"
          onClick={() => handleCurrent(idx)}
        >
          {idx + 1}
        </button>
      </PaginationItem>
    ))
  }

  if (totalPages <= 1) {
    return null
  }

  return (
    <Pagination className="pagination-component" aria-label="Page navigation">
      {totalPages > 3 && (
        <PaginationItem>
          <button
            type="button"
            className="page-link no-shape"
            onClick={() => handleCurrent(0)}
          >
            {'<<'}
          </button>
        </PaginationItem>
      )}
      {totalPages > 1 && (
        <PaginationItem>
          <button
            type="button"
            className="page-link no-shape"
            onClick={() => previusPage()}
          >
            {'<'}
          </button>
        </PaginationItem>
      )}
      {showPages()}
      {totalPages > 1 && (
        <PaginationItem>
          <button
            type="button"
            className="page-link no-shape"
            onClick={() => nextPage()}
          >
            {'>'}
          </button>
        </PaginationItem>
      )}
      {totalPages > 3 && (
        <PaginationItem>
          <button
            type="button"
            className="page-link no-shape"
            onClick={() => handleCurrent(totalPages - 1)}
          >
            {'>>'}
          </button>
        </PaginationItem>
      )}
    </Pagination>
  )
}

PaginationComponent.propTypes = {
  props: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handleNextPage: PropTypes.func.isRequired,
  handlePreviousPage: PropTypes.func.isRequired,
}

export default PaginationComponent
