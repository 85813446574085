import analytics from './analytics'

const { REACT_APP_ENVIRONMENT = 'localhost' } = process.env

export const mobileBreakpointPx = 1000
export const isMobile = () => window.innerWidth <= mobileBreakpointPx
export const widthScreen = () => window.outerWidth
/**
 * Dummy function meant to do nothing
 */
export const noop = () => {}

// Function to  truncate strings//
export const truncateStr = (str, long) =>
  str?.length > long ? `${str.slice(0, long)}...` : str

export const getCoords = (elementId) => {
  const elem = document.getElementById(elementId)
  const box = elem.getBoundingClientRect()
  const { top, left } = box
  return { top: Math.round(top), left: Math.round(left) }
}

export const doElementScrolls = (elementId) => {
  const elem = document.getElementById(elementId)
  return elem?.clientHeight <= elem?.scrollHeight
}

const DEFAULT_PAGE_TITLE = ' - SpacePlace'
let addedUserInfoToAnalytics = false
export const setPageTitleAndUserInfo = (
  title = '',
  userId = '',
  userName = '',
  userEmail = ''
) => {
  const newTitle = `${title}${DEFAULT_PAGE_TITLE}`
  document.title = newTitle

  if (REACT_APP_ENVIRONMENT !== 'production') return

  analytics.setPageTitle(title)
  if (!addedUserInfoToAnalytics && userId) {
    addedUserInfoToAnalytics = true
    analytics.addUserInfo(userId, userName, userEmail)
  }
}

export const extractSessionInfo = (sessionObj) => ({
  userId: sessionObj?.user?.cognitoUsername || null,
  userName: sessionObj?.user?.name || null,
  userEmail: sessionObj?.user?.email || null,
  userType: sessionObj?.user?.userType || null,
  isFirstTimeLogin: sessionObj?.user?.isFirstTimeLogin || null,

  jwt: sessionObj?.jwtToken || '',
})

export const addQueryStringParamToUrl = (url, key, value) =>
  `${url}&${key}=${value}`

export const NotImplemented = () => {
  throw new Error('Not implemented')
}
