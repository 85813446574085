import { useParams } from 'react-router-dom'

/**
 * Custom hook to extract relevant guest information from params in the url. Component needs to have a route
 * defined with params inside the route like: /:propertyID/:propertyName/...
 * @returns object with { isGuest: is guest or not (boolean), propertyId: id of property, propertyName: name of property, baseRoute: base route built either for guest or host }
 */
function useGuest() {
  const params = useParams()
  const { propertyID: propertyId, propertyName } = params
  const isGuest = !!propertyId

  let baseRoute = ''
  if (isGuest) baseRoute += `/${propertyId}/${propertyName}`

  return { isGuest, propertyId, propertyName, baseRoute }
}

export default useGuest
