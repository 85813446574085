/* eslint default-param-last: off */
import {
  GET_MENU_STARTED,
  GET_MENU_SUCCESS,
  GET_MENU_FAILED,
} from '../constants'

export const initialState = {
  getMenuItems: [],
  getMenuItemsStarted: false,
  getMenuItemsSuccess: false,
  getMenuItemsIsLoading: false,
  getMenuItemsFailed: false,
  getMenuItemsError: '',
}

const getMenuItemsReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_MENU_STARTED: {
      return {
        ...state,
        getMenuItemsStarted: true,
        getMenuItemsIsLoading: true,
        getMenuItemsError: '',
      }
    }
    case GET_MENU_SUCCESS: {
      return {
        ...state,
        getMenuItemsSuccess: true,
        getMenuItemsIsLoading: false,
        getMenuItems: payload,
      }
    }
    case GET_MENU_FAILED: {
      return {
        ...state,
        getMenuItemsFailed: true,
        getMenuItemsIsLoading: false,
        getMenuItemsError: payload.errorMessage,
      }
    }
    default:
      return state
  }
}

export default getMenuItemsReducer
