import { Country, State, City } from 'country-state-city'
import _ from 'lodash'

export const states = (countryCode = 'US') => {
  const statesArr = State.getStatesOfCountry(countryCode)
  const arr = []
  statesArr.map((state) =>
    arr.push({
      text: state.name,
      keyx: state.isoCode,
    })
  )
  return arr
}

export const cities = (countryCode = 'US', stateCode = 'AL') => {
  const citiesArr = City.getCitiesOfState(countryCode, stateCode)
  const arr = []
  citiesArr.map((city) =>
    arr.push({
      text: city.name,
      keyx: city.name,
    })
  )
  return arr
}

export const countries = () => {
  const countriesArr = Country.getAllCountries()
  const arr = []
  countriesArr.map((country) =>
    arr.push({
      text: country.name,
      keyx: country.isoCode,
    })
  )
  return arr
}

export const getState = (stateCode, countryCode = 'US') => {
  const state = State.getStateByCodeAndCountry(stateCode, countryCode)
  return state.name
}

export const getStateCode = (stateName, countryCode = 'US') => {
  const statesArrCodes = State.getStatesOfCountry(countryCode)
  const stateCode = _.filter(statesArrCodes, ['name', stateName])
  return stateCode[0].isoCode
}
