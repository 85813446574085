import { combineReducers } from 'redux'
import signUp from './SignUpReducers'
import login from './LoginReducers'
import addProperty from './AddPropertyReducers'
import breadCrumb from './BreadCrumbReducers'
import propertyDetails from './PropertyDetailsReducers'
import purchasePagination from './PurchasePaginationReducer'
import propertyList from './PropertyListReducers'
import productList from './CatalogReducers'
import productDetails from './ProductDetailsReducer'
import cart from './CartReducer'
import guestCart from './GuestCartReducer'
import editProperty from './EditPropertyReducers'
import order from './OrderReducer'
import orders from './OrdersReducers'
import orderDetails from './OrdersDetailsReducers'
import resetPassword from './ResetPasswordReducer'
import favoritesList from './FavoritesListReducers'
import savedList from './SavedListReducer'
import menuItems from './LayoutReducers'
import hostActiveProductFilters from './HostActiveProductFiltersReducer'
import guestActiveProductFilters from './GuestActiveProductFiltersReducer'

/**
 * Array of state object names that will not be persisted
 */
export const statePersistBlacklist = [
  'hostActiveProductFilters',
  'guestActiveProductFilters',
]

export default combineReducers({
  signUp,
  login,
  addProperty,
  breadCrumb,
  propertyDetails,
  purchasePagination,
  propertyList,
  productList,
  productDetails,
  cart,
  editProperty,
  order,
  orders,
  orderDetails,
  resetPassword,
  favoritesList,
  guestCart,
  savedList,
  menuItems,
  hostActiveProductFilters,
  guestActiveProductFilters,
})
