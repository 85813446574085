import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import CartActions from '../../../redux/actions/CartActions'
import {
  AddProductToSaved,
  DeleteProductSaved,
} from '../../../redux/actions/SavedListActions'
import ProductDetailsActions from '../../../redux/actions/ProductDetailsAction'
import { AddProductToGuestCart } from '../../../redux/actions/GuestCartActions'

import ComparisonFullPrice from '../../../components/ComparisonFullPrice'
import Icon from '../../../components/Icon'

import { extractSessionInfo, isMobile, noop, truncateStr } from '../../../utils'
import notifyToast from '../../../utils/notifyToast'
import { roundToDecimals } from '../../../utils/productUtils'
import analytics from '../../../utils/analytics'
import useGuest from '../../../hooks/useGuest'
import LoaderComponent from '../../../components/Loader'
import ProductCardTestUtils from '../../../utils/testUtils/ProductCardTestUtils'

const {
  DataIds: {
    PRODUCT_CARD_INFO_DATA_ID: infoDataId,
    PRODUCT_CARD_PRICE_DATA_ID: priceDataId,
  },
} = ProductCardTestUtils

const { AddProductToCart } = CartActions
const { LikeProduct, UnLikeProduct } = ProductDetailsActions

function ProductCard({
  product,
  session,
  likeProductIdSuccess,
  likedProductIds,
  unlikeProductIdSuccess,
  onLikedOrUnliked,
  savedProductList,
  guestCartProduct,
  addedCartProductIds,
}) {
  const navigate = useNavigate()
  const { isGuest, baseRoute } = useGuest()
  const {
    id,
    title,
    image,
    variants,
    rating,
    options,
    like,
    thumbnail,
    vendor,
  } = product || {}
  const [isLiked, setIsLiked] = useState(like)
  const guestSavedProductIds = savedProductList?.map(
    (idProduct) => idProduct.id
  )
  const isLikedSuccessful = likeProductIdSuccess === id
  const isUnlikedSuccessful = unlikeProductIdSuccess === id

  const { jwt } = extractSessionInfo(session)
  const isLoggedIn = !!jwt

  useEffect(() => {
    if (isLikedSuccessful) {
      setIsLiked(true)
    }
    if (isUnlikedSuccessful) {
      setIsLiked(false)
    }
    if (typeof onLikedOrUnliked === 'function') onLikedOrUnliked()
  }, [likeProductIdSuccess, unlikeProductIdSuccess])

  useEffect(() => {
    setIsLiked(product?.like || false)
  }, [product])

  const dispatch = useDispatch()

  const handleRedirect = (e) => {
    e.stopPropagation()
    return navigate(`${baseRoute}/product/${id}/details`)
  }

  const handleHover = (e) => {
    if (options?.some((option) => option?.values?.length > 1)) {
      // Has more than one option to pick from, send to details instead
      handleRedirect(e)
    } else {
      const payload = {
        id,
        variantId: variants[0].id,
        title,
        price: roundToDecimals(variants[0]?.price),
        quantity: 1,
        image,
        token: jwt,
        productOptionsJSON: JSON.stringify(
          options.map(({ name, values }) => ({ name, value: values[0] }))
        ),
        compareAtPrice: roundToDecimals(variants[0]?.compareAtPrice),
      }
      if (variants[0]?.inventoryQuantity > 0) {
        if (!isGuest || isLoggedIn)
          dispatch(
            AddProductToCart({
              ...payload,
              token: jwt,
              isGuest,
              propertyUrl: `${baseRoute}/product/list`,
            })
          )
        if (isGuest && !isLoggedIn) {
          const productCartIds = guestCartProduct?.map(
            (idProduct) => idProduct.variantId
          )
          const validateId = productCartIds?.includes(variants[0].id)
          if (validateId) {
            const productNewQuantity = guestCartProduct
            productNewQuantity.forEach((item) => {
              if (item.variantId === variants[0].id) {
                // eslint-disable-next-line
                item.quantity += 1
                dispatch(AddProductToGuestCart(productNewQuantity))
                analytics.addToCartGuest(
                  item.price,
                  id,
                  title,
                  item.quantity,
                  item.productOptionsJSON,
                  true,
                  item.variantId,
                  `${baseRoute}/product/list`
                )
              }
            })
          } else {
            dispatch(AddProductToGuestCart([...guestCartProduct, payload]))
            analytics.addToCartGuest(
              payload.price,
              payload.id,
              payload.title,
              payload.quantity,
              payload.productOptionsJSON,
              true,
              payload.variantId,
              `${baseRoute}/product/list`
            )
          }
        }
      } else {
        notifyToast('error', 'This product is out of stock')
      }
      e.stopPropagation()
    }
  }
  const handleLike = (e) => {
    e.stopPropagation()
    if (!isGuest || (isGuest && isLoggedIn)) {
      const likeAction = isLiked ? UnLikeProduct : LikeProduct
      dispatch(
        likeAction({
          id,
          title,
          image,
          variants,
          rating,
          options,
          like,
          thumbnail,
          isGuest,
          token: jwt,
          propertyUrl: `${baseRoute}/product/list`,
        })
      )
    }
    if (isGuest && !isLoggedIn) {
      const validateId = guestSavedProductIds?.includes(id)
      if (!validateId) {
        dispatch(AddProductToSaved([...savedProductList, product]))
        analytics.addToSavedProductsGuest(
          `${baseRoute}/product/list`,
          id,
          title
        )
      } else {
        dispatch(DeleteProductSaved(id))
      }
    }
  }

  const handleSaveLikeIcon = () => {
    if (isGuest) {
      if (
        (guestSavedProductIds?.includes(id) && !isLoggedIn) ||
        (isLiked && isLoggedIn)
      ) {
        return 'bookmark-filled'
      }
      return 'bookmark-unfilled'
    }
    if (isLiked) {
      return 'heart-filled'
    }
    return 'heart-unfilled'
  }

  const showLoaderLike = likedProductIds?.includes(id)
  const showLoaderCart = addedCartProductIds?.includes(id)

  const showLoader = showLoaderLike || showLoaderCart
  return (
    <button
      type="button"
      onClick={(e) => handleRedirect(e)}
      className="product-card container"
      data-testid="product-card"
    >
      {showLoader ? (
        <LoaderComponent show={showLoader} local />
      ) : (
        <div>
          {variants && (
            <button
              type="button"
              className="product-card__favorite-btn"
              onClick={handleLike}
            >
              <Icon
                item={handleSaveLikeIcon()}
                width="20px"
                height="20px"
                className="product-card__favorite-btn__icon"
              />
            </button>
          )}
          {variants && !isMobile() && (
            <button
              type="button"
              className="product-card__hover-button"
              onClick={(e) => handleHover(e)}
            >
              {!options?.some((option) => option?.values?.length > 1) &&
              !variants[0]?.inventoryQuantity > 0
                ? 'Out of stock'
                : 'Add to Cart'}
            </button>
          )}
          <figure className="product-card__image">
            <img src={thumbnail || image} alt="product img" loading="lazy" />
          </figure>
          <div sp-data-id={infoDataId} className="product-card__info">
            <p>
              {isMobile() ? truncateStr(vendor, 20) : truncateStr(vendor, 27)}
            </p>
            <p>
              {isMobile() ? truncateStr(title, 23) : truncateStr(title, 27)}
            </p>
            <div className="product-card__info-prices" sp-data-id={priceDataId}>
              {variants && (
                <span className="product-card__info-prices--discount">{`$${roundToDecimals(
                  variants[0]?.price
                )}`}</span>
              )}
              {variants && (
                <ComparisonFullPrice fullPrice={variants[0]?.compareAtPrice} />
              )}
            </div>
          </div>
        </div>
      )}
    </button>
  )
}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  likeProductIdSuccess: PropTypes.number.isRequired,
  likedProductIds: PropTypes.array.isRequired,
  onLikedOrUnliked: PropTypes.func,
  unlikeProductIdSuccess: PropTypes.number.isRequired,
  savedProductList: PropTypes.array,
  guestCartProduct: PropTypes.arrayOf(PropTypes.any).isRequired,
  addedCartProductIds: PropTypes.array.isRequired,
}

ProductCard.defaultProps = {
  onLikedOrUnliked: noop,
  savedProductList: [],
}

const mapStateToProps = ({
  login: { login },
  productDetails: {
    likeProductIdSuccess,
    unlikeProductIdSuccess,
    likedProductIds,
  },
  savedList: { savedProductList },
  guestCart: { cartProducts },
  cart: { addedCartProductIds },
}) => ({
  session: login,
  likeProductIdSuccess,
  unlikeProductIdSuccess,
  likedProductIds,
  savedProductList,
  guestCartProduct: cartProducts,
  addedCartProductIds,
})

export default connect(mapStateToProps)(ProductCard)
