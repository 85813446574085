/* eslint default-param-last: off */
import {
  START_CHECKOUT_STARTED,
  START_CHECKOUT_SUCCESS,
  START_CHECKOUT_FAILURE,
  RESET_CHECKOUT_TOASTS,
} from '../constants'

export const initialState = {
  checkoutOrder: {},
  startCheckoutIsLoading: false,
  startCheckoutError: '',
}

const orderReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case START_CHECKOUT_STARTED: {
      return {
        ...state,
        startCheckoutIsLoading: true,
        startCheckoutError: '',
      }
    }
    case START_CHECKOUT_SUCCESS: {
      return {
        ...state,
        startCheckoutIsLoading: false,
        checkoutOrder: payload.order,
      }
    }
    case START_CHECKOUT_FAILURE: {
      return {
        ...state,
        startCheckoutIsLoading: false,
        startCheckoutError: payload.errorMessage,
      }
    }
    case RESET_CHECKOUT_TOASTS: {
      return {
        ...state,
        startCheckoutIsLoading: false,
        startCheckoutError: '',
      }
    }

    default:
      return state
  }
}

export default orderReducer
