/* eslint-disable no-nested-ternary */
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import Button from '../Buttons/Button'
import Logo from '../../assets/Spaceplace_Full.svg'
import ArrowUp from '../../assets/icons/arrow-up.svg'
import Profile from '../../assets/icons/profile.svg'
import UserNotLogged from '../../assets/icons/user-not-logged.svg'
import useGuest from '../../hooks/useGuest'

export default function MobileHeader({
  ShoppingBagIcon,
  cartProducts,
  handleCartClicked,
  cartIndicatorDivId,
  isOpen,
  setIsOpen,
  navigate,
  handleLogOut,
  login,
  headersNoAuth,
  location,
}) {
  const { isGuest, propertyId, propertyName } = useGuest()
  const locName = location.pathname === '/'
  const dropdownIcon = login?.jwtToken ? Profile : UserNotLogged
  const setActiveDropdownItem = (urlKeyword) =>
    location.pathname.includes(urlKeyword) ? 'active-item' : ''
  // eslint-disable-next-line react/no-unstable-nested-components
  const CommonOpt = () => (
    <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <DropdownToggle nav>
        <button type="button" className="dropdown-icon">
          <img
            src={isOpen && !isGuest ? ArrowUp : dropdownIcon}
            alt="Profile"
          />
        </button>
      </DropdownToggle>
      <DropdownMenu container="body" className="header__nav--menu-header">
        <DropdownItem
          onClick={() =>
            navigate(
              isGuest
                ? `/${propertyId}/${propertyName}/product/list`
                : '/product/list'
            )
          }
          className={setActiveDropdownItem('product')}
        >
          {isGuest ? 'Catalog' : 'Shop'}
        </DropdownItem>
        {!isGuest && (
          <DropdownItem
            onClick={() => navigate('/property/list')}
            className={setActiveDropdownItem('property')}
          >
            My Properties
          </DropdownItem>
        )}
        {!isGuest && (
          <DropdownItem
            onClick={() => navigate('/orders')}
            className={setActiveDropdownItem('orders')}
          >
            My Orders
          </DropdownItem>
        )}
        {isGuest && login?.jwtToken && (
          <DropdownItem
            onClick={() => navigate(`/${propertyId}/${propertyName}/orders`)}
            className={setActiveDropdownItem('orders')}
          >
            Orders
          </DropdownItem>
        )}
        <DropdownItem
          onClick={() => {
            navigate(
              isGuest ? `/${propertyId}/${propertyName}/saved` : '/favorites'
            )
          }}
          className={
            isGuest
              ? setActiveDropdownItem('saved')
              : setActiveDropdownItem('favorites')
          }
        >
          {isGuest ? 'Saved Products' : 'My Favorites'}
        </DropdownItem>
        {((!isGuest && login?.jwtToken) || (isGuest && login?.jwtToken)) && (
          <DropdownItem onClick={() => handleLogOut()} className="logout-btn">
            Log out
          </DropdownItem>
        )}
        {isGuest && !login?.jwtToken && (
          <DropdownItem
            onClick={() => navigate(`/${propertyId}/${propertyName}`)}
          >
            Log in
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )

  // eslint-disable-next-line react/no-unstable-nested-components
  const UnLoggedInOpt = () => (
    <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <DropdownToggle nav>
        <button type="button" className="dropdown-icon">
          <img
            src={isOpen && !isGuest ? ArrowUp : UserNotLogged}
            alt="Profile"
          />
        </button>
      </DropdownToggle>

      <DropdownMenu container="body" className="header__nav--menu-header">
        {headersNoAuth.map((item) => (
          <DropdownItem>
            <a href={item.url} className="header__nav--anchor">
              {item.text}
            </a>
          </DropdownItem>
        ))}
        <div className="header__menu-action-btn">
          <Button
            id="login"
            action={() => navigate('/')}
            text="Log in"
            className="header__menu-btn__login"
          />
        </div>
      </DropdownMenu>
    </Dropdown>
  )
  return (
    <nav className="header__menu">
      <Link
        className="header-logo"
        to={
          !isGuest
            ? '/product/list'
            : `/${propertyId}/${propertyName}/product/list`
        }
      >
        <img src={Logo} alt="logo" />
      </Link>
      <div className="header__nav">
        {(login?.jwtToken || isGuest) && (
          <button
            type="button"
            className="shopping-cart-indicator"
            id={cartIndicatorDivId}
          >
            <div
              onClick={() => handleCartClicked()}
              role="none"
              className="shopping-cart-link"
            >
              <img
                className="icon"
                src={ShoppingBagIcon}
                alt="Shopping cart icon"
              />
              {cartProducts !== 0 && (
                <span className="badge">{cartProducts}</span>
              )}
            </div>
          </button>
        )}

        <div className="header__nav--dropdown">
          {((!isGuest && login?.jwtToken) ||
            (isGuest && login?.jwtToken) ||
            (isGuest && !login?.jwtToken)) &&
            CommonOpt()}
          {locName && !login?.jwtToken && UnLoggedInOpt()}
        </div>
      </div>
    </nav>
  )
}

MobileHeader.propTypes = {
  handleCartClicked: PropTypes.func.isRequired,
  ShoppingBagIcon: PropTypes.string.isRequired,
  cartProducts: PropTypes.array.isRequired,
  cartIndicatorDivId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  handleLogOut: PropTypes.func.isRequired,
  login: PropTypes.object.isRequired,
  headersNoAuth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}
