/* eslint default-param-last: off */
import {
  GET_FAVORITES_LIST_STARTED,
  GET_FAVORITES_LIST_SUCCESS,
  GET_FAVORITES_LIST_FAILURE,
  CLEAR_FAVORITES_LIST,
} from '../constants'

export const favoritesListInitialState = {
  favoritesList: {},
  favoritesListStarted: false,
  favoritesListSuccess: false,
  favoritesListIsLoading: false,
  favoritesListFailed: false,
  favoritesListError: '',
}

export const initialState = {
  ...favoritesListInitialState,
}

const favoritesListReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_FAVORITES_LIST_STARTED: {
      return {
        ...state,
        favoritesListStarted: true,
        favoritesListIsLoading: true,
        favoritesListError: '',
      }
    }
    case GET_FAVORITES_LIST_SUCCESS: {
      return {
        ...state,
        favoritesListSuccess: true,
        favoritesList: payload,
        favoritesListIsLoading: false,
      }
    }
    case GET_FAVORITES_LIST_FAILURE: {
      return {
        ...state,
        favoritesListFailed: true,
        favoritesListError: payload.errorMessage,
        favoritesListIsLoading: false,
      }
    }
    case CLEAR_FAVORITES_LIST: {
      return {
        ...state,
        ...favoritesListInitialState,
      }
    }
    default:
      return state
  }
}

export default favoritesListReducer
