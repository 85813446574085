import { useState, useEffect } from 'react'
import { isMobile, mobileBreakpointPx, widthScreen } from '../utils'

function useHandleMobile() {
  const [mobile, setMobile] = useState(false)
  const [showSideFilter, setShowSideFilter] = useState(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (widthScreen() <= mobileBreakpointPx) {
      setMobile(true)
    }
    // eslint-disable-next-line no-use-before-define
    window.addEventListener('resize', updateWidth)
    // eslint-disable-next-line no-use-before-define
    return () => window.removeEventListener('resize', updateWidth)
  })
  const updateWidth = () => {
    if (isMobile()) {
      setMobile(true)
    } else {
      setMobile(false)
      setShowSideFilter(false)
    }
  }
  return { mobile, showSideFilter }
}

export default useHandleMobile
