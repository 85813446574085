/* eslint default-param-last: off */
import {
  ADD_TO_CART_STARTED,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  GET_USER_CART_STARTED,
  GET_USER_CART_SUCCESS,
  GET_USER_CART_FAILURE,
  DELETE_CART_PRODUCT_STARTED,
  DELETE_CART_PRODUCT_SUCCESS,
  DELETE_CART_PRODUCT_FAILURE,
  UPDATE_CART_PRODUCT_QUANTITY_STARTED,
  UPDATE_CART_PRODUCT_QUANTITY_SUCCESS,
  UPDATE_CART_PRODUCT_QUANTITY_FAILURE,
  RESET_USER_CART,
  RESET_CART_TOASTS,
} from '../constants'

export const initialState = {
  cartProducts: [],
  addCartIsLoading: false,
  getCartIsLoading: false,
  deleteCartProductIsLoading: false,
  updateCartProductQuantityIsLoading: false,
  addedCartProductIds: [],
  addCartError: '',
  getCartError: '',
  addCartProductSuccess: false,
  deleteCartProductSuccess: false,
  deleteCartProductError: '',
  updateCartProductQuantityError: '',
}

const productWithSubtotal = (product) => ({
  ...product,
  subtotal: product.quantity * product.price || 0,
})

/**
 * Helper method to add a product to the cart items of the user.
 * @param {Product} newProduct - Product to add to the cart
 * @param {Array<Product>} currentCartProducts - Current state of the cart products of the user
 * @returns Array of products with the new product added
 */
const getArrayWithNewCartItem = (newProduct, currentCartProducts) => {
  if (
    !newProduct ||
    !newProduct.variantId ||
    !Array.isArray(currentCartProducts)
  ) {
    return currentCartProducts
  }

  const newCartProducts = [...currentCartProducts]

  const existingProductIndex = currentCartProducts?.findIndex(
    (product) => product.variantId === newProduct.variantId
  )
  if (existingProductIndex !== -1) {
    // product already exists
    const existingProduct = currentCartProducts[existingProductIndex]
    newCartProducts[existingProductIndex] = productWithSubtotal({
      ...existingProduct,
      quantity: newProduct.quantity,
    })
  } else {
    newCartProducts?.push(productWithSubtotal(newProduct))
  }

  return newCartProducts
}

const addCartId = (cartId, state) => {
  if (!state?.addedCartProductIds) return [cartId]
  const containsId = state?.addedCartProductIds?.find((id) => id === cartId)
  if (containsId) return state.addedCartProductIds

  return [...state.addedCartProductIds, cartId]
}

const removeCartId = (cartId, state) =>
  state.addedCartProductIds?.filter((id) => id !== cartId)

const cartReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case RESET_CART_TOASTS: {
      return {
        ...state,
        addCartIsLoading: false,
        addCartProductSuccess: false,
        deleteCartProductSuccess: false,
        addCartError: '',
        deleteCartProductError: '',
        updateCartProductQuantityError: '',
      }
    }
    case ADD_TO_CART_STARTED: {
      return {
        ...state,
        addCartIsLoading: true,
        addCartProductSuccess: false,
        addCartError: '',
        addedCartProductIds: addCartId(payload.id, state),
      }
    }
    case ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        addCartIsLoading: false,
        addCartProductSuccess: true,
        cartProducts: getArrayWithNewCartItem(payload, state.cartProducts),
        addedCartProductIds: removeCartId(payload.id, state),
      }
    }
    case ADD_TO_CART_FAILURE: {
      return {
        ...state,
        addCartIsLoading: false,
        addCartError: payload.errorMessage,
        addedCartProductIds: removeCartId(payload.initialPayload.id, state),
      }
    }
    case GET_USER_CART_STARTED: {
      return {
        ...state,
        deleteCartProductSuccess: false,
        addCartProductSuccess: false,
        getCartIsLoading: true,
        getCartError: '',
        addCartError: '',
      }
    }
    case GET_USER_CART_SUCCESS: {
      return {
        ...state,
        getCartIsLoading: false,
        cartProducts: payload?.map((product) => productWithSubtotal(product)),
      }
    }
    case GET_USER_CART_FAILURE: {
      return {
        ...state,
        getCartIsLoading: false,
        getCartError: payload.errorMessage,
      }
    }

    case DELETE_CART_PRODUCT_STARTED: {
      return {
        ...state,
        deleteCartProductSuccess: false,
        deleteCartProductIsLoading: true,
        deleteCartProductError: '',
      }
    }
    case DELETE_CART_PRODUCT_SUCCESS: {
      return {
        ...state,
        deleteCartProductIsLoading: false,
        deleteCartProductSuccess: true,
        cartProducts: state.cartProducts?.filter(
          (product) => product.variantId !== payload.variantId
        ),
      }
    }
    case DELETE_CART_PRODUCT_FAILURE: {
      return {
        ...state,
        deleteCartProductIsLoading: false,
        deleteCartProductError: payload.errorMessage,
      }
    }

    case UPDATE_CART_PRODUCT_QUANTITY_STARTED: {
      return {
        ...state,
        updateCartProductQuantityIsLoading: true,
        updateCartProductQuantityError: '',
      }
    }
    case UPDATE_CART_PRODUCT_QUANTITY_SUCCESS: {
      return {
        ...state,
        updateCartProductQuantityIsLoading: false,
        cartProducts: state.cartProducts?.map((product) =>
          product.variantId === payload.variantId
            ? productWithSubtotal({ ...product, quantity: payload.quantity })
            : product
        ),
      }
    }
    case UPDATE_CART_PRODUCT_QUANTITY_FAILURE: {
      return {
        ...state,
        updateCartProductQuantityIsLoading: false,
        updateCartProductQuantityError: payload.errorMessage,
      }
    }

    case RESET_USER_CART: {
      return {
        ...initialState,
        addedCartProductIds: state.addedCartProductIds || [],
      }
    }

    default:
      return state
  }
}

export default cartReducer
