import { addQueryStringParamToUrl } from '../utils'
import Recreatable from '../utils/BaseClasses/Recreatable'

export const defaultGuestProductFilters = Object.freeze({
  size: 10,
  visited: 0,
  propertyId: '0',
  hasNextPage: false,
})

export default class GuestProductFilters extends Recreatable {
  size

  visited

  propertyId

  hasNextPage

  get filters() {
    return {
      size: this.size,
      visited: this.visited,
      propertyId: this.propertyId,
      hasNextPage: this.hasNextPage,
    }
  }

  constructor(size, visited, propertyId, hasNextPage) {
    super()
    this.size = size || defaultGuestProductFilters.size
    this.visited = visited || defaultGuestProductFilters.visited
    this.propertyId = propertyId || defaultGuestProductFilters.propertyId
    this.hasNextPage = hasNextPage || defaultGuestProductFilters.hasNextPage
  }

  updatePropertyId(propertyId = defaultGuestProductFilters.propertyId) {
    this.propertyId = propertyId
  }

  updateHasNextPage(hasNextPage = defaultGuestProductFilters.hasNextPage) {
    this.hasNextPage = hasNextPage
  }

  // TODO: Pending refactor of this metric to make it more robust - capture view by session, date-time, etc. Recommended to use AWS Cloudwatch or a similar tool
  updateVisited(visited = defaultGuestProductFilters.visited) {
    this.visited = visited
  }

  /**
   * translates current class filters into a url so API can filter products accordingly. Some filters require special logic for which extra methods were created e.g. propertyId
   * @returns url string with the filters as parameters
   */
  filtersToApiStr() {
    let route = `/property/products/guest/${this.propertyId}?`
    Object.keys(defaultGuestProductFilters).forEach((key) => {
      // Skip propertyId and hasNextPage - not a real query string param for the api call
      if (key === 'propertyId') return
      if (key === 'hasNextPage') return
      // Skip page - will be handled by library
      if (key === 'page') return

      route = addQueryStringParamToUrl(route, key, this[key])
    })

    return route
  }

  output() {
    return this.filters
  }

  static fromFilters(filterProps) {
    return new GuestProductFilters(
      filterProps.size,
      filterProps.visited,
      filterProps.propertyId,
      filterProps.hasNextPage
    )
  }

  static reCreate(filterProps) {
    return GuestProductFilters.fromFilters(filterProps)
  }
}
