import {
  handleActionCatch,
  validateServerResponse,
} from '../../utils/errorUtils'
import {
  ADD_TO_CART_STARTED,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  GET_USER_CART_STARTED,
  GET_USER_CART_SUCCESS,
  GET_USER_CART_FAILURE,
  DELETE_CART_PRODUCT_STARTED,
  DELETE_CART_PRODUCT_SUCCESS,
  DELETE_CART_PRODUCT_FAILURE,
  UPDATE_CART_PRODUCT_QUANTITY_STARTED,
  UPDATE_CART_PRODUCT_QUANTITY_SUCCESS,
  UPDATE_CART_PRODUCT_QUANTITY_FAILURE,
  RESET_USER_CART,
  RESET_CART_TOASTS,
} from '../constants'
import api from '../../utils/api'
import analytics from '../../utils/analytics'
import notifyToast from '../../utils/notifyToast'

export const AddProductToCartStarted = (payload) => ({
  type: ADD_TO_CART_STARTED,
  payload,
})
export const AddProductToCartSuccess = (payload) => {
  if (payload.isGuest) {
    analytics.addToCartGuest(
      payload.price,
      payload.externalProductId,
      payload.title,
      payload.quantity,
      payload.productOptionsJSON,
      payload.isGuest,
      payload.variantId,
      payload.propertyUrl
    )
  } else {
    analytics.addToCart(
      payload.price,
      payload.externalProductId,
      payload.title,
      payload.quantity,
      payload.productOptionsJSON,
      payload.isGuest,
      payload.variantId
    )
  }
  notifyToast('success', 'Product added to the cart', 2000)
  return { type: ADD_TO_CART_SUCCESS, payload }
}
export const AddProductToCartFailure = (payload) => {
  notifyToast('error', payload.addCartError, 2000)
  return { type: ADD_TO_CART_FAILURE, payload }
}

export const GetShoppingCartStarted = (payload) => ({
  type: GET_USER_CART_STARTED,
  ...payload,
})
export const GetShoppingCartSuccess = (payload) => ({
  type: GET_USER_CART_SUCCESS,
  payload,
})
export const GetShoppingCartFailure = (payload) => ({
  type: GET_USER_CART_FAILURE,
  payload,
})

export const DeleteCartProductStarted = (payload) => ({
  type: DELETE_CART_PRODUCT_STARTED,
  ...payload,
})
export const DeleteCartProductSuccess = (payload) => {
  if (payload.isGuest) {
    analytics.removeFromCartGuest(
      payload.price,
      payload.externalProductId,
      payload.title,
      payload.quantity,
      payload.productOptionsJSON,
      payload.isGuest,
      payload.variantId,
      payload.propertyUrl
    )
  } else {
    analytics.removeFromCart(
      payload.price,
      payload.externalProductId,
      payload.title,
      payload.quantity,
      payload.productOptionsJSON,
      payload.isGuest,
      payload.variantId
    )
  }
  notifyToast(
    'success',
    'The product has been successfully removed from the cart'
  )
  return { type: DELETE_CART_PRODUCT_SUCCESS, payload }
}
export const DeleteCartProductFailure = (payload) => ({
  type: DELETE_CART_PRODUCT_FAILURE,
  payload,
})

export const UpdateCartProductQuantityStarted = (payload) => ({
  type: UPDATE_CART_PRODUCT_QUANTITY_STARTED,
  ...payload,
})
export const UpdateCartProductQuantitySuccess = (payload) => ({
  type: UPDATE_CART_PRODUCT_QUANTITY_SUCCESS,
  payload,
})
export const UpdateCartProductQuantityFailure = (payload) => ({
  type: UPDATE_CART_PRODUCT_QUANTITY_FAILURE,
  payload,
})

export const ResetUserCart = () => ({ type: RESET_USER_CART })
export const ResetCartToasts = () => ({ type: RESET_CART_TOASTS })

const AddProductToCart = (payload) => async (dispatch) => {
  dispatch(AddProductToCartStarted(payload))
  try {
    const {
      id: externalProductId,
      variantId,
      quantity,
      image,
      price,
      title,
      token,
      productOptionsJSON,
      compareAtPrice,
    } = payload

    const compareAtPriceNum =
      compareAtPrice && compareAtPrice !== '0' && compareAtPrice !== '0.00'
        ? compareAtPrice
        : 0

    const result = await api.Post(
      '/shoppingCart',
      {
        externalProductId,
        variantId,
        quantity,
        image,
        price,
        title,
        productOptionsJSON,
        compareAtPrice: compareAtPriceNum,
      },
      token
    )

    validateServerResponse(result)
    dispatch(AddProductToCartSuccess({ ...result.data.data, ...payload }))
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      AddProductToCartFailure,
      'add product to cart',
      payload
    )
  }
  dispatch(ResetCartToasts())
}

const GetShoppingCart = (payload) => async (dispatch) => {
  dispatch(GetShoppingCartStarted(payload))
  try {
    const { token } = payload

    const result = await api.Get('/shoppingCart', token)

    validateServerResponse(result)

    dispatch(GetShoppingCartSuccess(result.data.data))
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      GetShoppingCartFailure,
      'get shopping cart',
      payload
    )
  }
}

const DeleteCartItem = (payload) => async (dispatch) => {
  dispatch(DeleteCartProductStarted(payload))
  try {
    const { variantId, token } = payload

    const result = await api.Delete(`/shoppingCart/item/${variantId}`, token)

    validateServerResponse(result)
    dispatch(DeleteCartProductSuccess(payload))
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      DeleteCartProductFailure,
      'delete cart item',
      payload
    )
  }
  dispatch(ResetCartToasts())
}

const UpdateCartItemQuantity = (payload) => async (dispatch) => {
  dispatch(UpdateCartProductQuantityStarted(payload))
  try {
    const { variantId, quantity, token } = payload

    // don't wait for response
    dispatch(UpdateCartProductQuantitySuccess({ variantId, quantity }))

    const result = await api.Patch(
      `/shoppingCart/item/${variantId}`,
      { quantity },
      token
    )

    validateServerResponse(result)
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      UpdateCartProductQuantityFailure,
      'update cart item quantity',
      payload
    )
  }
  dispatch(ResetCartToasts())
}

export default {
  AddProductToCart,
  GetShoppingCart,
  DeleteCartItem,
  UpdateCartItemQuantity,
}
