import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'

import Icon from '../../../components/Icon'
import analytics from '../../../utils/analytics'
import HostActiveProductFiltersActions from '../../../redux/actions/HostActiveProductFiltersActions'
import HostProductFiltersRedux from '../../../redux/connectors/HostProductFiltersRedux'

const { SaveHostProductFilters } = HostActiveProductFiltersActions

function SearchBoxFilter({ hostActiveProductFilters }) {
  const dispatch = useDispatch()

  const productFilters = new HostProductFiltersRedux(
    hostActiveProductFilters,
    dispatch,
    SaveHostProductFilters
  )

  const [wordToSearch, setWordToSearch] = useState(
    productFilters.filters.search
  )

  const handleChange = ({ value }) => setWordToSearch(value)
  const handleSearch = () => {
    productFilters.clearSideFilters()
    productFilters.clearMenuFilters()
    productFilters.updateSearchBoxFilter({
      search: wordToSearch,
    })
    analytics.filterByKeyword(wordToSearch, false)
  }

  const handleKeyDown = ({ key }) => {
    if (key === 'Enter') {
      handleSearch()
    }
  }

  const handleClear = () => {
    setWordToSearch('')
    productFilters.clearSearchBoxFilter()
  }

  return (
    <div className="filter-component cont">
      <button
        type="button"
        className="search-btn"
        onClick={() => handleSearch()}
      >
        <Icon item="search" className="search-icon" />
      </button>
      <input
        type="text"
        placeholder="Find a product ..."
        value={wordToSearch}
        onChange={({ target }) => handleChange(target)}
        onKeyDown={(key) => handleKeyDown(key)}
      />
      {wordToSearch !== '' && (
        <button
          type="button"
          className="close-btn"
          onClick={() => handleClear()}
        >
          <Icon item="close" className="close-icon" />
        </button>
      )}
    </div>
  )
}

SearchBoxFilter.propTypes = {
  hostActiveProductFilters: PropTypes.object.isRequired,
}

const mapStateToProps = ({ hostActiveProductFilters }) => ({
  hostActiveProductFilters,
})

export default connect(mapStateToProps)(SearchBoxFilter)
