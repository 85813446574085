import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import MobileCards from '../../containers/MobileCards'
import ProductRow from './ProductRow'
import DottedHr from '../../components/Separators/DottedHr'
import Hr from '../../components/Separators/Hr'
import { roundToDecimals, shippingCostText } from '../../utils/productUtils'

function MobileOrderDetail({ details }) {
  const {
    shippingAddress1,
    state,
    city,
    zip,
    orderNumber,
    spStatus,
    totalPrice,
    subtotalPrice,
    totalShippingCost,
    totalTax,
    createdAt,
    orderLines,
  } = details
  const orderDate = createdAt ? format(new Date(createdAt), 'yyyy-MM-dd') : null
  const handleProductRow = (item, idx) => {
    const { variantId, image, name: title, price, quantity } = item
    const product = {
      variantId,
      image,
      title,
      price,
      quantity,
    }
    return (
      <tr className="table-row">
        <ProductRow product={product} altBackground={idx % 2 === 1} />
      </tr>
    )
  }
  return (
    <MobileCards key={orderNumber} action={() => {}}>
      <div className="mobile-card__order-number">
        <h5>Order number:</h5>
        <p>{orderNumber}</p>
      </div>
      <DottedHr />
      <div className="mobile-card__info">
        <div className="mobile-card__info-name">
          <h5>Status:</h5>
          <h5>Order date:</h5>
          <h5>Total:</h5>
        </div>
        <div className="mobile-card__info-value">
          <p>{spStatus}</p>
          <p>{orderDate}</p>
          <p>{`$${roundToDecimals(totalPrice)}`}</p>
        </div>
      </div>
      <Hr />
      <div className="mobile-card__all-info-container">
        <h3>Address</h3>
        <div className="mobile-card__address-info mobile-card--border_shape">
          <div className="mobile-card__address-name  mobile-card--margin-shape">
            <h5>Shipping address:</h5>
            <h5>State:</h5>
            <h5>City:</h5>
            <h5>Zip code:</h5>
          </div>
          <div className="mobile-card__address-value  mobile-card--margin-shape">
            <p>{shippingAddress1}</p>
            <p>{state}</p>
            <p>{city}</p>
            <p>{zip}</p>
          </div>
        </div>
      </div>
      <Hr />
      <div className="mobile-card__all-info-container">
        <h3>Products</h3>
        <div className="mobile-card__row-product">
          {orderLines?.map((orderLine, idx) =>
            handleProductRow(orderLine, idx)
          )}
        </div>
      </div>
      <Hr />
      <div className="mobile-card__all-info-container">
        <h3>Summary</h3>
        <div className="mobile-card__summary-info mobile-card--border_shape ">
          <div className="summary-first">
            <div className="mobile-card__summary-name mobile-card--margin-shape">
              <h5>Subtotal:</h5>
              <h5>Shipping:</h5>
              {!!totalTax && <h5>Taxes:</h5>}
            </div>
            <div className="mobile-card__summary-value mobile-card--margin-shape">
              <p>{`$${roundToDecimals(subtotalPrice)}`}</p>
              <p>{shippingCostText(totalShippingCost)}</p>
              {!!totalTax && <p>{`$${roundToDecimals(totalTax)}`}</p>}
            </div>
          </div>
          <DottedHr />
          <div className="mobile-card__total mobile-card--margin-shape">
            <h5>Total:</h5>
            <p>{`$${roundToDecimals(totalPrice)}`}</p>
          </div>
        </div>
      </div>
    </MobileCards>
  )
}
MobileOrderDetail.propTypes = {
  details: PropTypes.object.isRequired,
}
export default MobileOrderDetail
