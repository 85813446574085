/* eslint default-param-last: off */
import {
  SET_PURCHASE_PAGINATION,
  CLEAR_PURCHASE_PAGINATION,
} from '../constants'

const initialState = {
  page: 0,
  purchasePagination: [],
}

const purchasePaginationReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_PURCHASE_PAGINATION: {
      return {
        ...state,
        purchasePagination: payload.elements,
        page: payload.page,
      }
    }
    case CLEAR_PURCHASE_PAGINATION: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}

export default purchasePaginationReducer
