import React from 'react'
import PropTypes from 'prop-types'

function IconButton(props) {
  const {
    className,
    id,
    svg,
    alt,
    disabled,
    secondary,
    tertiary,
    action,
    component,
    noBorders,
  } = props
  return (
    <button
      type="button"
      className={`IconButton ${className}-IconButton`}
      disabled={disabled}
      id={id}
      primary={!(tertiary || secondary) ? 'true' : undefined}
      secondary={secondary ? 'true' : undefined}
      tertiary={tertiary ? 'true' : undefined}
      noBorders={noBorders ? 'true' : undefined}
      onClick={action}
    >
      {svg ? <img src={svg} alt={alt} /> : component}
    </button>
  )
}

IconButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  svg: PropTypes.element,
  component: PropTypes.element,
  alt: PropTypes.string,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  noBorders: PropTypes.bool,
  action: PropTypes.func,
}

IconButton.defaultProps = {
  className: '',
  svg: null,
  component: null,
  alt: 'Icon button',
  disabled: false,
  secondary: false,
  tertiary: false,
  noBorders: false,
  action: () => {},
}

export default IconButton
