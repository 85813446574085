import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Auth } from 'aws-amplify'

import LoaderComponent from '../../components/Loader'
import Logout from '../../redux/actions/LogoutActions'
import logMessage, { LogLevels } from '../../utils/logMessage'

function LogoutPage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const logout = async () => {
    try {
      await Auth.signOut()
      dispatch(Logout())
      navigate('/')
    } catch (ex) {
      logMessage('Logout error', ex, LogLevels.ERROR)
    }
  }

  useEffect(() => {
    logout()
  }, [])

  return <LoaderComponent show />
}

export default LogoutPage
