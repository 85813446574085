import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import MobileCards from '../../containers/MobileCards'
import Icon from '../../components/Icon'
import { roundToDecimals } from '../../utils/productUtils'

function MobilePropertyList({
  copyToClipboard,
  list,
  shouldShowModal,
  handleEdit,
}) {
  const navigate = useNavigate()
  return (
    <section>
      {list.elements.map((item) => (
        <MobileCards
          key={item.id}
          action={() => navigate(`/property/${item.id}/details`)}
        >
          <div className="mobile-card__header">
            <h4> {item.nickName} </h4>
            <span>
              <span
                type="button"
                className="button"
                onClick={(event) => handleEdit(event, item.id)}
                aria-hidden="true"
              >
                <Icon
                  item="edit"
                  width="18px"
                  height="18px"
                  fill="#767980"
                  stroke="#7679807a"
                  className="add_top"
                />
              </span>
              <span
                type="button"
                className="button"
                onClick={(event) => shouldShowModal(event, item.id)}
                aria-hidden="true"
              >
                <Icon
                  item="trash"
                  width="18px"
                  height="18px"
                  fill="#767980"
                  stroke="#7679807a"
                  className="mobile-card__icons"
                />
              </span>
            </span>
          </div>
          <ul className="mobile-card__items">
            <li className="mobile-card__definition">
              <p>Address</p> {item.address}
            </li>
            <li className="mobile-card__definition">
              <p>State</p> {item.state}
            </li>
            <li className="mobile-card__definition">
              <p>City</p> {item.city}
            </li>
            <li className="mobile-card__definition">
              <p>Spent</p> {roundToDecimals(item.spent)}
            </li>
            <li
              className="mobile-card__definition"
              onClick={(event) =>
                copyToClipboard(event, `${item.propertyUrl}/product/list`)
              }
              aria-hidden="true"
            >
              <p>Link</p>{' '}
              <span className="mobile-card__property-link">
                {item.propertyUrl.length > 25
                  ? `${item.propertyUrl.substring(0, 25)}...`
                  : item.propertyUrl}
              </span>
              <Icon
                item="link-icon"
                width="20px"
                height="20px"
                stroke="#0d6efd85"
                className="mobile-card__icons"
              />
            </li>
          </ul>
        </MobileCards>
      ))}
    </section>
  )
}

MobilePropertyList.propTypes = {
  copyToClipboard: PropTypes.func.isRequired,
  shouldShowModal: PropTypes.func.isRequired,
  list: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
}

export default MobilePropertyList
