/* eslint-disable import/no-unresolved */
import React from 'react'
import PropTypes from 'prop-types'

import { Modal } from 'reactstrap'
import { Player } from '@lottiefiles/react-lottie-player'
import LoaderLottie from '../../assets/LoaderLottie.json'

function LoaderComponent({ show, local }) {
  if (!show) return ''
  if (local)
    return (
      <div className="loader-local">
        <Player
          autoplay
          loop
          src={LoaderLottie}
          style={{ height: '40px', width: '40px' }}
        />
      </div>
    )
  return (
    <Modal
      className="loader-modal"
      isOpen={show}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Player
        autoplay
        loop
        src={LoaderLottie}
        style={{ height: '60px', width: '60px' }}
      />
    </Modal>
  )
}

LoaderComponent.propTypes = {
  show: PropTypes.bool,
  local: PropTypes.bool,
}

LoaderComponent.defaultProps = {
  show: false,
  local: false,
}

export default LoaderComponent
