import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Button from '../../../components/Buttons/Button'
import Icon from '../../../components/Icon'
import LoaderComponent from '../../../components/Loader'
import ModalComponent from '../../../components/ModalComponent'
import NoResults from '../../../components/NoResults/NoResults'
import TextArea from '../../../components/TextArea'
import ProductCard from '../ProductCard'

import { reviewProperty } from '../../../redux/actions/CatalogActions'

import { extractSessionInfo, setPageTitleAndUserInfo } from '../../../utils'
import notifyToast from '../../../utils/notifyToast'

import useGuest from '../../../hooks/useGuest'
import useProductFilters from '../../../hooks/catalog/useProductFilters'
import useFetchCatalog from '../../../hooks/catalog/useFetchCatalog'
import analytics from '../../../utils/analytics'

const GuestCatalog = (props) => {
  const { session, guestActiveProductFilters } = props

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isGuest, propertyId, propertyName, baseRoute } = useGuest()
  if (!isGuest) {
    notifyToast('error', 'Not a guest. Redirected to host experience', 2000)
    navigate('/product/list')
  }
  const { userId, userName, userEmail, jwt } = extractSessionInfo(session)

  const [showModal, setShowModal] = useState(false)
  const [propertyReview, setPropertyReview] = useState('')

  useEffect(() => {
    setPageTitleAndUserInfo('Catalog (Guest)', userId, userName, userEmail)
    analytics.viewGuestCatalog(
      `${baseRoute}/product/list`,
      propertyId,
      propertyName
    )
  }, [])

  const productFilters = useProductFilters(guestActiveProductFilters)

  if (productFilters.filters.propertyId !== propertyId)
    productFilters.updatePropertyId(propertyId)

  const {
    data: paginatedProductList,
    error,
    isFetching,
    page: currentPage,
    setPage,
  } = useFetchCatalog(productFilters, jwt)

  useEffect(() => {
    if (error) notifyToast('error', error, 2000)
  }, [error])

  const handlePropertyReviewChange = (value) => setPropertyReview(value.trim())

  const handleSaveReview = () => {
    dispatch(reviewProperty({ propertyId, propertyReview }))
    setShowModal(false)
  }

  const handleCancelReview = () => setShowModal(false)

  const handleShowModal = () => {
    setPropertyReview('')
    setShowModal(true)
  }

  const isPropertyReviewDisabled = propertyReview === ''

  const showProductList = () =>
    paginatedProductList?.map((page) =>
      page?.data?.paginatedProducts?.elements.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))
    )

  const noResultsMessage = () => {
    if (
      Array.isArray(paginatedProductList) &&
      paginatedProductList[0]?.data?.paginatedProducts?.elements?.length ===
        0 &&
      !isFetching
    )
      return (
        <div className="no-response__container-guest">
          <NoResults
            mainText="Sorry, we didn't find any results"
            secondaryText="No results"
          />
        </div>
      )
    return ''
  }

  return (
    <section className="catalog cont-guest">
      <div className="catalog-view-guest">
        <div className="wrapper">
          <h3 className="catalog-title-guest">
            Welcome to <span>{propertyName.replaceAll('-', ' ')}</span>
          </h3>
          <div className="list-show-more-container-guest">
            <div className="catalog-list-guest">
              {Array.isArray(paginatedProductList) && showProductList()}
              {noResultsMessage()}
            </div>

            {isFetching ? (
              <div className="show-more__container-guest">
                <LoaderComponent show local />
              </div>
            ) : null}
            {!isFetching && productFilters.filters.hasNextPage ? (
              <div className="show-more__container-guest">
                <button
                  type="button"
                  onClick={() => setPage(currentPage + 1)}
                  className="show-more no-shape-guest"
                >
                  Show more products...
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <button
        type="button"
        className="catalog-guest_comment"
        onClick={() => handleShowModal()}
      >
        <Icon
          item="comment"
          width="24px"
          height="24px"
          className="catalog-guest_comment-icon"
        />
        Do you want to leave a comment about the property?
      </button>

      {/* (TODO): Move to its own component */}
      <ModalComponent show={showModal} className="add-review">
        <h4>Add A Review</h4>
        <TextArea
          id="comment"
          className="add-review-modal__comment"
          onChange={(e) => handlePropertyReviewChange(e.target.value)}
          placeholder="What do you love about this property?"
        />
        <div className="add-review-modal__buttons">
          <Button
            id="cancelReview"
            secondary
            text="Cancel"
            action={() => handleCancelReview()}
          />
          <Button
            id="saveReview"
            text="Save"
            action={() => handleSaveReview()}
            disabled={isPropertyReviewDisabled}
          />
        </div>
      </ModalComponent>
    </section>
  )
}

GuestCatalog.propTypes = {
  session: PropTypes.shape({
    jwtToken: PropTypes.string,
    user: PropTypes.object,
  }).isRequired,
  guestActiveProductFilters: PropTypes.object.isRequired,
}

const mapStateToProps = ({ login: { login }, guestActiveProductFilters }) => ({
  session: login,
  guestActiveProductFilters,
})

export default connect(mapStateToProps)(GuestCatalog)
