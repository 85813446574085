/* eslint default-param-last: off */

import { defaultHostProductFilters } from '../../domain/HostProductFilters'
import { SAVE_HOST_ACTIVE_PRODUCT_FILTERS } from '../constants'

const initialState = { ...defaultHostProductFilters }

const hostActiveProductFilters = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SAVE_HOST_ACTIVE_PRODUCT_FILTERS:
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}

export default hostActiveProductFilters
