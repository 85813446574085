import logMessage, { LogLevels } from './logMessage'

const PaginationTypes = Object.freeze({
  PAGE_INFO: 'pageInfo',
  PAGE: 'page',
})

/**
 * Helper method to get pagination of products response
 * @param {ArrayOfProducts} paginatedData - array of paginated products
 * @param {PaginationTypes enum} paginationType - type of pagination response
 * @returns object with pagination info { hasNextPage, currentPage }
 */
const getProductsResponsePagination = (
  paginatedData,
  paginationType = PaginationTypes.PAGE_INFO
) => {
  const lastPage = paginatedData.slice(-1)[0]
  let hasNextPage = false
  let current
  if (paginationType === PaginationTypes.PAGE_INFO) {
    hasNextPage = !!lastPage?.data?.pageInfo?.nextPage
    current = lastPage?.data?.pageInfo?.nextPage
  } else if (paginationType === PaginationTypes.PAGE) {
    if (lastPage?.data?.paginatedProducts) {
      const { currentPage = 0, totalPages = 0 } =
        lastPage.data.paginatedProducts
      if (currentPage && totalPages) {
        hasNextPage = totalPages > currentPage
      }
      current = currentPage
    }
  } else {
    logMessage(
      'Wrong pagination specified',
      { paginationType, paginatedData },
      LogLevels.ERROR
    )
  }

  return { hasNextPage, currentPage: current }
}

export default {
  PaginationTypes,
  getProductsResponsePagination,
}
