import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

function ModalComponent(props) {
  const { show, children, modalHeader, modalFooter, className, onMouseLeave } =
    props
  const [isOpen, setIsOpen] = useState(show)

  const handleToggle = () => setIsOpen(false)

  useEffect(() => {
    setIsOpen(show)
  }, [show])

  return (
    <Modal
      className={`${className}-modal modal-cont`}
      isOpen={show || isOpen}
      toggle={() => handleToggle()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      backdropClassName={`${className}-backdrop`}
      modalClassName={`${className}`}
      onMouseLeave={onMouseLeave}
    >
      {modalHeader && (
        <ModalHeader toggle={() => handleToggle()}>{modalHeader}</ModalHeader>
      )}
      <ModalBody>{children}</ModalBody>
      {modalFooter && <ModalFooter>{modalFooter}</ModalFooter>}
    </Modal>
  )
}

ModalComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  show: PropTypes.bool.isRequired,
  modalHeader: PropTypes.element,
  modalFooter: PropTypes.element,
  className: PropTypes.string.isRequired,
  onMouseLeave: PropTypes.func,
}

ModalComponent.defaultProps = {
  modalHeader: null,
  modalFooter: null,
  onMouseLeave: () => {},
}

export default ModalComponent
