const DataIds = {
  BRAND_FILTER_UL: 'sidefilters-brand-filter-ul-data-id',
  PRICE_FILTER_UL: 'sidefilters-price-filter-ul-data-id',
  PRICE_MIN_INPUT: 'sidefilters-min-price-input-data-id',
  PRICE_MAX_INPUT: 'sidefilters-max-price-input-data-id',
  PRICE_FILTER_GO_CLEAR_BUTTON:
    'sidefilters-price-filter-go-clear-button-data-id',
  FILTER_OPTION_LI: 'sidefilters-filter-option-li-data-id',
  CLEAR_ALL_BUTTON: 'sidefilters-clear-all-button-data-id',
}

const BRAND_TEST_NAME = 'Tuxton Home'

const PRICE_TEST_DATA = {
  optionLabel: 'Under $50',
  priceMin: 0,
  priceMax: 50,
}

const PRICE_BOXES_TEST_DATA = {
  priceMin: 25,
  priceMax: 75,
}

export default {
  DataIds,

  BRAND_TEST_NAME,
  PRICE_TEST_DATA,
  PRICE_BOXES_TEST_DATA,
}
