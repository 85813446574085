import React from 'react'
import PropTypes from 'prop-types'

import DottedHr from '../../../components/Separators/DottedHr'
import { roundToDecimals } from '../../../utils/productUtils'

function CartTotalsSummary(props) {
  const {
    product: { subtotal = 0, shipping = 0, taxes = 0, savings = 0 },
  } = props
  const total = subtotal + shipping + taxes
  const shippingText = shipping ? `$${roundToDecimals(shipping)}` : 'Free'
  return (
    <section className="cart-summary-container">
      <div className="title-container">
        <h2 className="summary-title">Summary</h2>
      </div>
      <div className="content-container">
        <div className="cart-summary-line">
          <h4 className="label-semibold">Subtotal</h4>
          <p className="value-semibold">{`$${roundToDecimals(subtotal)}`}</p>
        </div>
        <div className="cart-summary-line">
          <h4 className="label-semibold">Shipping</h4>
          <p className="value-semibold">{shippingText}</p>
        </div>
        {taxes > 0 && (
          <div className="cart-summary-line">
            <h4 className="label-semibold">Taxes</h4>
            <p className="value-semibold">{`$${roundToDecimals(taxes)}`}</p>
          </div>
        )}
        <div className="cart-summary-line">
          <DottedHr />
        </div>
        <div className="cart-summary-line top-space">
          <h4 className="label-bold">Total</h4>
          <p className="value-bold">{`$${roundToDecimals(total)}`}</p>
        </div>
        <div className="cart-summary-line">
          <DottedHr />
        </div>
        <div className="cart-summary-line top-space">
          <h4 className="label-semibold">Savings</h4>
          <p className="value-semibold">{`$${roundToDecimals(savings)}`}</p>
        </div>
      </div>
    </section>
  )
}

CartTotalsSummary.propTypes = {
  product: PropTypes.shape({
    subtotal: PropTypes.number,
    shipping: PropTypes.number,
    taxes: PropTypes.number,
    savings: PropTypes.number,
  }).isRequired,
}

export default CartTotalsSummary
