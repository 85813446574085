import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useSWRConfig } from 'swr'

import LoaderComponent from '../../components/Loader'
import ProductCard from '../Catalog/ProductCard'

import GetFavoritesList, {
  ClearFavoritesList,
} from '../../redux/actions/FavoritesListActions'
import { extractSessionInfo, setPageTitleAndUserInfo } from '../../utils'
import NoResults from '../../components/NoResults/NoResults'

function SavedList({
  session,
  favoritesList,
  isLoading,
  savedProductList,
  isProductDetailLoading,
  unlikeProductIdSuccess,
}) {
  const { cache } = useSWRConfig()
  const dispatch = useDispatch()
  const [listToShow, setListToShow] = useState([])

  const { userId, userName, userEmail, jwt } = extractSessionInfo(session)

  useEffect(
    () =>
      setPageTitleAndUserInfo(
        'Favorites Saved List',
        userId,
        userName,
        userEmail
      ),
    []
  )
  useEffect(() => {
    if (jwt) {
      dispatch(ClearFavoritesList())
      dispatch(GetFavoritesList({ token: jwt, page: 0 }))
    }
  }, [dispatch, jwt])

  useEffect(() => {
    if (favoritesList?.elements?.length >= 1 && favoritesList?.currentPage) {
      const { elements, currentPage } = favoritesList
      if (currentPage > 1 && elements) {
        setListToShow([...listToShow, ...elements])
      } else {
        setListToShow(elements)
      }
    } else {
      setListToShow([])
    }
  }, [favoritesList])

  useEffect(() => {
    if (!isProductDetailLoading && unlikeProductIdSuccess !== 0) {
      if (listToShow?.length === 0) return
      const listToShowCopy = [...listToShow]
      const removedIndex = listToShowCopy?.findIndex(
        (item) => item.id === unlikeProductIdSuccess
      )
      if (removedIndex > -1) {
        listToShowCopy?.splice(removedIndex, 1)
        setListToShow(listToShowCopy)
      }
    }
  }, [isProductDetailLoading, unlikeProductIdSuccess])

  useEffect(() => {
    if (
      unlikeProductIdSuccess &&
      !favoritesList?.elements?.includes(unlikeProductIdSuccess)
    ) {
      // clear cache of the catalogs to avoid discrepancies between local cache
      // and updated backend data
      cache.clear()
    }
  }, [unlikeProductIdSuccess])

  const list = jwt ? listToShow : savedProductList
  const renderList = () => {
    if (list?.length >= 1) {
      return list.map((favorite) => <ProductCard product={favorite} />)
    }
    if (list?.length === 0 && !isLoading) {
      return (
        <div className="no-response__container">
          <NoResults
            mainText="Sorry, you don't have saved products"
            secondaryText="No results"
          />
        </div>
      )
    }
    return ''
  }

  return (
    <section className="saved-view">
      <h5>Your saved products</h5>
      <div className="saved-list">{renderList()}</div>
      <LoaderComponent show={isLoading} local />
    </section>
  )
}

SavedList.propTypes = {
  savedProductList: PropTypes.array,
  session: PropTypes.object.isRequired,
  favoritesList: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isProductDetailLoading: PropTypes.bool.isRequired,
  unlikeProductIdSuccess: PropTypes.number.isRequired,
}

SavedList.defaultProps = {
  savedProductList: [],
}
function mapStateToProps({
  savedList: { savedProductList },
  login,
  favoritesList,
  productDetails,
}) {
  return {
    savedProductList,
    session: login.login,
    favoritesList: favoritesList.favoritesList,
    isLoading: favoritesList.favoritesListIsLoading,
    isProductDetailLoading: productDetails.productDetailsIsLoading,
    unlikeProductIdSuccess: productDetails.unlikeProductIdSuccess,
  }
}

export default connect(mapStateToProps)(SavedList)
