/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

import Button from '../../components/Buttons/Button'
import Loader from '../../components/Loader'
import TableComponent from '../../components/Table'
import ModalComponent from '../../components/ModalComponent'
import useHandleMobile from '../../hooks/useHandleMobile'

import GetOrdersList, {
  CancelOrder,
  ClearCancelOrders,
} from '../../redux/actions/OrdersActions'

import MobileOrderList from './MobileOrderList'
import { orderFinancialStatus, orderFulfillmentStatus } from '../../utils/enums'
import { extractSessionInfo, setPageTitleAndUserInfo } from '../../utils'
import { clearOrderDetails } from '../../redux/actions/OrdersDetailsActions'
import { roundToDecimals } from '../../utils/productUtils'
import NoResults from '../../components/NoResults/NoResults'

function Orders({ session, isLoading, orderList, orderCanceled }) {
  const initialShowModalObj = {
    show: false,
    orderNumber: 0,
    externalOrderId: 0,
  }
  const { mobile } = useHandleMobile()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(0)
  const [showModal, setShowModal] = useState(initialShowModalObj)
  const [orders, setOrders] = useState([])

  const resetShowModal = () => setShowModal(initialShowModalObj)

  const { userId, userName, userEmail, jwt } = extractSessionInfo(session)

  useEffect(
    () => setPageTitleAndUserInfo('Order List', userId, userName, userEmail),
    []
  )

  useEffect(() => {
    dispatch(ClearCancelOrders())
    dispatch(
      GetOrdersList({
        token: jwt,
      })
    )
  }, [])

  useEffect(() => {
    dispatch(
      GetOrdersList({
        token: jwt,
        page: currentPage,
      })
    )
  }, [currentPage])

  useEffect(() => {
    if (Object.values(orderList).length >= 1) {
      const { elements, totalElements } = orderList
      if (totalElements >= 1) {
        setOrders(elements)
      }
    }
  }, [orderList])

  useEffect(() => {
    if (orderCanceled) {
      dispatch(
        GetOrdersList({
          token: jwt,
        })
      )
      resetShowModal()
    }
  }, [orderCanceled])

  const handleCancelOrder = () => {
    dispatch(
      CancelOrder({
        token: jwt,
        id: showModal.externalOrderId,
        orderNumber: showModal.orderNumber,
      })
    )
  }

  const handleShowModal = () => resetShowModal()

  const shouldShowModal = (event, orderNumber, externalOrderId) => {
    event.stopPropagation()
    setShowModal({
      show: true,
      orderNumber,
      externalOrderId,
    })
  }

  const handleRedirect = (id) => {
    dispatch(clearOrderDetails())
    navigate(`/orders/${id}/details`)
  }

  const tableBody = () => {
    if (orders.length >= 1) {
      return orders.map((order) => {
        const date = order?.createdAt
          ? format(new Date(order?.createdAt), 'yyyy-MM-dd')
          : null
        return (
          <tr
            key={`${order.id}`}
            onClick={() => handleRedirect(order?.externalOrderId)}
          >
            <td>{order?.orderNumber}</td>
            <td>{date}</td>
            <td>{`$${roundToDecimals(order?.totalPrice)}`}</td>
            <td>{order?.financialStatus}</td>
            <td>{order?.fulfillmentStatus}</td>
            <td className="action__buttons">
              {order?.financialStatus === orderFinancialStatus.PAID &&
                order?.fulfillmentStatus ===
                  orderFulfillmentStatus.UNFULFILLED && (
                  <button
                    type="button"
                    onClick={(event) =>
                      shouldShowModal(
                        event,
                        order?.orderNumber,
                        order.externalOrderId
                      )
                    }
                  >
                    Cancel
                  </button>
                )}
            </td>
          </tr>
        )
      })
    }
    return null
  }

  return (
    <section className="orders__cont">
      <Loader show={isLoading} />
      <article>
        <h2 className="orders__title">My orders</h2>
        {!orders.length >= 1 ? (
          <div className="no-response__container">
            <NoResults mainText="Sorry, you don't have any orders" />
          </div>
        ) : !mobile ? (
          <TableComponent
            id="orders-list"
            tableHead={[
              'Order Number',
              'Order Date',
              'Total',
              'Payment Status',
              'Fulfillment Status',
              '',
            ]}
            paginateOptions={{
              totalPages: orderList?.totalPages,
              currentPage,
              handleNextPage: setCurrentPage,
              handlePreviousPage: setCurrentPage,
            }}
          >
            <tbody>{tableBody()}</tbody>
          </TableComponent>
        ) : (
          <MobileOrderList
            orders={orders}
            navigation={(id) => navigate(`/orders/${id}/details`)}
          />
        )}
      </article>
      <ModalComponent show={showModal.show} className="cancel-order">
        <div>
          <h4>
            Are you sure you want to cancel order {showModal.orderNumber}?
          </h4>
          <div className="cancel-order-modal__buttons">
            <Button
              id="back"
              secondary
              text="Back"
              action={() => handleShowModal()}
            />
            <Button
              id="cancel"
              text="Cancel Order"
              action={() => handleCancelOrder()}
            />
          </div>
        </div>
      </ModalComponent>
    </section>
  )
}

Orders.propTypes = {
  session: PropTypes.object.isRequired,
  orderList: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  orderCanceled: PropTypes.bool.isRequired,
}

function mapStateToProps({ login, orders }) {
  return {
    session: login.login,
    orderList: orders.orderList,
    isLoading: orders.orderListIsLoading || orders.cancelOrderIsLoading,
    orderCanceled: orders.cancelOrderSuccess,
  }
}

export default connect(mapStateToProps)(Orders)
