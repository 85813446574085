import { Auth } from 'aws-amplify'
import { handleActionCatch } from '../../utils/errorUtils'
import {
  RESET_PASSWORD_CLEAR,
  START_RESET_PASSWORD_STARTED,
  START_RESET_PASSWORD_SUCCESS,
  START_RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD_STARTED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from '../constants'

export const StartResetPasswordStarted = (payload) => ({
  type: START_RESET_PASSWORD_STARTED,
  ...payload,
})
export const StartResetPasswordSuccess = (payload) => ({
  type: START_RESET_PASSWORD_SUCCESS,
  payload,
})
export const StartResetPasswordFailure = (payload) => ({
  type: START_RESET_PASSWORD_FAILURE,
  payload,
})

export const ChangePasswordStarted = (payload) => ({
  type: CHANGE_PASSWORD_STARTED,
  ...payload,
})
export const ChangePasswordSuccess = (payload) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload,
})
export const ChangePasswordFailure = (payload) => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload,
})

export const ResetPasswordClear = () => ({ type: RESET_PASSWORD_CLEAR })

const StartResetPassword = (payload) => async (dispatch) => {
  dispatch(StartResetPasswordStarted(payload))
  try {
    const { email } = payload
    await Auth.forgotPassword(email)
    dispatch(StartResetPasswordSuccess())
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      StartResetPasswordFailure,
      'Start reset password',
      payload
    )
  }
}

const ChangeUserPassword = (payload) => async (dispatch) => {
  dispatch(ChangePasswordStarted(payload))
  try {
    const { email, verificationCode, password } = payload
    await Auth.forgotPasswordSubmit(email, verificationCode, password)
    dispatch(ChangePasswordSuccess())
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      ChangePasswordFailure,
      'Change password',
      payload
    )
  }
}

const ClearResetPassword = () => (dispatch) => dispatch(ResetPasswordClear())

export default { StartResetPassword, ClearResetPassword, ChangeUserPassword }
