import React from 'react'
import { Amplify } from 'aws-amplify'

import RoutesList from './Routes'
import awsConfig from './aws-exports'
import useAppInit from './hooks/useAppInit'

import './App.scss'
// import { loggerName, streamName } from './utils/logMessage'

Amplify.configure({
  // Logging: {
  //   logGroupName: loggerName,
  //   logStreamName: streamName,
  // },
  ...awsConfig,
})

function App() {
  useAppInit()
  return <RoutesList />
}

export default App
