/* eslint default-param-last: off */
import {
  RESET_LOGIN_TOASTS,
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
} from '../constants'

export const initialState = {
  login: {},
  loginStarted: false,
  loginSuccess: false,
  loginIsLoading: false,
  loginFailed: false,
  loginError: '',
}

const loginReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case LOGIN_STARTED: {
      return {
        ...state,
        loginStarted: true,
        loginIsLoading: true,
        loginError: '',
      }
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        loginSuccess: true,
        loginIsLoading: false,
        login: payload,
      }
    }
    case LOGIN_FAILED: {
      return {
        ...state,
        loginFailed: true,
        loginIsLoading: false,
        loginError: payload.errorMessage,
      }
    }
    case LOGOUT: {
      return {
        ...initialState,
      }
    }
    case RESET_LOGIN_TOASTS: {
      return {
        ...state,
        loginError: '',
      }
    }
    default:
      return state
  }
}

export default loginReducer
