import api from '../../utils/api'
import {
  handleActionCatch,
  validateServerResponse,
} from '../../utils/errorUtils'
import {
  GET_MENU_STARTED,
  GET_MENU_SUCCESS,
  GET_MENU_FAILED,
} from '../constants'

export const GetMenuStarted = (payload) => ({
  type: GET_MENU_STARTED,
  ...payload,
})

export const GetMenuSuccess = (payload) => ({
  type: GET_MENU_SUCCESS,
  payload,
})

export const GetMenuFailed = (payload) => ({
  type: GET_MENU_FAILED,
  payload,
})

const GetMenu = (payload) => async (dispatch) => {
  dispatch(GetMenuStarted(payload))
  try {
    const result = await api.Get(`/shopify/layouts/menus?menu=${payload}`)
    validateServerResponse(result)
    dispatch(GetMenuSuccess(result.data.data.menus))
  } catch (error) {
    handleActionCatch(error, dispatch, GetMenuFailed, 'Get Menu Items', payload)
  }
}

export default GetMenu
