/* eslint default-param-last: off */
import {
  GET_PRODUCT_DETAILS_STARTED,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_FAILED,
  LIKE_PRODUCT_STARTED,
  LIKE_PRODUCT_SUCCESS,
  LIKE_PRODUCT_FAILURE,
  UNLIKE_PRODUCT_STARTED,
  UNLIKE_PRODUCT_SUCCESS,
  UNLIKE_PRODUCT_FAILURE,
  RATE_PRODUCT_STARTED,
  RATE_PRODUCT_SUCCESS,
  RATE_PRODUCT_FAILURE,
  CLEAR_PRODUCT_DETAILS,
  RESET_PRODUCT_TOASTS,
} from '../constants'

export const initialState = {
  productDetails: {
    id: null,
    title: null,
    price: 0,
    liked: false,
    rating: {},
    description: '',
    options: [],
    images: [],
    variants: [],
    mainImage: '',
  },
  productDetailsStarted: false,
  productDetailsSuccess: false,
  productDetailsIsLoading: false,
  productDetailsFailed: false,
  productDetailsError: '',
  likeProductIdSuccess: 0,
  likeProductError: '',
  unlikeProductIdSuccess: 0,
  unlikeProductError: '',
  likedProductIds: [],
  rateProductSuccess: false,
  rateProductError: '',
}

const addLikedId = (likedId, state) => {
  if (!state?.likedProductIds) return [likedId]
  const containsId = state.likedProductIds?.find((id) => id === likedId)
  if (containsId) return state.addedCartProductIds

  return [...state.likedProductIds, likedId]
}

const removeLikedId = (likedId, state) =>
  state.likedProductIds?.filter((id) => id !== likedId)

const productDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_PRODUCT_DETAILS_STARTED: {
      return {
        ...state,
        productDetailsStarted: true,
        productDetailsIsLoading: true,
        likeProductSuccess: false,
        unlikeProductSuccess: false,
        productDetailsError: '',
      }
    }
    case GET_PRODUCT_DETAILS_SUCCESS: {
      return {
        ...state,
        productDetailsSuccess: true,
        productDetailsIsLoading: false,
        productDetails: {
          ...payload,
          mainImage: payload?.image,
          images: payload.images,
          price: payload?.variants[0].price,
          priceNoDiscount: payload?.priceNoDiscount || 1,
          rating: payload?.rating,
          description: payload?.description || '[NO DESCRIPTION]',
          options: payload?.options.map((option) => ({
            ...option,
            chosenValue: '',
          })),
        },
        rateProductSuccess: false,
        rateProductError: '',
      }
    }
    case GET_PRODUCT_DETAILS_FAILED: {
      return {
        ...state,
        productDetailsFailed: true,
        productDetailsIsLoading: false,
        productDetailsError: payload.errorMessage,
      }
    }

    case LIKE_PRODUCT_STARTED: {
      return {
        ...state,
        productDetailsIsLoading: true,
        likeProductError: '',
        likedProductIds: addLikedId(payload.id, state),
      }
    }
    case LIKE_PRODUCT_SUCCESS: {
      return {
        ...state,
        productDetailsIsLoading: false,
        productDetails: {
          ...state.productDetails,
          liked: true,
        },
        unlikeProductIdSuccess: 0,
        likeProductIdSuccess: payload.id,
        likedProductIds: removeLikedId(payload.id, state),
      }
    }
    case LIKE_PRODUCT_FAILURE: {
      return {
        ...state,
        productDetailsIsLoading: false,
        likeProductError: payload.errorMessage,
        likedProductIds: removeLikedId(payload.initialPayload.id, state),
      }
    }

    case UNLIKE_PRODUCT_STARTED: {
      return {
        ...state,
        productDetailsIsLoading: true,
        unlikeProductError: '',
        likedProductIds: addLikedId(payload.id, state),
      }
    }
    case UNLIKE_PRODUCT_SUCCESS: {
      return {
        ...state,
        productDetailsIsLoading: false,
        productDetails: {
          ...state.productDetails,
          liked: false,
        },
        likeProductIdSuccess: 0,
        unlikeProductIdSuccess: payload.id,
        likedProductIds: removeLikedId(payload.id, state),
      }
    }
    case UNLIKE_PRODUCT_FAILURE: {
      return {
        ...state,
        productDetailsIsLoading: false,
        unlikeProductError: payload.errorMessage,
        likedProductIds: removeLikedId(payload.initialPayload.id, state),
      }
    }

    case RATE_PRODUCT_STARTED: {
      return {
        ...state,
        productDetailsIsLoading: true,
        rateProductError: '',
        rateProductSuccess: false,
      }
    }
    case RATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        productDetailsIsLoading: false,
        rateProductSuccess: true,
        productDetails: {
          ...state.productDetails,
        },
      }
    }
    case RATE_PRODUCT_FAILURE: {
      return {
        ...state,
        productDetailsIsLoading: false,
        rateProductError: payload.errorMessage,
        rateProductSuccess: false,
      }
    }

    case CLEAR_PRODUCT_DETAILS: {
      return {
        ...initialState,
        likedProductIds: state.likedProductIds,
      }
    }
    case RESET_PRODUCT_TOASTS: {
      return {
        ...state,
        productDetailsSuccess: false,
        likeProductSuccess: false,
        unlikeProductSuccess: false,
        rateProductSuccess: false,
        productDetailsError: '',
        likeProductError: '',
        unlikeProductError: '',
        rateProductError: '',
      }
    }
    default:
      return state
  }
}

export default productDetailsReducer
