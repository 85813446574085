import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { roundToDecimals } from '../../../utils/productUtils'

function ProductRow(props) {
  const {
    product: { image, title, price, quantity, externalProductId },
    altBackground,
  } = props
  const navigate = useNavigate()
  const goToDetail = () => {
    navigate(`/product/${externalProductId}/details`)
  }

  return (
    <button
      type="button"
      className="order-product-row-container"
      onClick={() => goToDetail()}
      altBackground={altBackground ? 'true' : undefined}
    >
      <img src={image} className="image-container" alt="Product main" />
      <div className="product-info">
        <h4 className="product-title">{title}</h4>
        <div>
          <p className="quantity">Quantity: {quantity}</p>
          <p className="price">{`$${roundToDecimals(price)}`}</p>
        </div>
      </div>
    </button>
  )
}

ProductRow.propTypes = {
  product: PropTypes.shape({
    variantId: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
    quantity: PropTypes.number,
    externalProductId: PropTypes.number,
  }).isRequired,
  altBackground: PropTypes.bool,
}

ProductRow.defaultProps = {
  altBackground: false,
}

export default ProductRow
