import api from '../../utils/api'
import {
  handleActionCatch,
  validateServerResponse,
} from '../../utils/errorUtils'
import {
  GET_ORDER_DETAILS_STARTED,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILURE,
} from '../constants'

export const GetOrdersDetailsStarted = () => ({
  type: GET_ORDER_DETAILS_STARTED,
})

export const GetOrdersDetailsSuccess = (payload) => ({
  type: GET_ORDER_DETAILS_SUCCESS,
  payload,
})

export const GetOrdersDetailsFailure = (payload) => ({
  type: GET_ORDER_DETAILS_FAILURE,
  payload,
})

const GetOrdersDetails =
  ({ id, token }) =>
  async (dispatch) => {
    dispatch(GetOrdersDetailsStarted())
    try {
      const result = await api.Get(`/orders/${id}`, token)
      validateServerResponse(result)
      dispatch(GetOrdersDetailsSuccess(result.data.data))
    } catch (error) {
      handleActionCatch(
        error,
        dispatch,
        GetOrdersDetailsFailure,
        'Get Orders Details',
        { id, token }
      )
    }
  }

export const clearOrderDetails = () => ({
  type: GET_ORDER_DETAILS_STARTED,
})

export default GetOrdersDetails
