import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import InputComponent from '../../../components/Inputs/InputComponent'
import SelectComponent from '../../../components/SelectComponent'

import { states, cities, getState } from '../../../utils/countryStateCity'

function DifferentAddress({ setFields }) {
  const [data, setData] = useState({
    address: '',
    additionalInfo: '',
    state: '',
    city: '',
    zipCode: '',
  })

  useEffect(() => {
    const state = data.state !== '' ? getState(data.state) : data.state
    setFields({
      ...data,
      state,
    })
  }, [data])

  const handleChange = ({ name, value }) => {
    setData({ ...data, [name]: value })
  }

  return (
    <section className="checkout__shipping-address--form">
      <h3>Shipping Address</h3>
      <div className="checkout__shipping-address--form-container">
        <InputComponent
          id="address"
          name="address"
          labelText="Address"
          isMandatory
          onChange={({ target }) => handleChange(target)}
          outlined
        />
        <InputComponent
          id="zip_code"
          name="zipCode"
          labelText="Zip Code"
          isMandatory
          onChange={({ target }) => handleChange(target)}
          outlined
        />
        <SelectComponent
          id="state"
          name="state"
          labelText="State"
          isMandatory
          onChange={({ target }) => handleChange(target)}
          options={states()}
        />
        <SelectComponent
          id="city"
          name="city"
          labelText="City"
          isMandatory
          onChange={({ target }) => handleChange(target)}
          options={cities(undefined, data?.state ? data.state : undefined)}
        />
        <InputComponent
          id="additionalInfo"
          name="additionalInfo"
          labelText="Additional Info"
          onChange={({ target }) => handleChange(target)}
          outlined
        />
      </div>
    </section>
  )
}

DifferentAddress.propTypes = {
  setFields: PropTypes.func.isRequired,
}

export default DifferentAddress
