import notifyToast from '../../utils/notifyToast'
import {
  ADD_TO_CART_GUEST,
  DELETE_CART_GUEST,
  UPDATE_CART_GUEST,
  RESET_CART_GUEST,
} from '../constants'

export const AddProductToGuestCart = (payload) => {
  notifyToast('success', 'Product added to the cart', 2000)
  return { type: ADD_TO_CART_GUEST, payload }
}

export const UpdateProductGuestCart = (payload) => ({
  type: UPDATE_CART_GUEST,
  payload,
})

export const DeleteProductGuestCart = (payload) => {
  notifyToast('success', 'Product removed from cart', 2000)
  return { type: DELETE_CART_GUEST, payload }
}
export const ResetGuestCart = (payload) => ({
  type: RESET_CART_GUEST,
  payload,
})
