/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import LoaderComponent from '../../components/Loader'
import TableComponent from '../../components/Table'
import DottedHr from '../../components/Separators/DottedHr'
import Icon from '../../components/Icon'
import download from '../../assets/download.png'
import GetPropertyDetails, {
  GetPurchaseHistory,
  setPurchasePagination,
} from '../../redux/actions/PropertyDetailsAction'
import PurchaseHistoryCard from './PurchaseHistoryCard'
import ErrorAlert from '../../components/ErrorAlert'
import notifyToast from '../../utils/notifyToast'

import {
  truncateStr,
  setPageTitleAndUserInfo,
  extractSessionInfo,
} from '../../utils'
import NoResults from '../../components/NoResults/NoResults'

function PropertyDetails(props) {
  const {
    session,
    propertyDetails,
    isLoading,
    isError,
    propertyDetailsError,
    purchaseHistory,
    purchasePagination,
    page,
  } = props
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { userId, userName, userEmail, jwt } = extractSessionInfo(session)

  useEffect(
    () =>
      setPageTitleAndUserInfo('Property Details', userId, userName, userEmail),
    []
  )

  const [details, setDetails] = useState(null)
  const { currentPage, totalPages, elements } = purchaseHistory
  const [changePage, setChangePage] = useState(false)
  const id = location.pathname.split('/')[2]
  useEffect(() => {
    if (!details?.city && userId) {
      dispatch(
        GetPropertyDetails({
          id,
          token: jwt,
        })
      )
      dispatch(
        GetPurchaseHistory({
          token: jwt,
          id,
        })
      )
    }
  }, [])

  useEffect(() => {
    if (purchasePagination?.length === 0 && elements?.length > 0) {
      dispatch(
        setPurchasePagination({
          elements,
          page: currentPage,
        })
      )
    }
    if (changePage && elements?.length > 0) {
      dispatch(
        setPurchasePagination({
          elements: [...purchasePagination, ...elements],
          page: currentPage,
        })
      )
      setChangePage(false)
    }
  }, [elements])

  useEffect(() => {
    if (isError) navigate('/property/list')
  }, [isError])

  useEffect(() => {
    if (propertyDetails) {
      setDetails(propertyDetails)
    }
  }, [propertyDetails])

  const handleNextPage = () => {
    const nextPage = currentPage
    dispatch(
      GetPropertyDetails({
        id,
        token: jwt,
      })
    )
    dispatch(
      GetPurchaseHistory({
        token: jwt,
        id,
        page: nextPage,
      })
    )
    setChangePage(true)
  }

  const shouldShowCards = () => {
    if (Object.values(purchaseHistory).length >= 1) {
      return purchasePagination?.map((product) => (
        <tr>
          <PurchaseHistoryCard product={product} token={jwt} />
        </tr>
      ))
    }
    return null
  }

  const copyToClipboard = (event, copyText) => {
    event.stopPropagation()
    navigator.clipboard.writeText(copyText)
    notifyToast('success', 'Link copied successfully')
  }

  const showPurchaseHistory = () => {
    if (
      Object.values(purchaseHistory).length >= 1 &&
      purchaseHistory?.elements?.length >= 1
    ) {
      return (
        <TableComponent id="purchase_history_table">
          <tbody>{shouldShowCards()}</tbody>
        </TableComponent>
      )
    }
    return (
      <div className="no-response__container">
        <NoResults
          mainText="It seems that you haven't purchased products for this property yet"
          secondaryText="0 Items"
        />
      </div>
    )
  }

  return (
    <section className="property-details">
      <LoaderComponent show={isLoading} />
      {propertyDetailsError && <ErrorAlert error={propertyDetailsError} />}
      {!!details && (
        <>
          <span className="property-details__back-link">
            <Link to="/property/list">{'<- BACK'}</Link>
          </span>
          <div className="property-details__info-container">
            <div>
              <h4>Details</h4>
              <button
                type="button"
                className="no-shape"
                onClick={() => navigate(`/property/${id}/edit`)}
              >
                <Icon item="edit" stroke="#CCCCCE" />
              </button>
            </div>
            <article className="property-details__info">
              <div className="details-info">
                <div className="columns">
                  <div>
                    <h5>Nickname:</h5>
                    <span>{details?.nickName}</span>
                  </div>
                  <div>
                    <h5>Address:</h5>
                    <span>{details?.address}</span>
                  </div>
                  <div>
                    <h5 className="long_item">Apartment/Floor/Other:</h5>
                    <span>{details?.secondAddress}</span>
                  </div>
                  <div>
                    <h5>Listing Link:</h5>
                    <span>
                      {details?.listingLink && (
                        <a href={details?.listingLink} target="__blank">
                          {truncateStr(details?.listingLink, 22)}
                        </a>
                      )}
                    </span>
                  </div>
                </div>
                <div className="columns right">
                  <div>
                    <h5>City:</h5>
                    <span>{details?.city}</span>
                  </div>
                  <div>
                    <h5>State:</h5>
                    <span>{details?.state}</span>
                  </div>
                  <div>
                    <h5>Zip Code:</h5>
                    <span>{details?.zipCode}</span>
                  </div>
                  <div>
                    <h5>Calendar Link:</h5>
                    <span>
                      {details?.calendarLink && (
                        <a href={details?.calendarLink} target="__blank">
                          {truncateStr(details?.calendarLink, 22)}
                        </a>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="hr_dotter-mobile">
                <DottedHr color="light_gray" />
              </div>
              <div className="details-image">
                <figure>
                  <img src={details?.qrCode} alt="Qr Code" />
                  <a href={details?.qrCode} download="new-image-name.png">
                    <img src={download} alt={download} />
                  </a>
                </figure>
                <div>
                  {details?.propertyUrl && (
                    <a href={`${details?.propertyUrl}/product/list`}>
                      {truncateStr(details?.propertyUrl, 22)}
                    </a>
                  )}
                  <button
                    type="button"
                    onClick={(event) =>
                      copyToClipboard(
                        event,
                        `${details?.propertyUrl}/product/list`
                      )
                    }
                  >
                    <Icon item="link-icon" stroke="#0d6efd" />
                  </button>
                </div>
              </div>
            </article>
          </div>
          <div className="property-details__purchase-table">
            <h4>Purchase History</h4>
            {showPurchaseHistory()}
            {totalPages > 1 && page !== totalPages && (
              <div className="show-more__container-purchase">
                <button
                  type="button"
                  className="show-more no-shape"
                  onClick={() => handleNextPage()}
                >
                  Show more products...
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </section>
  )
}

PropertyDetails.propTypes = {
  session: PropTypes.object.isRequired,
  propertyDetails: PropTypes.object.isRequired,
  purchaseHistory: PropTypes.object.isRequired,
  purchasePagination: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  propertyDetailsError: PropTypes.string.isRequired,
}

function mapStateToProps({ login, propertyDetails, purchasePagination }) {
  return {
    session: login.login,
    propertyDetails: propertyDetails.propertyDetails,
    purchaseHistory: propertyDetails.purchaseHistory,
    purchasePagination: purchasePagination.purchasePagination,
    page: purchasePagination.page,
    isLoading:
      propertyDetails.propertyDetailsIsLoading ||
      propertyDetails.purchaseHistoryIsLoading,
    isError: propertyDetails.propertyDetailsFailed,
    propertyDetailsError: propertyDetails.propertyDetailsError,
  }
}

export default connect(mapStateToProps)(PropertyDetails)
