import analytics from '../../utils/analytics'
import api from '../../utils/api'
import {
  handleActionCatch,
  validateServerResponse,
} from '../../utils/errorUtils'
import notifyToast from '../../utils/notifyToast'
import {
  START_CHECKOUT_STARTED,
  START_CHECKOUT_SUCCESS,
  START_CHECKOUT_FAILURE,
  RESET_CHECKOUT_TOASTS,
} from '../constants'

export const StartCheckoutStarted = (payload) => ({
  type: START_CHECKOUT_STARTED,
  ...payload,
})

export const StartCheckoutSuccess = (payload) => ({
  type: START_CHECKOUT_SUCCESS,
  payload,
})

export const StartCheckoutFailure = (payload) => {
  notifyToast('error', payload.startCheckoutError, 2000)
  return { type: START_CHECKOUT_FAILURE, payload }
}

const ResetCheckoutToasts = () => ({
  type: RESET_CHECKOUT_TOASTS,
})

const StartUserCheckout = (payload) => async (dispatch) => {
  dispatch(StartCheckoutStarted(payload))
  try {
    const isGuest = payload.id === 'guest'
    const result = await api.Post(
      `/checkout/${payload.id}`,
      isGuest ? payload.products : payload,
      payload.token
    )
    validateServerResponse(result)
    dispatch(
      StartCheckoutSuccess(
        payload.id === 'guest' ? { order: result.data.data } : result.data.data
      )
    )

    const {
      id,
      propertyId,
      propertyNickname,
      totalAmount,
      shippingAddress,
      propertyUrl,
      userEmail,
    } = payload

    const email = userEmail || ''

    if (isGuest) {
      analytics.redirectToCheckoutGuest(
        propertyId,
        propertyNickname,
        totalAmount,
        isGuest,
        propertyUrl,
        email
      )
    } else {
      const { address, city, state } = shippingAddress
      analytics.redirectToCheckout(
        id,
        propertyNickname,
        state,
        city,
        address,
        totalAmount,
        isGuest
      )
    }
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      StartCheckoutFailure,
      'Start user checkout',
      payload
    )
  }
  dispatch(ResetCheckoutToasts())
}

export default { StartUserCheckout }
