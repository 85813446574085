import axios from 'axios'
import useSWRInfinite from 'swr/infinite'
import { noop } from '.'
import pagination from './pagination'

const { PaginationTypes } = pagination

const { REACT_APP_SERVER_URL: apiUrl } = process.env

const setHeaders = (token) =>
  token ? { Authorization: `Bearer ${token}` } : {}

const Post = (route, body, token = '', additionalConfig = {}) => {
  const headers = setHeaders(token)

  return axios.post(`${apiUrl}${route}`, body, {
    headers,
    ...additionalConfig,
  })
}

const Put = (route, body, token = '', additionalConfig = {}) => {
  const headers = setHeaders(token)

  return axios.put(`${apiUrl}${route}`, body, {
    headers,
    ...additionalConfig,
  })
}

const Get = (route, token = '', additionalConfig = {}) => {
  const headers = setHeaders(token)

  return axios.get(`${apiUrl}${route}`, {
    headers,
    ...additionalConfig,
  })
}

const getKeyPageInfo =
  (route) =>
  (pageIndex, previousPageData = {}) => {
    if (previousPageData && !previousPageData.data) return null

    const { currentPage: nextPageCursor } =
      pagination.getProductsResponsePagination(
        [previousPageData],
        PaginationTypes.PAGE_INFO
      )

    return `${route}&page_info=${pageIndex !== 0 ? nextPageCursor : ''}`
  }

const getKeyPage =
  (route) =>
  (pageIndex, previousPageData = {}) => {
    if (previousPageData && !previousPageData.data) return null

    let pageParam = 1
    const { hasNextPage, currentPage } =
      pagination.getProductsResponsePagination(
        [previousPageData],
        PaginationTypes.PAGE
      )
    if (hasNextPage && pageIndex !== 0) pageParam = currentPage

    return `${route}&page=${pageParam}`
  }

const GetWithInfiniteSWR = (
  baseRoute,
  token = '',
  additionalConfig = {},
  // fallbackData = [],
  onSuccess = noop,
  paginationType = PaginationTypes.PAGE_INFO
) => {
  const headers = setHeaders(token)

  const fetcher = (route) =>
    axios
      .get(`${apiUrl}${route}`, {
        headers,
        ...additionalConfig,
      })
      .then((res) => res.data)

  const getKeyFunc =
    paginationType === PaginationTypes.PAGE_INFO
      ? getKeyPageInfo(baseRoute)
      : getKeyPage(baseRoute)

  return useSWRInfinite(getKeyFunc, fetcher, {
    initialSize: PaginationTypes.PAGE ? 1 : 0,
    // dedupingInterval: 30000,
    // revalidateAll: true,
    // revalidateOnFocus: false,
    revalidateFirstPage: false,
    // fallbackData, // Like "placeholder" data
    onSuccess,
  })
}

const Delete = (route, token = '', additionalConfig = {}) => {
  const headers = setHeaders(token)

  return axios.delete(`${apiUrl}${route}`, {
    headers,
    ...additionalConfig,
  })
}

const Patch = (route, body, token = '', additionalConfig = {}) => {
  const headers = setHeaders(token)

  return axios.patch(`${apiUrl}${route}`, body, {
    headers,
    ...additionalConfig,
  })
}

export default {
  /**
   * Wrapper function to make GET calls to the backend
   * @param {string} route - Route to append to the api server url
   * @param {string} token - JWT for authentication purposes (optional)
   * @param {object} additionalConfig - Additional library config
   * @returns Promise with the call to the api
   */
  Get,
  GetWithInfiniteSWR,

  /**
   * Wrapper function to make POST calls to the backend
   * @param {string} route - Route to append to the api server url
   * @param {object} body - object to include in the body
   * @param {string} token - JWT for authentication purposes (optional)
   * @param {object} additionalConfig - Additional library config (optional)
   * @returns Promise with the call to the api
   */
  Post,

  /**
   * Wrapper function to make DELETE calls to the backend
   * @param {string} route - Route to append to the api server url
   * @param {string} token - JWT for authentication purposes (optional)
   * @param {object} additionalConfig - Additional library config (optional)
   * @returns Promise with the call to the api
   */
  Delete,

  /**
   * Wrapper function to make PUT calls to the backend
   * @param {string} route - Route to append to the api server url
   * @param {object} body - object to include in the body
   * @param {string} token - JWT for authentication purposes (optional)
   * @param {object} additionalConfig - Additional library config
   * @returns Promise with the call to the api
   */
  Put,

  /**
   * Wrapper function to make PATCH calls to the backend
   * @param {string} route - Route to append to the api server url
   * @param {object} body - object to include in the body
   * @param {string} token - JWT for authentication purposes (optional)
   * @param {object} additionalConfig - Additional library config (optional)
   * @returns Promise with the call to the api
   */
  Patch,
}
