import React from 'react'
import PropTypes from 'prop-types'
import { hostItems, guestItems } from './constants'

import Icon from '../Icon'

function Footer({ urlGuestParams }) {
  const isGuest = !!urlGuestParams?.propertyID
  const items = isGuest ? guestItems : hostItems

  return (
    <section className={isGuest ? 'footer footer-guest' : 'footer'}>
      <ul className="footer-menu">
        <li className="footer-item">
          <Icon
            item="footer-logo"
            className="footer-logo"
            width="17px"
            height="23px"
          />
          <p>
            <b>space</b>place
          </p>
        </li>
        {items.map((item) => (
          <li className="footer-item">
            <a href={item.link} target="_blank" rel="noreferrer">
              {item.item}
            </a>
          </li>
        ))}
      </ul>
      <div className="footer-separator">
        <p className="footer-rights">© 2022 Space place</p>
      </div>
    </section>
  )
}

Footer.propTypes = {
  urlGuestParams: PropTypes.object.isRequired,
}

export default Footer
