import React from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useGuest from './hooks/useGuest'

function UserIsLogged({ children }) {
  const { isGuest, baseRoute } = useGuest()
  const auth = useSelector((state) => state.login.loginSuccess)
  if (auth) {
    return children
  }
  let loginUrl = '/'
  if (isGuest) loginUrl = baseRoute
  return <Navigate to={loginUrl} />
}

UserIsLogged.propTypes = {
  children: PropTypes.element,
}
UserIsLogged.defaultProps = {
  children: <div>Element</div>,
}

export default UserIsLogged
