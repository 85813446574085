import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

import Button from '../../components/Buttons/Button'
import CartActions from '../../redux/actions/CartActions'

import notifyToast from '../../utils/notifyToast'
import { roundToDecimals } from '../../utils/productUtils'

const { AddProductToCart } = CartActions
function PurchaseHistoryCard({ product, token }) {
  const {
    name,
    variantId,
    image,
    price,
    quantity,
    id,
    externalProductId,
    createdAt,
  } = product
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const purchasedDate = createdAt
    ? format(new Date(createdAt), 'yyyy-MM-dd')
    : null
  const handleReOrder = () => {
    const payload = {
      id: externalProductId,
      variantId,
      title: name,
      price,
      quantity: 1,
      image,
      token,
    }
    dispatch(AddProductToCart({ ...payload, token, isGuest: false }))
    notifyToast('success', 'Adding to cart!')
  }
  const goToDetail = () => {
    navigate(`/product/${externalProductId}/details`)
  }

  return (
    <button
      type="button"
      className="purchase-card container"
      onClick={() => goToDetail()}
    >
      <div className="purchase-card__info-container">
        <div className="product-image">
          <img src={image} alt="product" />
        </div>
        <div className="purchase-card__info">
          <span>{name}</span>
          <span>Purchase Date: {purchasedDate}</span>
          <span>Quantity: {quantity}</span>
        </div>
        <div className="purchase-card__spent">{`$${roundToDecimals(
          price
        )}`}</div>
      </div>
      <div className="purchase-card__button-container">
        {false ? (
          <Button
            id={`card_Button_${id}`}
            action={() => handleReOrder()}
            text="Add To Cart"
          />
        ) : (
          <div />
        )}
      </div>
    </button>
  )
}

PurchaseHistoryCard.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    variantId: PropTypes.number,
    externalProductId: PropTypes.number,
    image: PropTypes.string,
    price: PropTypes.number,
    quantity: PropTypes.number,
    id: PropTypes.number,
    createdAt: PropTypes.string,
  }).isRequired,
  token: PropTypes.string.isRequired,
}

export default PurchaseHistoryCard
