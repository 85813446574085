/* eslint default-param-last: off */
import analytics from '../../utils/analytics'
import {
  ADD_TO_CART_GUEST,
  DELETE_CART_GUEST,
  UPDATE_CART_GUEST,
  RESET_CART_GUEST,
} from '../constants'

export const initialState = {
  cartProducts: [],
  addCartProductSuccess: false,
  updateCartProductSuccess: false,
  deleteCartProductSuccess: false,
}

// eslint-disable-next-line arrow-body-style
const productWithSubtotal = (product) => {
  return {
    ...product,
    subtotal: product.quantity * product.price || 0,
  }
}

const removeItem = (state, payload) => {
  const {
    externalProductId,
    variantId,
    price,
    title,
    quantity,
    productOptionsJSON,
    propertyUrl,
  } = payload
  const updateGuestCart = state.cartProducts.filter(
    (product) => product.variantId !== variantId
  )
  analytics.removeFromCartGuest(
    price,
    externalProductId,
    title,
    quantity,
    productOptionsJSON,
    true,
    variantId,
    propertyUrl
  )
  return updateGuestCart
}

const guestCartReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case ADD_TO_CART_GUEST: {
      return {
        ...state,
        cartProducts: payload.map((product) => productWithSubtotal(product)),
        addCartProductSuccess: true,
        updateCartProductSuccess: false,
        deleteCartProductSuccess: true,
      }
    }
    case UPDATE_CART_GUEST: {
      return {
        ...state,
        cartProducts: payload.map((product) => productWithSubtotal(product)),
        addCartProductSuccess: true,
        updateCartProductSuccess: true,
        deleteCartProductSuccess: true,
      }
    }
    case DELETE_CART_GUEST: {
      return {
        ...state,
        cartProducts: removeItem(state, payload),
        addCartProductSuccess: true,
        updateCartProductSuccess: true,
        deleteCartProductSuccess: true,
      }
    }
    case RESET_CART_GUEST: {
      return {
        ...state,
        cartProducts: [],
        addCartProductSuccess: false,
        updateCartProductSuccess: false,
        deleteCartProductSuccess: false,
      }
    }
    default:
      return state
  }
}

export default guestCartReducer
