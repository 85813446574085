import api from '../../utils/api'
import {
  handleActionCatch,
  validateServerResponse,
} from '../../utils/errorUtils'

import {
  GET_FAVORITES_LIST_STARTED,
  GET_FAVORITES_LIST_SUCCESS,
  GET_FAVORITES_LIST_FAILURE,
  CLEAR_FAVORITES_LIST,
} from '../constants'

export const GetFavoritesListStarted = (payload) => ({
  type: GET_FAVORITES_LIST_STARTED,
  ...payload,
})

export const GetFavoritesListSuccess = (payload) => ({
  type: GET_FAVORITES_LIST_SUCCESS,
  payload,
})

export const GetFavoritesListFailed = (payload) => ({
  type: GET_FAVORITES_LIST_FAILURE,
  payload,
})

export const ClearFavoritesList = () => ({
  type: CLEAR_FAVORITES_LIST,
})

const GetFavoritesList = (payload) => async (dispatch) => {
  const { page = 0, size = 12, token } = payload
  dispatch(GetFavoritesListStarted(payload))
  try {
    const result = await api.Get(
      `/products/liked?page=${page}&size=${size}`,
      token
    )
    validateServerResponse(result)
    dispatch(GetFavoritesListSuccess(result.data?.data))
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      GetFavoritesListFailed,
      'Get favorites list',
      payload
    )
  }
}

export default GetFavoritesList
