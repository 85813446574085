import React from 'react'
import PropTypes from 'prop-types'

import CheckboxTestUtils from '../../utils/testUtils/Components/Checkbox'

const {
  DataIds: { CHECKBOX_BOX_SPAN: boxSpan },
} = CheckboxTestUtils

export const checkedClassName = 'checked'

function Checkbox(props) {
  const { className, id, onClick, disabled, labelText, name, checked } = props
  return (
    <div className="checkbox-component">
      <input
        id={id}
        type="checkbox"
        onChange={onClick}
        disabled={disabled}
        name={name}
        checked={checked}
        className={`checkbox-input ${className}-checkbox`}
      />
      <span
        id={id}
        sp-data-id={boxSpan}
        className={`checkmark 
          ${checked ? checkedClassName : ''} 
          ${disabled ? 'disabled' : ''}
          ${className}`}
        role="none"
        onClick={() => onClick({ target: { name, checked: !checked } })}
      />
      {labelText && (
        <span
          className="text"
          role="none"
          onClick={() => onClick({ target: { name } })}
        >
          {labelText}
        </span>
      )}
    </div>
  )
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  labelText: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
}

Checkbox.defaultProps = {
  className: '',
  onClick: () => {},
  labelText: '',
  name: '',
  checked: false,
  disabled: false,
}

export default Checkbox
