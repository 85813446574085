import React, { useState } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import PropTypes from 'prop-types'

import SubMenu from './SubMenu'

import { isMobile } from '../../utils'
import analytics from '../../utils/analytics'

function DrawableMenu({ options }) {
  const [active, setActive] = useState('')

  const showSubmenu = (tab) => {
    setActive(tab.title)
  }
  const hideSubmenu = () => {
    setActive('')
  }

  const onMenuLinkClick = (tab) => {
    if (!isMobile()) {
      // If not on mobile, it is filtering by category
      // if on mobile, it opens/closes the menu - not filtering yet
      analytics.filterByCategory(tab.title, false)
    }
    showSubmenu(tab)
  }

  const handleOptions = () =>
    options.map((tab) => (
      <NavItem key={tab.title} className={active === tab.title ? 'active' : ''}>
        {/* TODO: change NavLink so that it doesn't reloads the page */}
        <NavLink
          onMouseEnter={!isMobile() ? () => showSubmenu(tab) : undefined}
          onMouseLeave={!isMobile() ? () => hideSubmenu() : undefined}
          onClick={() => onMenuLinkClick(tab)}
          href={!isMobile() ? tab.url : undefined}
        >
          {tab.title}
        </NavLink>

        {tab?.items?.length >= 1 && active === tab.title ? (
          <div
            className="drawable-menu__submenu"
            onMouseEnter={!isMobile() ? () => showSubmenu(tab) : undefined}
            onMouseLeave={!isMobile() ? () => hideSubmenu() : undefined}
          >
            <SubMenu
              options={!isMobile() ? tab?.items : [tab, tab.items].flat()}
              keyx={tab.title}
            />
          </div>
        ) : null}
      </NavItem>
    ))

  return (
    <div className="drawable-menu__container">
      <Nav tabs>{handleOptions()}</Nav>
      {active !== '' ? (
        <div
          className="drawable-menu__background"
          aria-hidden="true"
          onClick={(e) => {
            e.stopPropagation()
            hideSubmenu()
          }}
        />
      ) : null}
    </div>
  )
}

DrawableMenu.propTypes = {
  options: PropTypes.array.isRequired,
}

export default DrawableMenu
