import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up.svg'
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg'
import { ReactComponent as Bathroom } from '../../assets/icons/bathroom.svg'
import { ReactComponent as Bedroom } from '../../assets/icons/bedroom.svg'
import { ReactComponent as BookmarkFilled } from '../../assets/icons/bookmark-filled.svg'
import { ReactComponent as BookmarkUnfilled } from '../../assets/icons/bookmark-unfilled.svg'
import { ReactComponent as BurgerMenu } from '../../assets/icons/burger-menu.svg'
import { ReactComponent as CarouselArrow } from '../../assets/icons/carousel_arrow.svg'
import { ReactComponent as Comment } from '../../assets/icons/comment.svg'
import { ReactComponent as Clean } from '../../assets/icons/clean.svg'
import { ReactComponent as Close } from '../../assets/icons/close.svg'
import { ReactComponent as CloseFilter } from '../../assets/icons/close_filter.svg'
import { ReactComponent as DinningRoom } from '../../assets/icons/dinning-room.svg'
import { ReactComponent as Edit } from '../../assets/icons/edit.svg'
import { ReactComponent as FilterVariant } from '../../assets/icons/filter-variant.svg'
import { ReactComponent as FilterMobile } from '../../assets/icons/filter-side-mobile.svg'
import { ReactComponent as FilterDesktop } from '../../assets/icons/filter-side-desktop.svg'
import { ReactComponent as FooterLogo } from '../../assets/icons/footer-logo.svg'
import { ReactComponent as HeartFilled } from '../../assets/icons/heart-filled.svg'
import { ReactComponent as HeartUnfilled } from '../../assets/icons/heart-unfilled.svg'
import { ReactComponent as InfoCircle } from '../../assets/icons/info-circle.svg'
import { ReactComponent as Kitchen } from '../../assets/icons/kitchen.svg'
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg'
import { ReactComponent as LivingRoom } from '../../assets/icons/living-room.svg'
import { ReactComponent as FacebookLogo } from '../../assets/icons/logo-facebook.svg'
import { ReactComponent as GoogleLogo } from '../../assets/icons/logo-google.svg'
import { ReactComponent as Hide } from '../../assets/icons/hide.svg'
import { ReactComponent as MapPointer } from '../../assets/icons/map-pointer.svg'
import { ReactComponent as Notes } from '../../assets/icons/notes.svg'
import { ReactComponent as Profile } from '../../assets/icons/profile.svg'
import { ReactComponent as Search } from '../../assets/icons/search.svg'
import { ReactComponent as ShopNumber } from '../../assets/icons/shop-number.svg'
import { ReactComponent as ShoppingBag } from '../../assets/icons/shopping-bag.svg'
import { ReactComponent as Show } from '../../assets/icons/show.svg'
import { ReactComponent as ShowPassword } from '../../assets/icons/show-pwd.svg'
import { ReactComponent as Snippet } from '../../assets/icons/snippet.svg'
import { ReactComponent as Towel } from '../../assets/icons/towel.svg'
import { ReactComponent as UserNotLogged } from '../../assets/icons/user-not-logged.svg'
import { ReactComponent as Trash } from '../../assets/icons/trash.svg'
import { ReactComponent as Wallet } from '../../assets/icons/wallet.svg'

function Icon(props) {
  const { item, width, height, fill, stroke, className } = props

  const iconsList = {
    'arrow-up': (
      <ArrowUp
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    'arrow-left': (
      <ArrowLeft
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    bathroom: (
      <Bathroom
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    bedroom: (
      <Bedroom
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    'bookmark-filled': (
      <BookmarkFilled
        fill={fill}
        stroke="none"
        height={height}
        width={width}
        className={className}
      />
    ),
    'bookmark-unfilled': (
      <BookmarkUnfilled
        fill="#000"
        stroke="none"
        height={height}
        width={width}
        className={className}
      />
    ),
    'burger-menu': (
      <BurgerMenu
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    'carousel-arrow': (
      <CarouselArrow
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    clean: (
      <Clean
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    close: (
      <Close
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    closeFilter: (
      <CloseFilter
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    comment: (
      <Comment
        fill="#030303"
        stroke="none"
        height={height}
        width={width}
        className={className}
      />
    ),
    'dinning-room': (
      <DinningRoom
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    edit: (
      <Edit
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    'filter-variant': (
      <FilterVariant
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    'filter-desktop': (
      <FilterDesktop
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    'filter-mobile': (
      <FilterMobile
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    'footer-logo': (
      <FooterLogo
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    'heart-filled': (
      <HeartFilled
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    'heart-unfilled': (
      <HeartUnfilled
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    'info-circle': (
      <InfoCircle
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    kitchen: (
      <Kitchen
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    'link-icon': (
      <LinkIcon
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    'living-room': (
      <LivingRoom
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    facebook: (
      <FacebookLogo
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    google: (
      <GoogleLogo
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    'map-pointer': (
      <MapPointer
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    notes: (
      <Notes
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    profile: (
      <Profile
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    search: (
      <Search
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    'shopping-bag': (
      <ShoppingBag
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    'shop-number': (
      <ShopNumber
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    'show-pwd': (
      <ShowPassword
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        strokeOpacity="0"
        className={className}
      />
    ),
    towel: (
      <Towel
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    'user-not-logged': (
      <UserNotLogged
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    trash: (
      <Trash
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    wallet: (
      <Wallet
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    hide: (
      <Hide
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    show: (
      <Show
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
    snippet: (
      <Snippet
        fill={fill}
        stroke={stroke}
        height={height}
        width={width}
        className={className}
      />
    ),
  }
  return iconsList?.[item] ?? null
}

Icon.propTypes = {
  item: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

Icon.defaultProps = {
  width: '16px',
  height: '16px',
  fill: '#CCCCCE',
  stroke: '#CCCCCE',
  className: '',
}

export default Icon
