/* eslint default-param-last: off */
import {
  EDIT_PROPERTY_STARTED,
  EDIT_PROPERTY_SUCCESS,
  EDIT_PROPERTY_FAILED,
  CLEAR_EDIT_PROPERTY,
} from '../constants'

export const initialState = {
  editProperty: {},
  editPropertyStarted: false,
  editPropertySuccess: false,
  editPropertyIsLoading: false,
  editPropertyFailed: false,
  editPropertyError: '',
}

const editPropertyReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case EDIT_PROPERTY_STARTED: {
      return {
        ...initialState,
        editPropertyStarted: true,
        editPropertyIsLoading: true,
        editPropertyError: '',
      }
    }
    case EDIT_PROPERTY_SUCCESS: {
      return {
        ...initialState,
        editPropertySuccess: true,
        editPropertyIsLoading: false,
        editProperty: payload,
      }
    }
    case EDIT_PROPERTY_FAILED: {
      return {
        ...initialState,
        editPropertyFailed: true,
        editPropertyError: payload.errorMessage,
        editPropertyIsLoading: false,
      }
    }
    case CLEAR_EDIT_PROPERTY: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}

export default editPropertyReducer
