import React from 'react'
// import PropTypes from 'prop-types'

function Coupons() {
  // const { onApply } = props
  return (
    <section hidden>
      {' '}
      {/* (TODO): coupons component (sprint 5) */}
      <h4>coupons go here</h4>
    </section>
  )
}

Coupons.propTypes = {
  // onApply: PropTypes.func.isRequired,
}

export default Coupons
