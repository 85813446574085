import React from 'react'
import PropTypes from 'prop-types'

import { RatingStar } from 'rating-star'

function StarRating(props) {
  const { id, maxScore, clickable, rating, action } = props

  return (
    <RatingStar
      data-testid="star-rate"
      clickable={clickable}
      maxScore={maxScore}
      id={`start_rate_${id}`}
      rating={rating}
      onRatingChange={action}
      colors={{ mask: '#FF8A48' }}
    />
  )
}

StarRating.propTypes = {
  id: PropTypes.string.isRequired,
  maxScore: PropTypes.number,
  clickable: PropTypes.bool,
  rating: PropTypes.number,
  action: PropTypes.func,
}

StarRating.defaultProps = {
  maxScore: 5,
  clickable: false,
  rating: 1,
  action: () => {},
}

export default StarRating
