import ProductFilters from '../../domain/GuestProductFilters'
import ReduxConnector from '../../utils/BaseClasses/ReduxConnector'

export default class GuestProductFiltersRedux extends ReduxConnector {
  filters

  constructor(reduxProps, dispatch, action) {
    super(dispatch, action)
    this.filters = ProductFilters.reCreate(reduxProps)
  }

  updatePropertyId(propertyId) {
    this.filters.updatePropertyId(propertyId)
    this.save(this.filters.output())
  }

  updateHasNextPage(hasNextPage) {
    this.filters.updateHasNextPage(hasNextPage)
    this.save(this.filters.output())
  }

  updateVisited(visited) {
    this.filters.updateVisited(visited)
    this.save(this.filters.output())
  }
}
