import { NotImplemented } from '..'

export default class Recreatable {
  /**
   * Return an object with the current state of the instance class
   */
  /* eslint-disable class-methods-use-this */
  output() {
    NotImplemented()
  }

  /**
   * re-create the instance of the domain from a given state
   */
  static reCreate(state) {
    NotImplemented(state)
  }
}
