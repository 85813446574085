import api from '../../utils/api'
import {
  handleActionCatch,
  validateServerResponse,
} from '../../utils/errorUtils'
import {
  RESET_PROPERTY_DETAILS_TOASTS,
  GET_PROPERTY_DETAILS_STARTED,
  GET_PROPERTY_DETAILS_SUCCESS,
  GET_PROPERTY_DETAILS_FAILED,
  GET_PROPERTY_PURCHASE_HISTORY_STARTED,
  GET_PROPERTY_PURCHASE_HISTORY_SUCCESS,
  GET_PROPERTY_PURCHASE_HISTORY_FAILED,
  SET_PURCHASE_PAGINATION,
  CLEAR_PURCHASE_PAGINATION,
  CLEAR_PROPERTY_PURCHASE_HISTORY,
  CLEAR_PROPERTY_DETAILS,
} from '../constants'

export const GetPropertyDetailsStarted = (payload) => ({
  type: GET_PROPERTY_DETAILS_STARTED,
  ...payload,
})

export const GetPropertyDetailsSuccess = (payload) => ({
  type: GET_PROPERTY_DETAILS_SUCCESS,
  payload,
})

export const GetPropertyDetailsFailed = (payload) => ({
  type: GET_PROPERTY_DETAILS_FAILED,
  payload,
})

export const GetPurchaseHistoryStarted = (payload) => ({
  type: GET_PROPERTY_PURCHASE_HISTORY_STARTED,
  ...payload,
})

export const GetPurchaseHistorySuccess = (payload) => ({
  type: GET_PROPERTY_PURCHASE_HISTORY_SUCCESS,
  payload,
})

export const GetPurchaseHistoryFailed = (payload) => ({
  type: GET_PROPERTY_PURCHASE_HISTORY_FAILED,
  payload,
})

export const ClearPropertyDetails = () => ({
  type: CLEAR_PROPERTY_DETAILS,
})

export const ClearPurchaseHistory = () => ({
  type: CLEAR_PROPERTY_PURCHASE_HISTORY,
})

export const ClearPurchasePagination = () => ({
  type: CLEAR_PURCHASE_PAGINATION,
})

const ResetPropertyDetailsToasts = () => ({
  type: RESET_PROPERTY_DETAILS_TOASTS,
})

const GetPropertyDetails = (payload) => async (dispatch) => {
  dispatch(GetPropertyDetailsStarted(payload))
  try {
    const result = await api.Get(`/property/${payload.id}`, payload.token)
    validateServerResponse(result)
    dispatch(GetPropertyDetailsSuccess(result.data?.data))
  } catch (error) {
    handleActionCatch(
      error,
      dispatch,
      GetPropertyDetailsFailed,
      'Get property details',
      payload
    )
  }
  dispatch(ResetPropertyDetailsToasts())
}

export const setPurchasePagination = (payload) => ({
  type: SET_PURCHASE_PAGINATION,
  payload: { elements: payload.elements, page: payload.page },
})

export const GetPurchaseHistory =
  ({ token, id, page = 0 }) =>
  async (dispatch) => {
    // eslint-disable-next-line no-console
    dispatch(GetPurchaseHistoryStarted())
    try {
      const result = await api.Get(
        `/property/products/${id}?page=${page}&size=8`,
        token
      )
      validateServerResponse(result)
      dispatch(GetPurchaseHistorySuccess(result.data?.data))
    } catch (error) {
      handleActionCatch(
        error,
        dispatch,
        GetPurchaseHistoryFailed,
        'Get property purchase history',
        { token, id, page }
      )
    }
    dispatch(ResetPropertyDetailsToasts())
  }

export default GetPropertyDetails
